import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    type: string;
};

export const ReviewHeader: FC<Props> = ({ type }) => {
    const { t } = useTranslation();
    return (
        <>
            <p className="pb-5 pl-6 pt-5 font-medium">
                {t('REVIEW_INFO')} <span className="text-lg underline">{type}</span>
            </p>
            <div className="ml-auto mr-6" data-tip={`${t('REVIEW')} ${type}`}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#1890FF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </>
    );
};
