import { Header } from '../Header';
import { Table } from './Table';

export const BookingHistory = ({ tab }) => {
    return (
        <>
            <Header tab={tab} />

            <Table />
        </>
    );
};
