import { useTranslation } from 'react-i18next';
import { BallTriangle, RotatingLines } from 'react-loader-spinner';

import { Fancybox } from '../../common/Fancybox';
import { MessageForm } from './MessageForm';

export const ChatDetail = ({ setBookingId, roomNumber, messageList, userMessagesFetch, oldestMessagesFetch, handlerLoadMoreMessages, tab, bookingId }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            {/*Mobile nav*/}
            <div className="border-b-gray-10 sticky top-0 flex w-full items-center justify-center border-b bg-white p-3 md:hidden">
                <button type="button" onClick={() => setBookingId('')} className="mr-auto flex w-20 items-center gap-2">
                    <svg className="h-5 w-3 fill-blue-500" viewBox="0 0 12 20">
                        <path d="M0 9.93896C0 10.3208 0.145996 10.6465 0.449219 10.9385L9.20898 19.5073C9.44482 19.7544 9.75928 19.8779 10.1187 19.8779C10.8486 19.8779 11.4214 19.3164 11.4214 18.5752C11.4214 18.2158 11.2754 17.8901 11.0283 17.6431L3.1333 9.93896L11.0283 2.23486C11.2754 1.97656 11.4214 1.65088 11.4214 1.2915C11.4214 0.561523 10.8486 0 10.1187 0C9.75928 0 9.44482 0.123535 9.20898 0.370605L0.449219 8.93945C0.145996 9.23145 0.0112305 9.55713 0 9.93896Z" />
                    </svg>
                    <span className="text-blue-500">{t('CHAT_BACK')}</span>
                </button>

                <span className="ml-2 truncate font-bold">{roomNumber ? `${t('CHAT_ROOM')} ${roomNumber}` : ''}</span>
                <span className="ml-auto w-20"></span>
            </div>

            {/*Messages list*/}
            <div className="flex h-full flex-col-reverse overflow-auto p-3 pt-0 md:p-6 md:pt-0">
                {userMessagesFetch ? (
                    <div className="flex h-full w-full items-center justify-center">
                        <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
                    </div>
                ) : messageList ? (
                    messageList.messages.length > 0 ? (
                        messageList.messages.map((message, index) => {
                            let dateJXS = '';

                            if (
                                messageList.messages.length === index + 1 ||
                                message.date_add.slice(0, 10) !== messageList.messages[index + 1].date_add.slice(0, 10)
                            ) {
                                dateJXS = (
                                    <div className="my-6 text-center text-sm">
                                        {i18n.language === 'ru'
                                            ? new Date(message.date_add)
                                                  .toLocaleString(i18n.language, {
                                                      month: 'long',
                                                      day: 'numeric',
                                                      year: 'numeric',
                                                  })
                                                  .slice(0, -3)
                                            : new Date(message.date_add)
                                                  .toLocaleString(i18n.language, {
                                                      month: 'long',
                                                      day: 'numeric',
                                                      year: 'numeric',
                                                  })
                                                  .slice(0, -3)}
                                    </div>
                                );
                            }

                            return (
                                <div key={message.id} className="flex flex-col">
                                    {dateJXS}
                                    <div
                                        className={`relative mb-3 inline-block min-w-[30%] max-w-[85%] flex-wrap items-end rounded-[18px] px-3 pb-1.5 pt-3 text-sm after:absolute after:block after:h-4 after:w-4 md:min-w-[20%] ${
                                            message.from_admin === '1'
                                                ? 'ml-auto bg-blue-500 text-white after:-right-[3px] after:bottom-[3px] after:content-bubbleSent'
                                                : 'mr-auto bg-gray-200 after:-left-[3px] after:bottom-[3px] after:content-bubbleReceived'
                                        }`}
                                    >
                                        {message.files &&
                                            message.files.map(pic => (
                                                <img
                                                    key={pic.id}
                                                    src={`${pic.base64 ? pic.base64 : pic.link}`}
                                                    alt="photo"
                                                    className="mb-3 cursor-pointer rounded-lg"
                                                    data-fancybox="gallery"
                                                    width="300"
                                                />
                                            ))}
                                        <Fancybox />
                                        {message.message !== 'undefined' && (
                                            <span
                                                className={`break-words ${message.from_admin === '1' ? '!text-white' : ''}`}
                                                dangerouslySetInnerHTML={{ __html: message.message.replace(/<br\s*\/?>|\s*style="[^"]*"/g, '') }}
                                            ></span>
                                        )}
                                        <div className="flex items-center">
                                            <span className={`z-10 ml-auto text-xs ${message.from_admin === '1' ? 'text-gray-100' : 'text-gray-400'}`}>
                                                {message.date_add.slice(11, 16)}
                                            </span>
                                            {message.is_user_read === '1' && message.from_admin && (
                                                <span className="mb-1 ml-1">
                                                    <svg viewBox="0 0 16 11" height="11" width="16" fill="none">
                                                        <path
                                                            d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        t('CHAT_NO_MESSAGE')
                    )
                ) : (
                    ''
                )}
                {messageList ? (
                    Number(messageList.count) !== messageList.messages.length && !oldestMessagesFetch ? (
                        <button type="button" onClick={() => handlerLoadMoreMessages()} className="mt-3 text-sm text-blue-500">
                            загрузить еще
                        </button>
                    ) : (
                        Number(messageList.count) !== messageList.messages.length && (
                            <div className="flex justify-center">
                                <RotatingLines width="20" strokeColor="blue" />
                            </div>
                        )
                    )
                ) : (
                    ''
                )}
            </div>

            {/*Message form*/}
            <MessageForm booking_id={bookingId} tab={tab} />
        </>
    );
};
