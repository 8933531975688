import { useTranslation } from 'react-i18next';

export const ChangeButton = ({ setChangePopupIsOpen, machine, setMachineId }) => {
    const { t } = useTranslation();
    return (
        <button
            type="button"
            className="rounded-2xl bg-white px-2 py-1 text-sm text-blue-500"
            onClick={() => {
                setChangePopupIsOpen(true);
                setMachineId(machine.id);
            }}
        >
            {t('INFO_CHANGE')}
        </button>
    );
};
