import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';

import { Fancybox } from '../common/Fancybox';

import { AppDispatch } from '../../redux/store';
import { blackListUserInfoFetchState, blackListUserInfoState, getBlackListUserInfo, readBlackListUserInfo } from '../../redux/slices/blackListSlice';
import { BlackList } from './types';

type Props = {
    setBlackListData: Dispatch<SetStateAction<BlackList | null>>;
    blackListData: BlackList | null;
};
export const Detail: FC<Props> = ({ setBlackListData, blackListData }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch<AppDispatch>();
    const blackListUserInfo = useSelector(blackListUserInfoState);
    const blackListUserInfoFetch = useSelector(blackListUserInfoFetchState);

    useEffect(() => {
        if (blackListData) {
            dispatch(getBlackListUserInfo(blackListData.id));
            if (!blackListData.isRead) {
                dispatch(readBlackListUserInfo(blackListData.id));
            }
        }
    }, [blackListData]);

    if (!blackListData) {
        return (
            <div className="hidden h-full w-full items-center justify-center md:flex">
                <div className="rounded-[100px] bg-gray-200 px-6 py-3 font-semibold">{t('CHAT_CHOOSE_PEOPLE')}</div>
            </div>
        );
    }

    if (blackListUserInfoFetch) {
        return (
            <div className="flex h-full w-full items-center justify-center">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }
    return (
        <div className="flex h-full w-full flex-col md:h-auto">
            {/*Mobile nav*/}
            <div className="border-b-gray-10 sticky top-0 flex w-full items-center justify-center border-b bg-white p-3 md:hidden">
                <button type="button" onClick={() => setBlackListData(null)} className="mr-auto flex w-20 items-center gap-2">
                    <svg className="h-5 w-3 fill-blue-500" viewBox="0 0 12 20">
                        <path d="M0 9.93896C0 10.3208 0.145996 10.6465 0.449219 10.9385L9.20898 19.5073C9.44482 19.7544 9.75928 19.8779 10.1187 19.8779C10.8486 19.8779 11.4214 19.3164 11.4214 18.5752C11.4214 18.2158 11.2754 17.8901 11.0283 17.6431L3.1333 9.93896L11.0283 2.23486C11.2754 1.97656 11.4214 1.65088 11.4214 1.2915C11.4214 0.561523 10.8486 0 10.1187 0C9.75928 0 9.44482 0.123535 9.20898 0.370605L0.449219 8.93945C0.145996 9.23145 0.0112305 9.55713 0 9.93896Z" />
                    </svg>
                    <span className="text-blue-500">{t('CHAT_BACK')}</span>
                </button>

                <span className="ml-2 truncate font-bold">{blackListData.roomNumber}</span>
                <span className="ml-auto w-20"></span>
            </div>

            <div className="overflow-auto p-6 pt-3">
                <div className="mb-3 flex flex-col border-b border-b-gray-300 pb-2 text-center">
                    <span className="text-lg font-bold">{t('BLACK_LIST_1')}</span>
                    <span>{blackListUserInfo?.name ? blackListUserInfo.name : 'ФИО не указано'}</span>
                </div>
                <div className="mb-3 flex flex-col border-b border-b-gray-300 pb-2 text-center">
                    <span className="text-lg font-bold">{t('BLACK_LIST_2')}</span>
                    <span>{blackListUserInfo?.city.title ? blackListUserInfo.city.title : 'Город не указан'}</span>
                </div>
                <div className="mb-3 flex flex-col border-b border-b-gray-300 pb-2 text-center">
                    <span className="text-lg font-bold">{t('BLACK_LIST_3')}</span>
                    <span>{blackListUserInfo?.createdAt ? blackListUserInfo.createdAt : 'Дата не указана'}</span>
                </div>
                <div className="mb-3 flex flex-col border-b border-b-gray-300 pb-2 text-center">
                    <span className="text-lg font-bold">{t('BLACK_LIST_4')}</span>
                    <span>{blackListUserInfo?.humanKind ? blackListUserInfo.humanKind : 'Причина не указана'}</span>
                </div>
                <div className="mb-3 flex flex-col border-b border-b-gray-300 pb-2 text-center">
                    <span className="text-lg font-bold">{t('BLACK_LIST_5')}</span>
                    <span>{blackListUserInfo?.phone ? blackListUserInfo.phone : 'Телефон не указан'}</span>
                </div>
                <div className="mb-3 flex flex-col border-b border-b-gray-300 pb-2 text-center">
                    <span className="text-lg font-bold">{t('BLACK_LIST_6')}</span>
                    <span>{blackListUserInfo?.passport ? blackListUserInfo.passport : 'Данных нет'}</span>
                </div>
                <div className="mb-3 flex flex-col border-b border-b-gray-300 pb-2 text-center">
                    <span className="text-lg font-bold">{t('BLACK_LIST_7')}</span>
                    <span>{blackListUserInfo?.description ? blackListUserInfo.description : 'Описания нет'}</span>
                </div>
                <div className="mb-3 flex flex-col items-center text-center">
                    <span className="text-lg font-bold">{t('PHOTO')}</span>
                    {blackListUserInfo?.photos.length
                        ? blackListUserInfo.photos.map(photo => (
                              <img key={photo.id} src={photo.url} alt="photo" className="mb-3 cursor-pointer rounded-lg " data-fancybox="gallery" width="300" />
                          ))
                        : 'Нет фото'}
                    <Fancybox />
                </div>
            </div>
        </div>
    );
};
