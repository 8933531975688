import { Field, FieldArray } from 'formik';

export const AdditionOptions = ({ values, isSubmitting }) => {
    return (
        <>
            <div className="text-gray-20 mb-2 flex w-full justify-center text-base">Дополнительные опции</div>
            <FieldArray name="addition_options">
                {({ remove, push }) => (
                    <div className="relative">
                        {values.addition_options &&
                            values.addition_options.map((option, id) => (
                                <div key={option.id} className="mb-4 border-b-2">
                                    {values.addition_options.length > 0 && (
                                        <div className="absolute right-0 h-5 rounded-full border text-gray-500 transition-colors hover:border-red-600 hover:text-red-600">
                                            <button type="button" onClick={() => remove(id)}>
                                                <svg width="18" height="18" viewBox="0 0 24 24" className="rotate-45 fill-current">
                                                    <g clipPath="url(#clip0_2599_10981)">
                                                        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2599_10981">
                                                            <rect width="24" height="24" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>
                                        </div>
                                    )}
                                    <div className="mb-1.5">
                                        <p className="text-gray-20 mb-1 flex w-full text-sm text-gray-500">Название опции</p>
                                        <Field
                                            name={`addition_options.${id}.name`}
                                            component="textarea"
                                            placeholder="Введите название"
                                            className={`border-gray-10 box-border h-6 w-full resize-none rounded p-0.5 pl-2 text-xs ${
                                                values?.addition_options[id]?.name === '' ? 'border-red-500' : ''
                                            }`}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="mb-1.5">
                                        <p className="text-gray-20 mb-1 flex w-full text-sm text-gray-500">Название опции на английском</p>
                                        <Field
                                            name={`addition_options.${id}.name_en`}
                                            component="textarea"
                                            placeholder="Enter name"
                                            className={`border-gray-10 box-border h-6 w-full resize-none rounded p-0.5 pl-2 text-xs ${
                                                values?.addition_options[id]?.name_en === '' ? 'border-red-500' : ''
                                            }`}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="mb-1.5">
                                        <p className="text-gray-20 mb-1 flex w-full text-sm text-gray-500">Стоимость опции</p>
                                        <Field
                                            name={`addition_options.${id}.price`}
                                            component="textarea"
                                            placeholder="Стоимость опции"
                                            className={`border-gray-10 box-border h-6 w-full resize-none rounded p-0.5 pl-2 text-xs ${
                                                values?.addition_options[id]?.price === '' ? 'border-red-500' : ''
                                            }`}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                            ))}
                        <div className="flex justify-center">
                            <button
                                className=" rounded border bg-blue-400 px-3 py-0.5 text-xs text-white transition-colors hover:bg-blue-600"
                                type="button"
                                onClick={() =>
                                    push({
                                        name: '',
                                        name_en: '',
                                        price: '',
                                        id: Date.now(),
                                    })
                                }
                            >
                                Добавить опцию
                            </button>
                        </div>
                    </div>
                )}
            </FieldArray>
        </>
    );
};
