import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InitValuesList, Room } from '../types/templateTypes.ts';
import { WorkingHours } from './WorkingHours.tsx';
import { Location } from './Location.tsx';
import { Description } from './Description.tsx';
import { SubmitButton } from '../SubmitButton.tsx';

type Props = {
    values: InitValuesList;
    isSubmitting: boolean;
    selectedRoom: Room | null;
    dirty: boolean;
    loading: boolean;
};
export const ListInfo: FC<Props> = ({ values, isSubmitting, selectedRoom, dirty, loading }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="mb-3 mt-6 text-center">
                {t('INFO_APARTMENT')}: {selectedRoom && selectedRoom.label}
            </div>
            <div className="bg-white pt-6 sm:flex">
                <div className=" sm:w-1/2">
                    <WorkingHours values={values} isSubmitting={isSubmitting} />
                    <Location values={values} isSubmitting={isSubmitting} />
                </div>
                <div className="sm:w-1/2">
                    <Description values={values} isSubmitting={isSubmitting} />
                </div>
            </div>
            <SubmitButton dirty={dirty} loading={loading} name="SAVE" />
        </>
    );
};
