import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import { getTariffs, tariffsFetchingState, tariffsState } from '../../../redux/slices/coworkingSlice';
import { ContentWrapper } from '../../common/ContentWrapper';
import { Item } from './Item';

export const List = ({ tab, setDetailId }) => {
    const dispatch = useDispatch();

    const tariffs = useSelector(tariffsState);
    const tariffsFetching = useSelector(tariffsFetchingState);

    useEffect(() => {
        if (tab && tab.hotel_id) {
            dispatch(getTariffs({ hotel_id: tab.hotel_id }));
        }
    }, [tab]);

    if (tariffsFetching) {
        return (
            <div className="mt-8 flex w-full justify-center">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    return (
        <ContentWrapper>
            <div className="flex flex-wrap">
                {!!tariffs.length && tariffs.map(item => <Item key={item.id} tab={tab} item={item} setDetailId={setDetailId} />)}
            </div>
        </ContentWrapper>
    );
};
