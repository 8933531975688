import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { tabs as tabs_state, tab_fetching } from '../redux/slices/hotelSlice';
import { BallTriangle } from 'react-loader-spinner';

import { Header } from '../components/blackList/Header';

import { HotelTab } from '../types/HotelTab';
import { List } from '../components/blackList/List';
import { Detail } from '../components/blackList/Detail';
import { BlackList } from '../components/blackList/types';

type Tab = {
    hotel_name: string;
    hotel_id: string;
};

export const BlackListPage: FC = () => {
    const { hotel_id } = useParams();

    const tabFetching: boolean = useSelector(tab_fetching);
    const tabs: HotelTab[] = useSelector(tabs_state);

    const [tab, setTab] = useState<Tab | null>(null);
    const [blackListData, setBlackListData] = useState<BlackList | null>(null);

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel && typeof hotel.Name === 'string' && typeof hotel.id === 'string') {
                setTab({
                    hotel_name: hotel.Name,
                    hotel_id: hotel.id,
                });
            }
        }
    }, [tabs, hotel_id]);

    if (tabFetching) {
        return (
            <div className="flex h-full w-full items-center justify-center">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }
    return (
        <>
            <Header tab={tab} />

            <div className="flex h-[calc(100vh-4rem)] w-full flex-col overflow-hidden bg-white md:h-[calc(100vh-9rem)] md:flex-row">
                <List setBlackListData={setBlackListData} blackListData={blackListData} tab={tab} />
                <Detail setBlackListData={setBlackListData} blackListData={blackListData} />
            </div>
        </>
    );
};
