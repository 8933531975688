import { useTranslation } from 'react-i18next';

export const SettingsInput = ({ title, initValues, setInitValues, status }) => {
    const { t } = useTranslation();
    const handlerInput = value => {
        const filteredText = value.replace(/[^0-9]/g, '');
        if (title === 'LOYALTY_PERCENT_DISCOUNT') {
            const num = Number(filteredText);
            if (num >= 1 && num <= 100) {
                setInitValues({ ...initValues, [status]: filteredText });
            } else if (filteredText === '') {
                setInitValues({ ...initValues, [status]: '' });
            }
        } else {
            setInitValues({ ...initValues, [status]: filteredText });
        }
    };
    return (
        <div className={`mb-2 flex w-56 flex-row items-center justify-between ${title === 'LOYALTY_PERCENT_DISCOUNT' ? 'md:w-52' : 'md:w-64 '}`}>
            <p className="mr-2 text-sm">{t(title)}:</p>
            <input value={initValues[status] ? initValues[status] : ''} onChange={e => handlerInput(e.target.value)} type="text" className="h-7 w-16 text-sm" />
        </div>
    );
};
