import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

export const Sort = ({ values, isSubmitting }) => {
    const { t } = useTranslation();
    return (
        <>
            <p className="mr-4 pb-6 pl-6 pt-6 font-medium">{t('INFO_SORT')}</p>

            <Field
                name="sort"
                component="textarea"
                value={values.sort}
                placeholder="№"
                className="border-gray-10 mr-5 box-border h-8 w-12 resize-none rounded p-1 pl-2 text-sm"
                disabled={isSubmitting}
            />

            <div className="ml-auto mr-6" data-tip={t('INFO_TOOLTIP_1')}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#1890FF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </>
    );
};
