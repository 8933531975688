import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header } from './Header';
import { information_tabs as informations_tabs_state } from '../../redux/slices/informationSlice';

export const HotelRooms = ({ tab }) => {
    const information_tabs = useSelector(informations_tabs_state);
    const params = useParams();

    const { t, i18n } = useTranslation();
    const rooms = information_tabs?.find(el => el.code === params.tab_code);

    return (
        <>
            <Header tab={tab} />
            <div className="pb-6 pt-6 sm:flex sm:flex-wrap sm:justify-center xl:gap-3 ">
                {rooms?.subitems
                    ? rooms.subitems.map(room => {
                          return (
                              <div
                                  className="mb-3 ml-2 mr-2 flex-col rounded-2 bg-white drop-shadow last:mb-0 sm:mb-2 sm:w-[47%] sm:last:mb-2 sm:odd:mr-2 xl:m-0 xl:w-1/4 xl:last:mb-0 xl:odd:m-0  "
                                  key={room.id}
                              >
                                  {room.active === '0' && (
                                      <div className="absolute flex h-full w-full items-center justify-center bg-white bg-opacity-50">
                                          <div className="w-2/3 bg-green-500 py-2 text-center text-sm text-white">{t('INFO_ROOM_NOT_ACTIVE')}</div>
                                      </div>
                                  )}
                                  <div className="h-44 w-full shrink-0 xl:h-56">
                                      <img src={room.files?.length ? room.files[0] : ''} alt={room.name} className=" h-full w-full object-cover " />
                                  </div>
                                  <div className="pl-4 pr-4">
                                      <p className="mb-2 mt-4 truncate whitespace-nowrap text-base font-bold sm:text-base  ">
                                          {i18n.language === 'ru' ? room.name : room.name_en}
                                      </p>
                                      <p className=" mb-3 text-sm">
                                          <span className="font-medium">{t('INFO_SIZE')}: </span>
                                          <span className="opacity-80">{room.preview_description?.replace('Площадь', '').replace('номера', '')}</span>
                                      </p>
                                  </div>
                                  <Link to={`?roomId=${room.id}`} className="relative z-10">
                                      <div className="flex justify-center">
                                          <button
                                              type="button"
                                              className="mb-4 w-4/5 rounded border border-blue-500 py-1 text-center text-sm text-blue-500 transition-colors hover:bg-blue-500 hover:text-white "
                                          >
                                              {t('EDIT')}
                                          </button>
                                      </div>
                                  </Link>
                              </div>
                          );
                      })
                    : t('INFO_NOT_ROOM')}
            </div>
        </>
    );
};
