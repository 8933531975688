import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import Select, { SingleValue } from 'react-select';

import { Popup } from '../../common/popup/Popup';
import { DepositInput } from './DepositInput';
import { ActionConfirmPopup } from './ActionConfirmPopup.tsx';
import { ReasonBlock } from './ReasonBlock.tsx';

import {
    withholdDeposit,
    depositFetchingState,
    returnDeposit,
    returnPayment,
    getBookingForDepositTransfer,
    bookingsForDepositTransferState,
    setBookingsForDepositTransfer,
    transferDeposit,
} from '../../../redux/slices/chatSlice';

import { errorNotification, getIcon } from '../../../api/functions.js';
import { getButtonName } from './utils/getButtonName.ts';
import { isDisabledSendButton } from './utils/isDisabledSendButton.ts';
import { initialState } from './utils/initialState.ts';
import { checkPaymentStatus } from './utils/checkPaymentStatus.ts';

import { Data, Props } from './utils/types.js';
import { AppDispatch } from '../../../redux/store';
import { useParams } from 'react-router-dom';

const MAX_PHOTO_SIZE = 9_961_472;
type OptionType = { value: string; label: string };

export const DepositPopup: FC<Props> = ({ isOpen, setIsOpen, bookingId, payment, deposit, checkLink }) => {
    const { t } = useTranslation();
    const { hotel_id } = useParams();

    const compleatActionRef = useRef(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch<AppDispatch>();
    const depositFetching = useSelector(depositFetchingState);
    const bookingsForDepositTransfer_options: OptionType[] = useSelector(bookingsForDepositTransferState);

    const [formData, setFormData] = useState(initialState);
    const [photoPreview, setPhotoPreview] = useState<string | null>(null);
    const [isActionConfirmShow, setIsActionConfirmShow] = useState(false);

    useEffect(() => {
        if (deposit) {
            dispatch(getBookingForDepositTransfer(hotel_id, bookingId));
        }
        if (!isOpen) {
            setFormData(initialState);
            setPhotoPreview(null);
            setBookingsForDepositTransfer(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (compleatActionRef.current) {
            compleatActionRef.current = false;
            handlerClick();
        }
    }, [compleatActionRef.current]);

    const actionConfirm = () => {
        setIsActionConfirmShow(true);
    };
    const handlerClick = async () => {
        ym(98170648, 'reachGoal', 'yaMetricsDepositClick');

        const data: Data = {
            booking_id: bookingId,
        };
        // удержание всего залога
        if (formData.allDeposit) {
            data.status = 5;
            data.reason = formData.withholdReason;
            data.photo = formData.photo;

            const status: boolean | string = await dispatch(withholdDeposit({ data }));
            checkPaymentStatus(status, setIsOpen, t, 'withhold');
        }
        // удержание части залога
        if (formData.depositSum.length) {
            if (/^\d+$/.test(formData.depositSum)) {
                data.sum = formData.depositSum;
                data.status = 3;
                data.reason = formData.withholdReason;
                data.photo = formData.photo;

                const status = await dispatch(withholdDeposit({ data }));
                checkPaymentStatus(status, setIsOpen, t, 'withhold');
            } else {
                return errorNotification(t('CHAT_DEPOSIT_2'));
            }
        }
        // возврат залога
        if (formData.refundDeposit) {
            const status = await dispatch(returnDeposit(bookingId));
            checkPaymentStatus(status, setIsOpen, t, 'return');
        }
        // возврат оплаты
        if (formData.refundPayment) {
            const status = await dispatch(returnPayment(bookingId));
            checkPaymentStatus(status, setIsOpen, t, 'returnPayment');
        }

        // перенос залога
        if (formData.transferDepositRoom) {
            data.hotel_id = hotel_id;
            data.ext_id = formData.transferDepositRoom.value;
            const status = await dispatch(transferDeposit({ data }));
            checkPaymentStatus(status, setIsOpen, t, 'transferDepositRoom');
        }
    };
    const handlerInputChange = (e: any, name: string) => {
        // удержание всего залога
        if (name === 'full') {
            setFormData(prev => ({ ...initialState, withholdReason: prev.withholdReason, allDeposit: e.target.checked }));
        }
        // возврат залога
        if (name === 'refundDeposit') {
            setFormData({ ...initialState, refundDeposit: e.target.checked });
            setPhotoPreview(null);
        }
        // удержание части залога
        if (name === 'sum') {
            setFormData(prev => ({ ...initialState, withholdReason: prev.withholdReason, depositSum: e.target.value }));
        }
        // возврат оплаты
        if (name === 'refundPayment') {
            setFormData({ ...initialState, refundPayment: e.target.checked });
            setPhotoPreview(null);
        }
        // причина удержания
        if (name === 'withholdReason') {
            setFormData(prev => ({ ...prev, withholdReason: e.target.value }));
        }
        // фото
        if (name === 'photo') {
            // 9.5 mb
            if (e.target.files.length && e.target.files[0].size > MAX_PHOTO_SIZE) {
                fileInputRef.current!.value = '';
                return errorNotification(t('CHAT_DEPOSIT_32'));
            }
            setFormData(prev => ({ ...prev, photo: e.target.files[0] }));
            const photoBlob = new Blob([e.target.files[0]], { type: e.target.files[0].type });
            const img = URL.createObjectURL(photoBlob);
            setPhotoPreview(img);
        }
        // перенос залога
        if (name === 'transferDepositRoom') {
            setFormData({ ...initialState, transferDepositRoom: e });
        }
    };

    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
            <div>
                <div className="text-center text-lg">{t('CHAT_DEPOSIT_7')}</div>

                <div className="mt-3">
                    <div className="flex w-full flex-col ">
                        <div className="mb-1 flex w-full justify-center font-medium">
                            {t('CHAT_DEPOSIT_18')}
                            {deposit === null ? getIcon('tooltip', t('CHAT_DEPOSIT_21')) : deposit === false ? getIcon('tooltip', t('CHAT_DEPOSIT_20')) : ''}
                        </div>
                        <div className={`${deposit ? 'w-full' : ''}`}>
                            <div>
                                <span className={`${!deposit ? 'text-gray-300' : ''} mr-2 text-sm`}>{t('CHAT_DEPOSIT_4')}</span>
                                <DepositInput
                                    inputType="checkbox"
                                    data={formData.allDeposit}
                                    name="full"
                                    callback={handlerInputChange}
                                    status={{ payment, deposit }}
                                />
                            </div>
                            <div className="mt-2">
                                <span className={`${!deposit ? 'text-gray-300' : ''} mr-2 text-sm`}>{t('CHAT_DEPOSIT_5')}</span>
                                <DepositInput
                                    inputType="text"
                                    data={formData.depositSum}
                                    name="sum"
                                    callback={handlerInputChange}
                                    status={{ payment, deposit }}
                                />
                            </div>
                            {formData.allDeposit || formData.depositSum ? (
                                <ReasonBlock
                                    formData={formData}
                                    setFormData={setFormData}
                                    handlerInputChange={handlerInputChange}
                                    photoPreview={photoPreview}
                                    setPhotoPreview={setPhotoPreview}
                                    fileInputRef={fileInputRef}
                                />
                            ) : (
                                ''
                            )}
                            <div className="mt-2">
                                <span className={`${!deposit ? 'text-gray-300' : ''} mr-2 text-sm`}>{t('CHAT_DEPOSIT_11')}</span>
                                <DepositInput
                                    inputType="checkbox"
                                    data={formData.refundDeposit}
                                    name="refundDeposit"
                                    callback={handlerInputChange}
                                    status={{ payment, deposit }}
                                />
                            </div>
                            <div className="mt-2 flex items-center">
                                <span className={`${!deposit ? 'text-gray-300' : ''} mr-2 text-sm`}>Перенос залога</span>
                                <Select
                                    name="type"
                                    value={formData.transferDepositRoom as SingleValue<OptionType>}
                                    options={bookingsForDepositTransfer_options ? bookingsForDepositTransfer_options : []}
                                    onChange={(val: SingleValue<OptionType>) => handlerInputChange(val, 'transferDepositRoom')}
                                    placeholder="Выберите бронь"
                                    className="transferDepositSelect w-64 rounded border-gray-300 text-xs"
                                    isSearchable={true}
                                    isDisabled={!deposit}
                                />
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="mt-3 flex flex-col border-t pt-2">
                        <div className="mb-1 flex items-center justify-center font-medium">
                            {t('CHAT_PAYMENT')}
                            {payment === null ? getIcon('tooltip', t('CHAT_DEPOSIT_22')) : payment === false ? getIcon('tooltip', t('CHAT_DEPOSIT_20')) : ''}
                        </div>
                        <div>
                            <span className={`${!payment ? 'text-gray-300' : ''} mr-2 text-sm`}>{t('CHAT_DEPOSIT_12')}</span>
                            <DepositInput
                                inputType="checkbox"
                                data={formData.refundPayment}
                                name="refundPayment"
                                callback={handlerInputChange}
                                status={{ payment, deposit }}
                            />
                        </div>
                    </div>
                </div>

                <div className="relative mt-6 flex justify-center">
                    {checkLink && (
                        <div className="absolute bottom-0 left-0 flex items-center justify-center rounded-sm bg-blue-500 px-2 py-0.5 hover:bg-blue-600">
                            <a href={checkLink} target="_blank" rel="noopener noreferrer" className="text-sm text-white">
                                Скачать чек
                            </a>
                        </div>
                    )}
                    <button
                        type="button"
                        className={`${
                            isDisabledSendButton(formData) ? 'bg-gray-400' : 'cursor-pointer bg-blue-500 hover:bg-blue-600'
                        }  flex w-28 items-center justify-center rounded-sm py-0.5 text-sm font-medium text-white `}
                        onClick={actionConfirm}
                        disabled={isDisabledSendButton(formData)}
                    >
                        {depositFetching ? (
                            <RotatingLines width="22" strokeColor="white" />
                        ) : (
                            getButtonName(formData.depositSum, formData.allDeposit, formData.transferDepositRoom, t)
                        )}
                    </button>
                </div>
            </div>
            {isActionConfirmShow && <ActionConfirmPopup setIsOpen={setIsActionConfirmShow} formData={formData} compleatActionRef={compleatActionRef} />}
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </Popup>
    );
};
