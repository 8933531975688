import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Popup } from '../../common/popup/Popup';
import { successNotification, errorNotification } from '../../../api/functions';
import { updateMachine } from '../../../redux/slices/informationSlice';

const initialValues = {
    price: '',
    type: '',
    working_status: {
        1: '',
        0: '',
    },
};
export const ChangePopup = ({ machine, isOpen, setIsOpen }) => {
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState(initialValues);

    const { t, i18n } = useTranslation();

    const type_options = useMemo(
        () => [
            { value: 1, label: t('INFO_WASHING') },
            { value: 2, label: t('INFO_DRYER') },
        ],
        [t]
    );

    useEffect(() => {
        const working_status = machine.working_status === '0' ? { 0: true, 1: false } : { 0: false, 1: true };
        const type = type_options.filter(el => (i18n.language === 'ru' ? el.label === machine.type : el.label === machine.type_en))[0];
        setFormValues({ price: machine.price, type, working_status });
    }, [machine]);

    const handlerSubmit = async () => {
        const working_status = formValues.working_status[0] === true ? '0' : '1';
        const data = { ...formValues, working_status, type: formValues.type.label, id: machine.id };

        const status = await dispatch(updateMachine({ data }));

        if (status === 'ok') {
            successNotification(t('SAVE_INFORMATION'));
            setIsOpen(false);
            setFormValues(initialValues);
        } else {
            errorNotification(status);
        }
    };

    const handleChange = e => {
        let { value, max } = e.target;
        const numberValue = Number(value);
        const numberMax = Number(max);

        if (numberValue <= 0) {
            errorNotification(t('INFO_ERROR_44'));
            setFormValues({ ...formValues, price: value });
        } else if (!Number.isInteger(numberValue)) {
            errorNotification(t('INFO_ERROR_45'));
        } else if (numberValue <= numberMax) {
            setFormValues({ ...formValues, price: value });
        } else {
            errorNotification(t('INFO_ERROR_46'));
        }
    };
    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
            <div>
                <label className="relative mt-2 flex flex-col">
                    <span className="text-xs font-medium">{t('INFO_PRICE')}</span>
                    <input
                        type="number"
                        max="999999"
                        value={formValues.price}
                        className="mt-2 h-9 rounded border-gray-300 px-3 py-1.5 text-xs"
                        onChange={e => handleChange(e)}
                    />
                    <span className="absolute bottom-2 right-1.5 text-xs text-gray-400">{t('INFO_PRICE_1')}</span>
                </label>
                <label className="relative mt-6 flex flex-col">
                    <span className="text-xs font-medium">{t('INFO_MACHINE_TYPE')}</span>
                    <Select
                        name="type"
                        value={formValues.type}
                        options={type_options}
                        onChange={val => setFormValues({ ...formValues, type: val })}
                        placeholder="Выберите тип машинки"
                        className=" mt-2 rounded border-gray-300 text-xs"
                        isSearchable={false}
                    />
                </label>
                <div className="mt-6 flex flex-col">
                    <span className="mb-3 text-xs font-medium">{t('INFO_WORK_STATUS')}</span>
                    <label className="w-full rounded-tl rounded-tr border-b border-b-gray-200 bg-gray-100 p-4">
                        <input
                            type="checkbox"
                            name="working_status"
                            className="mr-3"
                            checked={formValues.working_status[1]}
                            onChange={e => setFormValues({ ...formValues, working_status: { 1: e.target.checked, 0: !e.target.checked } })}
                        />
                        <span className="text-sm">{t('INFO_WORK')}</span>
                    </label>
                    <label className="w-full rounded-bl rounded-br bg-gray-100 p-4">
                        <input
                            type="checkbox"
                            checked={formValues.working_status[0]}
                            name="working_status"
                            className="mr-3"
                            onChange={e => setFormValues({ ...formValues, working_status: { 0: e.target.checked, 1: !e.target.checked } })}
                        />
                        <span className="text-sm">{t('INFO_NOT_WORK')}</span>
                    </label>
                </div>

                <div className="mt-6">
                    <button
                        disabled={Number(formValues.price) <= 0}
                        className="mr-2 rounded bg-blue-400 px-2 py-1 text-sm text-white transition-colors hover:bg-blue-600"
                        type="button"
                        onClick={() => {
                            handlerSubmit();
                        }}
                    >
                        {t('INFO_SAVE_CHANGES')}
                    </button>
                    <button
                        className="rounded px-2 py-1 text-sm text-gray-500 transition-colors hover:bg-gray-100"
                        type="button"
                        onClick={() => {
                            setIsOpen(false);
                            setFormValues(initialValues);
                        }}
                    >
                        {t('INFO_CANCEL')}
                    </button>
                </div>
            </div>
        </Popup>
    );
};
