import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { successNotification, errorNotification } from '../../../../api/functions.js';

export const checkPaymentStatus = (
    status: boolean | string,
    setIsOpen: Dispatch<SetStateAction<boolean>>,
    t: TFunction<'translation', undefined>,
    type: 'withhold' | 'return' | 'returnPayment' | 'transferDepositRoom'
) => {
    if (status && typeof status === 'boolean') {
        if (type === 'withhold') {
            successNotification(t('CHAT_DEPOSIT_1'));
        }
        if (type === 'return') {
            successNotification(t('CHAT_DEPOSIT_10'));
        }
        if (type === 'returnPayment') {
            successNotification(t('CHAT_DEPOSIT_13'));
        }
        if (type === 'transferDepositRoom') {
            successNotification(t('CHAT_DEPOSIT_35'));
        }
        setIsOpen(false);
    } else {
        errorNotification(status);
    }
};
