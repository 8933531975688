import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { getFileFromUrl } from '../../api/functions';
import { add_section, update_hotel_tab } from './hotelSlice';
import { set_token_error } from './loginSlice';

export const meetingRoomSlice = createSlice({
    name: 'meetingRoom',

    initialState: {
        meetingRooms: [],
        meeting_room_options: [],
        selectedMeetingRooms: [],
        hotel_id: '',
        meetingRoomColors: [],
        booking_history: [],
        history_page: 0,
        history_count: null,
        response_status: false,
        meetingRooms_fetch: false,
        update_meetingRoom_fetch: false,
        add_meetingRoom_fetch: false,
        selectedDate: Date.parse(new Date()) / 1000,
    },

    reducers: {
        add_meetingRoom: (state, action) => {
            state.meetingRooms.push(action.payload);
        },
        set_add_meetingRoom_fetch: (state, action) => {
            state.add_meetingRoom_fetch = action.payload;
        },
        set_meetingRooms: (state, action) => {
            state.meetingRooms = action.payload;
        },
        set_hotel_id: (state, action) => {
            state.hotel_id = action.payload;
        },
        set_response_status: (state, action) => {
            state.response_status = action.payload;
        },
        update_meetingRooms: (state, action) => {
            state.meetingRooms = state.meetingRooms.map(el => (el.id === action.payload.id ? { ...el, ...action.payload } : el));
        },
        set_update_meetingRoom_fetch: (state, action) => {
            state.update_meetingRoom_fetch = action.payload;
        },
        set_meetingRoom_colors: (state, action) => {
            state.meetingRoomColors = action.payload;
        },
        add_meetingRoom_booking: (state, action) => {
            state.selectedMeetingRooms = state.selectedMeetingRooms.map(el =>
                el.value === action.payload.meeting_id ? { ...el, bookings: [...el.bookings, action.payload] } : el
            );
        },
        update_meetingRoom_booking: (state, action) => {
            state.selectedMeetingRooms = state.selectedMeetingRooms.map(el => {
                if (el.value === action.payload.meeting_id) {
                    const meeting = el.bookings.map(booking => {
                        if (booking.id === action.payload.id) {
                            return { ...booking, ...action.payload };
                        }
                        return booking;
                    });
                    const bookings = { bookings: meeting };
                    return { ...el, ...bookings };
                }
                return el;
            });
        },
        set_selected_date: (state, action) => {
            state.selectedDate = action.payload;
        },
        set_booking_history: (state, action) => {
            state.booking_history = state.history_page === 0 ? action.payload : [...state.booking_history, ...action.payload];
        },
        set_history_page: (state, action) => {
            state.history_page = action.payload;
        },
        set_history_count: (state, action) => {
            state.history_count = action.payload;
        },
        set_selected_meetingRooms: (state, action) => {
            state.selectedMeetingRooms = action.payload;
        },
        set_meeting_room_options: (state, action) => {
            state.meeting_room_options = action.payload;
        },
        update_all_booking: (state, action) => {
            state.selectedMeetingRooms = state.selectedMeetingRooms.map(ell => {
                const room = action.payload.find(el => el.id === ell.value);
                if (room) return { ...ell, bookings: room.bookings };
            });
        },
    },
});

export const {
    update_all_booking,
    set_meeting_room_options,
    set_selected_meetingRooms,
    set_history_count,
    set_history_page,
    set_booking_history,
    set_selected_date,
    update_meetingRoom_booking,
    add_meetingRoom_booking,
    set_meetingRoom_colors,
    set_add_meetingRoom_fetch,
    update_meetingRooms,
    set_update_meetingRoom_fetch,
    set_hotel_id,
    set_meetingRooms,
    add_meetingRoom,
    set_response_status,
} = meetingRoomSlice.actions;

export const addMeetingRoom =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        const hotel_id = getState().meetingRoom.hotel_id;

        try {
            const files = data.hasOwnProperty('files')
                ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                : [];
            const response = await api.addMeetingRoom.fetch(access_token, hotel_id, {
                ...data,
                files,
                included_options: JSON.stringify(data.included_options),
                addition_options: JSON.stringify(data.addition_options),
            });

            if (response.status === 200) {
                const result = await response.json();
                if (result.status === 'ok') {
                    const files = result.files.map((file, id) => ({ url: file, id: id + 1 }));
                    dispatch(set_response_status(true));
                    dispatch(add_meetingRoom({ ...data, files2: files, id: result.id, hotel_id }));
                    dispatch(add_section({ ...data, files2: files, id: result.id, link: result.id, hotel_id }));
                } else {
                    return result.error;
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
            }
            return response.status;
        } catch (e) {
            console.log('addMeetingRoom error: ', e);
        } finally {
            dispatch(set_add_meetingRoom_fetch(false));
        }
    };

export const editMeetingRoom =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        const hotel_id = getState().meetingRoom.hotel_id;

        try {
            const files = data.hasOwnProperty('files')
                ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                : [];

            const response = await api.editMeetingRoom.fetch(access_token, hotel_id, {
                ...data,
                files,
                included_options: JSON.stringify(data.included_options),
                addition_options: JSON.stringify(data.addition_options),
            });
            if (response.status === 200) {
                const result = await response.json();
                if (result.status === 'ok') {
                    const files = result.files.map((file, id) => ({ url: file, id: id + 1 }));

                    dispatch(set_response_status(true));
                    dispatch(update_meetingRooms({ ...data, hotel_id, files2: files }));
                    dispatch(update_hotel_tab({ ...data, hotel_id, files2: files }));
                } else {
                    return result.error;
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
            }
            return response.status;
        } catch (e) {
            console.log('editMeetingRoom error: ', e);
        } finally {
            dispatch(set_update_meetingRoom_fetch(false));
        }
    };

export const getMeetingRoomColors = () => async (dispatch, getState) => {
    const access_token = getState().login.access_token;

    try {
        const response = await api.getMeetingRoomColors.fetch(access_token);

        if (response.status === 200) {
            const result = await response.json();
            const data = result.map(color => color.color);
            dispatch(set_meetingRoom_colors(data));
        }
    } catch (e) {
        console.log('getMeetingRoomColors error: ', e);
    }
};

export const addMeetingRoomBooking =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        const hotel_id = getState().meetingRoom.hotel_id;
        const date_from = getState().meetingRoom.selectedDate;

        try {
            const date = new Date(data.date * 1000);
            const response = await api.addMeetingRoomBooking.fetch(access_token, hotel_id, {
                ...data,
                date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
            });

            if (response.status === 200) {
                const result = await response.json();
                if (result.status === 'ok') {
                    const date = new Date(data.date * 1000);
                    const dateNow = new Date(date_from * 1000);
                    if (!(date.getFullYear() > dateNow.getFullYear() || date.getMonth() > dateNow.getMonth() || date.getDate() > dateNow.getDate())) {
                        dispatch(add_meetingRoom_booking({ ...data, hotel_id, id: result.id }));
                    }
                } else {
                    return result.error;
                }
            }
        } catch (e) {
            console.log('addMeetingRoomBooking error: ', e);
        }
    };

export const updateMeetingRoomBooking =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        const hotel_id = getState().meetingRoom.hotel_id;
        const date_from = getState().meetingRoom.selectedDate;

        try {
            const date = new Date(data.date * 1000);
            const response = await api.updateMeetingRoomBooking.fetch(access_token, hotel_id, {
                ...data,
                date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
            });

            if (response.status === 200) {
                const result = await response.json();
                if (result.status === 'ok') {
                    const date = new Date(data.date * 1000);
                    const dateNow = new Date(date_from * 1000);
                    if (date.getFullYear() === dateNow.getFullYear() && date.getMonth() === dateNow.getMonth() && date.getDate() === dateNow.getDate()) {
                        dispatch(update_meetingRoom_booking({ ...data, hotel_id }));
                    } else {
                        dispatch(update_meetingRoom_booking({ ...data, hotel_id, active: '0' }));
                    }
                } else {
                    return result.error;
                }
            }
        } catch (e) {
            console.log('updateMeetingRoomBooking error: ', e);
        }
    };

export const updateAllMeetingRoomBooking = () => async (dispatch, getState) => {
    const access_token = getState().login.access_token;
    const hotel_id = getState().meetingRoom.hotel_id;
    const date_from = getState().meetingRoom.selectedDate;

    try {
        const response = await api.getAllMeetingRoomBooking.fetch(access_token, hotel_id, date_from);

        if (response.status === 200) {
            const result = await response.json();
            let data = [];

            for (const key in result) {
                if (Object.hasOwnProperty.call(result, key)) {
                    data = [...data, result[key]];
                }
            }
            dispatch(update_all_booking(data));
        }
    } catch (e) {
        console.log('getAllMeetingRoomBooking error: ', e);
    }
};
export const getAllMeetingRoomForFilter = () => async (dispatch, getState) => {
    const access_token = getState().login.access_token;
    const hotel_id = getState().meetingRoom.hotel_id;
    const date_from = getState().meetingRoom.selectedDate;

    try {
        const response = await api.getAllMeetingRoomBooking.fetch(access_token, hotel_id, date_from);

        if (response.status === 200) {
            const result = await response.json();
            let dataOptions = [];
            let dataMeetingRooms = [];

            for (const key in result) {
                if (Object.hasOwnProperty.call(result, key)) {
                    dataMeetingRooms = [...dataMeetingRooms, result[key]];
                    if (result[key].active === '1') {
                        dataOptions = [
                            ...dataOptions,
                            {
                                label: result[key].name,
                                value: result[key].id,
                                color: result[key].color,
                                start: result[key].working_hours_from,
                                end: result[key].working_hours_to,
                                bookings: result[key].bookings,
                                selected: '1',
                            },
                        ];
                    }
                }
            }
            dispatch(set_meeting_room_options(dataOptions));
            dispatch(set_selected_meetingRooms(dataOptions));
            dispatch(set_meetingRooms(dataMeetingRooms));
        }
    } catch (e) {
        console.log('getAllMeetingRoomBooking error: ', e);
    }
};

export const getBookingHistory =
    ({ page }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        const hotel_id = getState().meetingRoom.hotel_id;

        try {
            const response = await api.getBookingHistory.fetch(access_token, hotel_id, page);

            if (response.status === 200) {
                const result = await response.json();
                if (result.status === 'ok') {
                    dispatch(set_history_count(result.all_count));
                    dispatch(set_booking_history(result.data));
                }
            }
        } catch (e) {
            console.log('getBookingHistory error: ', e);
        }
    };

export const hotel_id = state => state.meetingRoom.hotel_id;
export const meeting_rooms = state => state.meetingRoom.meetingRooms;
export const response_status = state => state.meetingRoom.response_status;
export const update_meetingRoom_fetch = state => state.meetingRoom.update_meetingRoom_fetch;
export const add_meetingRoom_fetch = state => state.meetingRoom.add_meetingRoom_fetch;
export const meetingRoom_colors = state => state.meetingRoom.meetingRoomColors;
export const booking_history = state => state.meetingRoom.booking_history;
export const history_page = state => state.meetingRoom.history_page;
export const history_count = state => state.meetingRoom.history_count;
export const selected_meetingRooms = state => state.meetingRoom.selectedMeetingRooms;
export const meeting_room_options = state => state.meetingRoom.meeting_room_options;

export default meetingRoomSlice.reducer;
