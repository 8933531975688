import { useFormikContext, useField } from 'formik';
import DatePicker from 'react-datepicker';

export const CustomTimePicker = props => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <DatePicker
            {...field}
            {...props}
            autoComplete="off"
            showTimeSelect
            showTimeSelectOnly
            onKeyDown={e => {
                e.preventDefault();
            }}
            onChange={val => {
                if (field.name === 'working_hours_from') {
                    setFieldValue(field.name, val);
                    setFieldValue('working_hours_to', '');
                } else {
                    setFieldValue(field.name, val);
                }
            }}
        />
    );
};
