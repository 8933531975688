import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AppDispatch } from '../../redux/store';
import { blackListFetchState, blackListState, getBlackList } from '../../redux/slices/blackListSlice';
import { BlackList } from './types';

type Tab = {
    hotel_name: string;
    hotel_id: string;
};

type Props = {
    setBlackListData: Dispatch<SetStateAction<BlackList | null>>;
    blackListData: BlackList | null;
    tab: Tab | null;
};
export const List: FC<Props> = ({ setBlackListData, blackListData, tab }) => {
    const [changeHotel, setChangeHotel] = useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const blackList: BlackList[] = useSelector(blackListState);
    const blackListFetching = useSelector(blackListFetchState);

    const { hotel_id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        if (!changeHotel) {
            setChangeHotel(true);
        }
    }, [hotel_id]);

    useEffect(() => {
        if (tab) dispatch(getBlackList(tab.hotel_id));
    }, [tab]);

    return (
        <div className={`md:border-r-gray-10 h-full w-full shrink-0 overflow-auto md:w-1/3 md:border-r ${blackListData?.id ? 'hidden md:block' : 'block'}`}>
            {blackListFetching ? (
                <div className="flex h-full w-full items-center justify-center">
                    <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
                </div>
            ) : blackList.length > 0 ? (
                blackList.map(blackListItem => (
                    <div
                        key={blackListItem.id}
                        className={`border-b-gray-10 relative flex w-full cursor-pointer flex-col border-b py-4 transition-colors ${
                            blackListData?.id === blackListItem.id ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-50'
                        }`}
                        onClick={() => setBlackListData({ id: blackListItem.id, isRead: blackListItem.isRead, roomNumber: blackListItem.roomNumber })}
                    >
                        {!blackListItem.isRead && <div className={`absolute left-1 top-1 h-2 w-2 rounded rounded-br bg-red-500`}></div>}
                        <div className="flex items-center px-6 text-sm">
                            <span className="break-words">{blackListItem.title}</span>
                        </div>
                    </div>
                ))
            ) : (
                <div className="p-3 text-center">{t('CHAT_NO_CHATS')}</div>
            )}
        </div>
    );
};
