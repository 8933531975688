import { ChangeEvent, FC } from 'react';

type Props = {
    inputType: string;
    data: string | boolean;
    name: string;
    callback: (e: ChangeEvent<HTMLInputElement>, name: string) => void;
    status: { payment: boolean; deposit: boolean };
};
export const DepositInput: FC<Props> = ({ inputType, data, name, callback, status }) => {
    const isDisabled = () => {
        if (name === 'refundPayment' && !status.payment) {
            return true;
        }
        if (name !== 'refundPayment' && !status.deposit) {
            return true;
        }
    };
    return (
        <input
            checked={typeof data === 'boolean' ? data : false}
            type={inputType}
            value={typeof data === 'string' ? data : ''}
            name={name}
            onChange={e => callback(e, name)}
            className={`${inputType === 'text' ? 'h-7 w-20 px-2 text-sm' : ''} rounded-2`}
            disabled={isDisabled()}
        />
    );
};
