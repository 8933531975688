import { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';

import { Header } from '../../../../Header.js';
import { RoomList } from '../RoomList.tsx';
import { ContentWrapper } from '../../../../../common/ContentWrapper.js';
import { ListInfo } from './ListInfo.tsx';
import { AccordionInfo } from './AccordionInfo.tsx';

import {
    tab_info,
    updateInfoFetch as updateInfoFetchState,
    set_update_info_fetch,
    createNewInfoSection,
    information_tabs,
    editCombinedInformation,
} from '../../../../../../redux/slices/informationSlice.js';
import { access_token as accessTokenState } from '../../../../../../redux/slices/loginSlice.js';
import { AppDispatch } from '../../../../../../redux/store.ts';
import { getUpdateString, errorNotification, successNotification } from '../../../../../../api/functions.js';
import { Room, TabInfo } from '../types/templateTypes.js';
import { TemplateListSchema } from '../schemas/TemplateListSchema.ts';
import { TemplateAccordionSchema } from '../schemas/TemplateAccordionSchema.ts';
import { errorNotificationAccordion, errorNotificationList } from '../utils/errorNotification.ts';
import { initialValues } from '../utils/initialValues.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../SubmitButton.tsx';
import { MainInfo } from './MainInfo.tsx';

type Props = {
    templateVariant: string;
};

export const TemplateCombined: FC<Props> = ({ templateVariant }) => {
    const params = useParams();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const formikRef = useRef<FormikProps<any>>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch<AppDispatch>();
    const tabInfo: TabInfo = useSelector(tab_info);
    const information: any = useSelector(information_tabs);
    const updateInfoFetch: boolean = useSelector(updateInfoFetchState);
    const access_token: string = useSelector(accessTokenState);

    const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);
    const [initValues, setInitValues] = useState(initialValues);
    const [idSvg, setIdSvg] = useState<number | null>(null);
    const [currentSvg, setCurrentSvg] = useState<string | null>(null);

    useEffect(() => {
        if (params && params.tab_code && information) {
            const tab = information.find(item => item.code === params.tab_code);
            if (tab) {
                setInitValues(prev => ({
                    ...prev,
                    name: tab.name,
                    name_en: tab.name_en,
                    sort: tab.sort,
                    files: tab.files2,
                    active: tab.active === '1' ? ['active'] : [],
                    svg_current: tab.svg_current,
                    tab_item_id: tab.id,
                    json: tab.templateType === 'accordion' ? [] : null,
                    subitems: tab.subitems,
                }));
                setCurrentSvg(typeof tab.svg_current === 'string' ? tab.svg_current : '');
                setIdSvg(null);
            }
        }
    }, [information, params.tab_code]);

    useEffect(() => {
        if (scrollRef.current && selectedRoom) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (selectedRoom && information.length) {
            const tab = information.find(item => item.code === params.tab_code);
            const roomDetail = tab.subitems ? tab.subitems.find(room => room.roomId === selectedRoom.id) : null;

            if (roomDetail) {
                setInitValues(prev => ({
                    ...prev,
                    detail_description: roomDetail.detail_description,
                    detail_description_en: roomDetail.detail_description_en,
                    preview_description: roomDetail.preview_description,
                    preview_description_en: roomDetail.preview_description_en,
                    preview_description2: roomDetail.preview_description2,
                    preview_description2_en: roomDetail.preview_description2_en,
                    roomTemplateId: roomDetail.roomTemplateId ? roomDetail.roomTemplateId : roomDetail.id,
                    json: roomDetail.json ? JSON.parse(roomDetail.json) : [],
                }));
            } else {
                setInitValues(prev => ({
                    ...prev,
                    detail_description: '',
                    detail_description_en: '',
                    preview_description: '',
                    preview_description_en: '',
                    preview_description2: '',
                    preview_description2_en: '',
                    roomTemplateId: '',
                    json: [],
                }));
            }
        }
    }, [selectedRoom, information]);

    const formSubmit = async (values, { setSubmitting }) => {
        let error = false;
        const active = values.active ? (values.active[0] === 'active' ? '1' : '0') : '0';
        const sort: string = values.sort ? values.sort : '100';

        let data = { ...values, hotel_id: tabInfo.hotel_id, sort, active, section_link: tabInfo.link, roomId: selectedRoom?.id, templateType: templateVariant };

        // Проверка на то, создан раздел или нет, если нет - создаем
        if (params.hasOwnProperty('tab_code')) {
            if (templateVariant === 'list') {
                const detail_en: string = await getUpdateString(values.detail_description_en, tab_info, access_token);
                const detail_ru: string = await getUpdateString(values.detail_description, tab_info, access_token);

                if (detail_ru.length > 10000 || detail_en.length > 10000) {
                    return errorNotification(t('INFO_PICSLOADER_ERROR_5'));
                }

                data.detail_description = detail_ru;
                data.detail_description_en = detail_en;
                data.tab_item_id = initValues.tab_item_id;
                data.json = null;
                const status = await dispatch(editCombinedInformation({ data }));
                if (status) successNotification(t('SAVE_INFORMATION'));
            } else {
                const json = await Promise.all(
                    values.json.map(async rebro => {
                        if (rebro.sectionDesc) {
                            const description_ru: string = await getUpdateString(rebro.sectionDesc[0], tab_info, access_token);
                            const description_en: string = await getUpdateString(rebro.sectionDesc[1], tab_info, access_token);

                            if (description_ru.length > 10000 || description_en.length > 10000) {
                                error = true;
                                dispatch(set_update_info_fetch(false));
                                return errorNotification(t('INFO_PICSLOADER_ERROR_5'));
                            } else {
                                return { ...rebro, sectionDesc: [description_ru, description_en] };
                            }
                        } else {
                            return rebro;
                        }
                    })
                );

                if (error) return;

                data.json = JSON.stringify(json);
                const status = await dispatch(editCombinedInformation({ data }));
                if (status) successNotification(t('SAVE_INFORMATION'));
            }
        } else {
            const result = await dispatch(createNewInfoSection({ data }));
            if (result && result.link) {
                navigate(`/object/${tabInfo.hotel_id}/information/${result.link}/`);
            } else if (result === 20) {
                errorNotification(t('INFO_ERROR_82'));
            }
            if (result === 500) {
                errorNotification('Что-то пошло не так, обратитесь к администратору');
            }
            setSubmitting(false);
        }
    };

    return (
        <>
            <Header
                tab={{
                    ...tabInfo,
                    name: params && params.tab_code ? initValues.name : t('INFO_TEMPLATE_SECTION'),
                    name_en: params && params.tab_code ? initValues.name_en : t('INFO_TEMPLATE_SECTION'),
                }}
            />
            <ContentWrapper>
                <Formik
                    initialValues={initValues}
                    validationSchema={templateVariant === 'list' ? TemplateListSchema : TemplateAccordionSchema}
                    innerRef={formikRef}
                    onSubmit={formSubmit}
                    enableReinitialize={true}
                >
                    {({ values, errors, isValidating, isSubmitting, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            templateVariant === 'list' ? errorNotificationList(errors, t) : errorNotificationAccordion(errors, t);
                        }
                        return (
                            <Form>
                                <MainInfo
                                    values={values}
                                    isSubmitting={isSubmitting}
                                    setFieldValue={setFieldValue}
                                    setInitValues={setInitValues}
                                    idSvg={idSvg}
                                    setIdSvg={setIdSvg}
                                    currentSvg={currentSvg}
                                />
                                {params && !params.tab_code && <SubmitButton dirty={dirty} loading={updateInfoFetch} name="CREATE" />}
                                {params && params.tab_code && <RoomList setSelectedRoom={setSelectedRoom} selectedRoom={selectedRoom} scrollRef={scrollRef} />}

                                {selectedRoom ? (
                                    templateVariant === 'list' ? (
                                        <ListInfo
                                            values={values}
                                            isSubmitting={isSubmitting}
                                            selectedRoom={selectedRoom}
                                            dirty={dirty}
                                            loading={updateInfoFetch}
                                        />
                                    ) : (
                                        <AccordionInfo
                                            dirty={dirty}
                                            loading={updateInfoFetch}
                                            values={values}
                                            isSubmitting={isSubmitting}
                                            selectedRoom={selectedRoom}
                                        />
                                    )
                                ) : null}
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
        </>
    );
};
