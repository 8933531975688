import * as Yup from 'yup';

export const ReviewSchema = Yup.object().shape({
    mainText: Yup.string().required('REVIEW_ERROR').min(10, 'REVIEW_ERROR_1').max(100, 'REVIEW_ERROR_2'),
    mainTextEn: Yup.string().required('REVIEW_ERROR_3').min(10, 'REVIEW_ERROR_4').max(100, 'REVIEW_ERROR_5'),
    time: Yup.string().required('REVIEW_ERROR_6'),
    secondButtons: Yup.array().of(
        Yup.object().shape({
            text: Yup.string().required('REVIEW_ERROR_7').min(3, 'REVIEW_ERROR_8').max(20, 'REVIEW_ERROR_9'),
        })
    ),
    secondButtonsEn: Yup.array().of(
        Yup.object().shape({
            text: Yup.string().required('REVIEW_ERROR_10').min(3, 'REVIEW_ERROR_11').max(20, 'REVIEW_ERROR_12'),
        })
    ),
});
