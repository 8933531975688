export const initialValues = {
    name: '',
    name_en: '',
    seats: '',
    working_hours_from: '',
    working_hours_to: '',
    price_hour: '',
    files: [],
    included_options: [],
    addition_options: [],
    active: null,
    color: '',
};
