import { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { listenForOutsideClick } from '../../api/functions';

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const langReg = useRef();
    const [_, setCookie] = useCookies();

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [listening, setListening] = useState(false);

    useEffect(listenForOutsideClick(listening, setListening, langReg, setMenuIsOpen));

    const langArr = Object.keys(i18n.options.resources);

    const handlerLanguageChoose = async lang => {
        setCookie('language', lang, { path: '/' });
        setMenuIsOpen(false);
        await i18n.changeLanguage(lang);
    };

    return (
        <div ref={langReg} className="relative ml-1 cursor-pointer">
            <button
                type="button"
                onClick={() => setMenuIsOpen(!menuIsOpen)}
                className="w-10 rounded-full p-2 uppercase text-blue-500 hover:bg-gray-100 hover:text-blue-600"
            >
                {i18n.language}
            </button>
            {menuIsOpen && (
                <div className="absolute flex flex-col rounded-md border border-gray-100 bg-white px-2">
                    {langArr.map(lang => (
                        <span key={lang} onClick={() => handlerLanguageChoose(lang)} className="py-1 uppercase hover:text-blue-500 ">
                            {lang}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};
