import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Field, Form, Formik } from 'formik';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { CustomPhoneInput } from './customFields/CustomPhoneInput';
import { CustomSelectField } from './customFields/CustomSelectField';
import { CustomDatePicker } from './customFields/CustomDatePicker';
import { CustomTimePicker } from './customFields/CustomTimePicker';
import { getFilteredTimeStart } from './helpers/getFilteredTimeStart';
import { meetingSchema } from './helpers/meetingSchema';
import { errorNotification } from '../../../../api/functions';
import { meeting_rooms as meeting_rooms_state, addMeetingRoomBooking, updateMeetingRoomBooking } from '../../../../redux/slices/meetingRoomSlice';

const initialValue = {
    fio: '',
    phone: '',
    date: '',
    meeting_id: '',
    start: '',
    end: '',
    comment: '',
};

export const CreateMeetingPane = ({ isOpen, setIsOpen, selectedDate, bookingData, setBookingData }) => {
    const formikRef = useRef();

    const dispatch = useDispatch();
    const meetingRooms = useSelector(meeting_rooms_state);

    const [formValues, setFormValues] = useState(initialValue);

    const meetingRooms_options = meetingRooms.map(el => ({ label: el.name, value: el.id, start: el.working_hours_from, end: el.working_hours_to }));

    useEffect(() => {
        if (bookingData) {
            const start_time = new Date(new Date(selectedDate).setHours(bookingData.start.split(':')[0], bookingData.start.split(':')[1]));
            const time_end = bookingData?.end ? new Date(new Date(selectedDate).setHours(bookingData.end.split(':')[0], bookingData.end.split(':')[1])) : '';
            setFormValues(formValues => ({
                ...formValues,
                date: bookingData.hasOwnProperty('id')
                    ? new Date(bookingData.date * 1000)
                    : bookingData.hasOwnProperty('selectedDate')
                    ? bookingData.selectedDate
                    : '',
                start: start_time,
                end: time_end,
                meeting_id: meetingRooms_options.find(el => el.value === bookingData.meeting_id),
                fio: bookingData?.fio ? bookingData.fio : '',
                phone: bookingData?.phone ? bookingData.phone : '',
                comment: bookingData?.comment ? bookingData.comment : '',
            }));
        }
    }, [selectedDate, bookingData]);

    const closePopupHandler = () => {
        setIsOpen(false);
        setFormValues(initialValue);
        setBookingData(null);
    };

    const formSubmit = async values => {
        const hoursStart = String(values.start.getHours());
        const minuteStart = String(values.start.getMinutes());
        const hoursEnd = String(values.end.getHours());
        const minuteEnd = String(values.end.getMinutes());
        const date = Date.parse(values.date) / 1000;

        const data = {
            fio: values.fio,
            phone: values.phone,
            date,
            meeting_id: values.meeting_id.value,
            start: `${hoursStart.padStart(2, '0')}:${minuteStart.padStart(2, '0')}`,
            end: `${hoursEnd.padStart(2, '0')}:${minuteEnd.padStart(2, '0')}`,
            comment: values.comment,
            id: bookingData ? (bookingData.hasOwnProperty('id') ? bookingData.id : '') : '',
            active: values.hasOwnProperty('active') ? values.active : '1',
        };

        let error;
        // проверка создавать или обновлять бронирование
        if (bookingData) {
            if (bookingData.hasOwnProperty('id')) {
                if (bookingData.meeting_id !== data.meeting_id) {
                    error = await dispatch(addMeetingRoomBooking({ data }));
                    if (!error) {
                        error = await dispatch(updateMeetingRoomBooking({ data: { ...data, meeting_id: bookingData.meeting_id, active: '0' } }));
                    }
                } else {
                    error = await dispatch(updateMeetingRoomBooking({ data }));
                }
            } else {
                error = await dispatch(addMeetingRoomBooking({ data }));
            }
        } else {
            error = await dispatch(addMeetingRoomBooking({ data }));
        }
        if (!error) {
            setIsOpen(false);
            setFormValues(initialValue);
            setBookingData(null);
        } else {
            errorNotification(error);
        }
    };

    const handlerCancelBooking = formValues => {
        formSubmit({ ...formValues, active: '0' });
    };

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePopupHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="25rem"
        >
            <div className=" sticky -top-6 z-20 -mt-6 w-full bg-white py-4">
                <div className="flex items-center justify-between">
                    <div className="text-xl font-bold text-black">Добавить событие</div>
                    <button className="flex h-9 w-9 items-center justify-center rounded-full bg-transparent p-0 hover:bg-gray-100" onClick={closePopupHandler}>
                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                        </svg>
                    </button>
                </div>
                {bookingData && bookingData.id && (
                    <div className="absolute flex w-full">
                        <button
                            type="button"
                            onClick={() => handlerCancelBooking(formValues)}
                            className="rounded border bg-slate-400 px-1 py-0.5 text-xs text-white hover:bg-slate-600"
                        >
                            Отменить бронь
                        </button>
                    </div>
                )}
            </div>

            <Formik initialValues={formValues} validationSchema={meetingSchema} onSubmit={formSubmit} innerRef={formikRef} enableReinitialize={true}>
                {({ values, errors, isSubmitting, isValidating, setFieldValue }) => {
                    if (isSubmitting && !isValidating) {
                        for (const err in errors) {
                            errorNotification(errors[err].toString());
                        }
                    }
                    return (
                        <Form>
                            {/* ФИО */}
                            <div className=" pr-6">
                                <p className={`text-gray-20 mb-2 mt-5 flex w-full text-sm`}> Ф.И.О.</p>
                                <Field
                                    name="fio"
                                    value={values.fio}
                                    placeholder="Введите фио"
                                    className="box-border h-8 w-full rounded border border-gray-300 p-1 pl-2 text-sm"
                                    disabled={isSubmitting}
                                />
                            </div>

                            {/* номер телефона */}
                            <div className="mt-6 pr-6">
                                <p className="text-gray-20 mb-2 flex w-full text-sm">Телефон</p>
                                <CustomPhoneInput
                                    name="phone"
                                    placeholder="Введите номер телефона"
                                    value={values.phone}
                                    className="box-border h-8 w-full rounded border border-gray-300 p-1 pl-2 text-sm"
                                    onChange={e => {
                                        const value = e.target.value || '';
                                        setFieldValue('phone', value);
                                    }}
                                />
                            </div>

                            {/* переговорки */}
                            <div className="mt-6 pr-6">
                                <p className="text-gray-20 mb-2 flex w-full text-sm">Переговорная</p>
                                <CustomSelectField
                                    name="meeting_id"
                                    options={meetingRooms_options}
                                    placeholder="Выберите переговорную"
                                    className="mt-1 w-full text-sm"
                                    defaultValue={bookingData && meetingRooms_options.find(el => el.value === bookingData.meeting_id)}
                                />
                            </div>

                            {/* дата */}
                            <div className="mt-6 pr-6">
                                <p className="text-gray-20 mb-2 flex w-full text-sm">Дата</p>
                                <CustomDatePicker name="date" selected={values.date} className="h-10 w-full rounded border-gray-300" minDate={Date.now()} />
                            </div>

                            {/* время */}
                            <div className="mt-6 pr-6">
                                <p className="text-gray-20 mb-2 flex w-full text-sm">Время</p>

                                <div className="flex gap-6">
                                    <div>
                                        <p className="text-xs">Начало</p>
                                        <CustomTimePicker
                                            name="start"
                                            selected={values.start}
                                            timeIntervals={30}
                                            className="h-8 w-24 rounded text-sm"
                                            filterTime={time => getFilteredTimeStart(time, values)}
                                            disabled={values.meeting_id.length === 0}
                                        />
                                    </div>

                                    <div>
                                        <p className="text-xs">Завершение</p>
                                        <CustomTimePicker
                                            name="end"
                                            selected={values.end}
                                            timeIntervals={30}
                                            className="h-8 w-24 rounded text-sm"
                                            minTime={
                                                values.start &&
                                                setHours(
                                                    setMinutes(new Date(values.start), values.start.getMinutes() === 30 ? 40 : 30),
                                                    values.start.getHours()
                                                )
                                            }
                                            maxTime={new Date(2021, 1, 1, values.meeting_id.end, 0)}
                                            disabled={values.start.length === 0}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* комментарий */}
                            <div className="mt-6 pr-6">
                                <p className="text-gray-20 mb-2 flex w-full text-sm">Описание</p>
                                <Field
                                    name="comment"
                                    component="textarea"
                                    value={values.comment}
                                    placeholder="Оставьте комментарий..."
                                    className="box-border h-24 w-full rounded border border-gray-300 p-1 pl-2 text-sm"
                                    disabled={isSubmitting}
                                />
                            </div>

                            <div className="border-t-gray-10 fixed bottom-0 left-0 h-16 w-full border-t bg-white">
                                <div className="flex h-full w-full flex-row items-center px-4">
                                    <button
                                        type="submit"
                                        className="mr-6 rounded border-blue-400 bg-blue-400 px-2 py-1 text-white transition-colors hover:bg-blue-500"
                                    >
                                        {bookingData && bookingData.hasOwnProperty('id') ? 'Сохранить' : 'Применить'}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={closePopupHandler}
                                        className="border-transparent bg-transparent text-gray-500 transition-colors hover:bg-transparent hover:text-gray-900"
                                    >
                                        Отменить
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </SlidingPane>
    );
};
