import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';
import moment from 'moment';

export const loyaltySlice = createSlice({
    name: 'loyalty',

    initialState: {
        users: [],
        users_fetching: false,
        page: 1,
        total_count: null,
        filter: '',
        // перенести в management slice
        paymentsData: { totalCount: 0, data: [] },
        paymentsFilter: {
            date_from: null,
            date_to: null,
            pay_type: null,
            page: 1,
        },
        paymentsDataFetching: true,
    },

    reducers: {
        set_users: (state, action) => {
            state.users = state.page === 1 ? action.payload.data : [...state.users, ...action.payload.data];
        },
        update_users: (state, action) => {
            state.users = state.users.map(user => {
                if (user.id === action.payload.guest_id) {
                    return { ...user, ...action.payload };
                }

                return user;
            });
        },
        set_users_fetching: (state, action) => {
            state.users_fetching = action.payload;
        },
        set_page: (state, action) => {
            state.page = action.payload;
        },
        set_user_count: (state, action) => {
            state.total_count = action.payload;
        },
        set_filter: (state, action) => {
            state.filter = action.payload;
            state.page = 1;
            state.total_count = null;
        },
        // перенести в management slice
        setPaymentsData: (state, action) => {
            if (action.payload.page === 1) {
                state.paymentsData = { totalCount: action.payload.totalCount, data: action.payload.data };
            } else {
                state.paymentsData = { totalCount: action.payload.totalCount, data: [...state.paymentsData.data, ...action.payload.data] };
            }
        },
        setPaymentsFilter: (state, action) => {
            state.paymentsFilter = { ...state.paymentsFilter, ...action.payload };
        },
        resetPaymentData: state => {
            state.paymentsData = { totalCount: 0, data: [] };
            state.paymentsFilter = {
                date_from: null,
                date_to: null,
                pay_type: null,
                page: 1,
            };
        },
        setPaymentsDataFetching: (state, action) => {
            state.paymentsDataFetching = action.payload;
        },
    },
});

export const {
    setPaymentsDataFetching,
    resetPaymentData,
    setPaymentsData,
    setPaymentsFilter,
    set_filter,
    set_user_count,
    set_page,
    set_users,
    update_users,
    set_users_fetching,
} = loyaltySlice.actions;

export const getUsers =
    ({ access_token, page, userFilter }) =>
    async dispatch => {
        dispatch(set_users_fetching(true));
        try {
            if (access_token) {
                const response = await api.getUsersList.fetch(access_token, page, userFilter);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.hasOwnProperty('result')) {
                        dispatch(set_user_count(res.conut));
                        dispatch(
                            set_users({
                                data: res.result.map(el => ({
                                    name: el.Name,
                                    loyalty_days: el.days,
                                    loyalty_discount: el.percent_discont,
                                    loyalty_status_name: el.status_name,
                                    loyalty_status_name_en: el.status_name_en,
                                    loyalty_status_id: el.status_id,
                                    black_list: el.black_list,
                                    phone: el.clean_phone ? `+${el.clean_phone}` : '',
                                    email: el.email,
                                    id: el.guest_id,
                                    comment: el.comment || '',
                                    loyalty_days_detail: el.days_detail.map(item => ({
                                        hotel: item.hotel_name,
                                        room: item.room_num,
                                        date_from: new Date(item.date_from).toLocaleString('ru', {
                                            month: 'numeric',
                                            day: 'numeric',
                                            year: 'numeric',
                                        }),
                                        date_to: new Date(item.date_to).toLocaleString('ru', {
                                            month: 'numeric',
                                            day: 'numeric',
                                            year: 'numeric',
                                        }),
                                    })),
                                })),
                            })
                        );
                    }
                } else {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('setUsers error: ', e);
        } finally {
            dispatch(set_users_fetching(false));
        }
    };

export const editUser =
    ({ access_token, data }) =>
    async dispatch => {
        try {
            if (access_token) {
                const response = await api.editUser.fetch(access_token, data);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.result === 'guest updated') {
                        dispatch(update_users({ ...data }));
                    }
                } else {
                    dispatch(set_token_error(true));
                }

                return response.status;
            }
        } catch (e) {
            console.log('editUser error: ', e);
        }

        return 0;
    };

// перенести в management slice
export const getPayments =
    ({ data }) =>
    async (dispatch, getState) => {
        if (data.page === 1) {
            dispatch(setPaymentsDataFetching(true));
        }
        try {
            const access_token = getState().login.access_token;
            if (access_token) {
                const response = await api.getPaymentList.fetch(access_token, data);
                if (response.status === 200) {
                    const result = await response.json();

                    if (result.result.bookings.length) {
                        dispatch(
                            setPaymentsData({
                                page: data.page,
                                totalCount: +result.result.count,
                                data: result.result.bookings.map(el => ({
                                    balance: el.balance,
                                    roomNumber: el.room_number,
                                    id: el.ext_id,
                                    paymentMethod: el.pay,
                                    date: `${moment(el.date_from).format('DD.MM.YYYY')}-${moment(el.date_to).format('DD.MM.YYYY')}`,
                                })),
                            })
                        );
                    } else {
                        dispatch(setPaymentsData({ totalCount: 0, data: [], page: 1 }));
                    }
                }
            }
        } catch (e) {
            console.log('getPayments error: ', e);
        } finally {
            dispatch(setPaymentsDataFetching(false));
        }
    };

export const users = state => state.loyalty.users;
export const users_fetching = state => state.loyalty.users_fetching;
export const page = state => state.loyalty.page;
export const total_count = state => state.loyalty.total_count;
export const userFilter = state => state.loyalty.filter;
// перенести в management slice
export const paymentsDataState = state => state.loyalty.paymentsData;
export const paymentsFilterState = state => state.loyalty.paymentsFilter;
export const paymentsDataFetchingState = state => state.loyalty.paymentsDataFetching;
export default loyaltySlice.reducer;
