import { Calendar } from './Calendar';
import { CreateBookingButton } from './CreateBookingButton';
import { Filter } from './Filter';

export const Header = ({ setCreateMeetingPaneIsOpen, selectedDate, setSelectedDate }) => {
    return (
        <div className="sticky top-16 z-10 flex h-16 justify-between border-b bg-white px-6 py-4">
            <div className="flex gap-3">
                <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                <Filter />
            </div>
            <CreateBookingButton setPaneIsOpen={setCreateMeetingPaneIsOpen} />
        </div>
    );
};
