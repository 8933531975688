import { FC } from 'react';
import { InitialValues } from './utils/types';
import { useTranslation } from 'react-i18next';

type Props = {
    formData: InitialValues;
    setFormData: React.Dispatch<React.SetStateAction<InitialValues>>;
    handlerInputChange: (e: any, name: string) => void;
    photoPreview: string | null;
    setPhotoPreview: React.Dispatch<React.SetStateAction<string | null>>;
    fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
};
export const ReasonBlock: FC<Props> = ({ formData, setFormData, handlerInputChange, photoPreview, setPhotoPreview, fileInputRef }) => {
    const { t } = useTranslation();

    return (
        <div className="mt-2 flex flex-col">
            <span className="mr-2 text-sm">{t('CHAT_DEPOSIT_29')}</span>
            <div className="flex w-full">
                <textarea
                    value={formData.withholdReason}
                    onChange={e => handlerInputChange(e, 'withholdReason')}
                    className="h-20 max-h-[200px] min-h-[60px] w-72 rounded border border-gray-300 px-2 py-1 text-sm placeholder:text-xs"
                    name="withholdReason"
                    placeholder={t('CHAT_DEPOSIT_30')}
                />
                {photoPreview ? (
                    <div className="relative ml-2 flex items-center bg-black/50">
                        <button
                            type="button"
                            className="absolute right-1 top-1"
                            onClick={() => {
                                setPhotoPreview('');
                                setFormData(prev => ({ ...prev, photo: null }));
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = '';
                                }
                            }}
                        >
                            <svg className="h-3 w-3 fill-white" viewBox="0 0 14 14">
                                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                            </svg>
                        </button>
                        <img src={photoPreview} alt="photo" className="max-h-20 w-20" />
                    </div>
                ) : (
                    <label
                        htmlFor="photoOfProblem"
                        className="ml-2 flex w-20 cursor-pointer flex-col items-center justify-center text-center text-sm hover:text-blue-500"
                    >
                        <span>{t('CHAT_DEPOSIT_31')}</span>
                        <svg className="h-6 w-6 fill-current stroke-current" viewBox="0 0 20 20">
                            <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </label>
                )}
                <input
                    type="file"
                    name="photo"
                    id="photoOfProblem"
                    accept="image/*"
                    className="hidden"
                    onChange={e => handlerInputChange(e, 'photo')}
                    ref={fileInputRef}
                />
            </div>
        </div>
    );
};
