import { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import './source/styles/index.scss';

import { Navigation } from './components/navigation/Navigation';
import { InitialLayout } from './components/common/initialLayout/InitialLayout';

export const App: FC = () => {
    return (
        <>
            <InitialLayout>
                <Navigation />
            </InitialLayout>

            <ToastContainer />
        </>
    );
};
