import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { ContentWrapper } from '../common/ContentWrapper';
import { MainDetailAccordion } from './templatesPages/templatePart/MainDetailAccordion';
import { CustomReactQuill } from '../common/CustomReactQuill';
import { getUpdateString, errorNotification } from '../../api/functions';
import { AccordionSchema } from './schemas/AccordionSchema';
import {
    updateInfoFetch as updateInfoFetch_state,
    editInformation,
    set_update_info_fetch,
    information_icons as information_icons_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

export const HotelServicesAccord = ({ tab_info, initValuesProp, jsonInfo }) => {
    const [initValues, setInitValues] = useState(initValuesProp);
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [idSvg, setIdSvg] = useState('');

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const information_icons = useSelector(information_icons_state);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        setInitValues({ ...initValuesProp, json: jsonInfo });
        setIdSvg('');
    }, [initValuesProp]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        dispatch(set_update_info_fetch(true));
        let error;

        const json = await Promise.all(
            values.json.map(async rebro => {
                if (rebro.sectionDesc) {
                    const description_ru = await getUpdateString(rebro.sectionDesc[0], tab_info, access_token);
                    const description_en = await getUpdateString(rebro.sectionDesc[1], tab_info, access_token);

                    if (description_ru.length > 10000 || description_en.length > 10000) {
                        error = true;
                        dispatch(set_update_info_fetch(false));
                        return errorNotification(t('INFO_PICSLOADER_ERROR_5'));
                    } else {
                        return { ...rebro, sectionDesc: [description_ru, description_en] };
                    }
                } else {
                    return rebro;
                }
            })
        );

        if (error) return;

        let status;
        const active = values.active ? (values.active[0] === 'active' ? '1' : '0') : '0';
        const data = {
            ...values,
            hotel_id: tab_info.hotel_id,
            tab_item_id: tab_info.id,
            sort: values.sort ? values.sort : '100',
            active,
            json: JSON.stringify(json),
            section_link: tab_info.section_link,
        };

        status = await dispatch(editInformation({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm({ initValues });
            setIdSvg('');
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const handleSvgClick = (e, setFieldValue) => {
        const ev = e.target.closest('div').dataset.svg;
        if (ev) {
            const icon = information_icons.find(el => el.id === Number(ev));
            setIdSvg(Number(ev));
            setFieldValue('svg_current', icon.icon);
        }
    };

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={AccordionSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                if (err === 'json') {
                                    errors[err].map(el => {
                                        for (const err in el) {
                                            if (Array.isArray(el[err])) {
                                                el[err].map(el => errorNotification(t(el)));
                                            } else if (typeof el[err] === 'object') {
                                                el[err].head?.first?.map(el => errorNotification(t(el)));
                                                el[err].head?.second?.map(el => errorNotification(t(el)));
                                                el[err].body?.map(el => {
                                                    for (const err in el) {
                                                        errorNotification(t(el[err]));
                                                    }
                                                });
                                            } else {
                                                errorNotification(t(el[err]));
                                            }
                                        }
                                    });
                                } else {
                                    errorNotification(t(errors[err]));
                                }
                            }
                        }
                        return (
                            <Form>
                                {/* main info */}
                                <MainDetailAccordion
                                    values={values}
                                    isSubmitting={isSubmitting}
                                    dataSvg={information_icons}
                                    handleSvgClick={handleSvgClick}
                                    idSvg={idSvg}
                                    setFieldValue={setFieldValue}
                                    initValues={initValuesProp}
                                    sortDispatch={sortDispatch}
                                />

                                {/* content */}
                                <div className=" mt-3 bg-white sm:w-full">
                                    {values.json && (
                                        <FieldArray name="json">
                                            {({ remove, unshift }) => (
                                                <div>
                                                    <div className="flex items-center justify-evenly border-b p-6">
                                                        <button
                                                            type="button"
                                                            className="flex w-1/3 flex-row items-center rounded-full bg-blue-500 px-6 py-2 text-white transition-colors hover:bg-blue-800"
                                                            onClick={() =>
                                                                unshift({
                                                                    sectionName: '',
                                                                    sectionName_en: '',
                                                                    sectionDesc: ['', ''],
                                                                    id: Date.now(),
                                                                    isDesc: true,
                                                                })
                                                            }
                                                        >
                                                            <svg className="mr-2 h-10 w-10 fill-current stroke-current" viewBox="0 0 20 20">
                                                                <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            {t('INFO_ADD_SECTION_TEXT')}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="flex w-1/3 flex-row items-center rounded-full bg-blue-500 px-6 py-2 text-white transition-colors hover:bg-blue-800"
                                                            onClick={() =>
                                                                unshift({
                                                                    sectionName: '',
                                                                    sectionName_en: '',
                                                                    sectionTable: {
                                                                        isTable: true,
                                                                        head: { isTable: true, first: ['', ''], second: ['', ''] },
                                                                        body: [
                                                                            {
                                                                                cellFirst: '',
                                                                                cellFirst_en: '',
                                                                                cellSecond: '',
                                                                                cellSecond_en: '',
                                                                                id: Date.now(),
                                                                            },
                                                                        ],
                                                                        payment: [],
                                                                    },
                                                                    id: Date.now(),
                                                                    isDesc: false,
                                                                })
                                                            }
                                                        >
                                                            <svg className="mr-2 h-10 w-10 fill-current stroke-current" viewBox="0 0 20 20">
                                                                <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            {t('INFO_ADD_SECTION_TABLE')}
                                                        </button>
                                                    </div>
                                                    {values.json.map((section, id) => (
                                                        <div className="pb-8 pt-8 shadow-lg" key={section.id}>
                                                            {/* section naming */}
                                                            <p className="text-gray-20 pl-6 pr-6 text-center">
                                                                {t('INFO_SECTION_NAME_1')}:
                                                                {i18n.language === 'ru' ? section?.sectionName : section?.sectionName_en}
                                                            </p>
                                                            <div className="mt-3 flex">
                                                                <div className="w-1/2 pl-6 pr-6">
                                                                    <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME_1')}</p>
                                                                    <Field
                                                                        name={`json.${id}.sectionName`}
                                                                        component="textarea"
                                                                        placeholder={t('INFO_SECTION_NAME_1')}
                                                                        className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                            values?.json[id]?.sectionName === '' ? 'border-red-500' : ''
                                                                        }`}
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                                <div className="w-1/2 pl-6 pr-6">
                                                                    <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME_EN_1')}</p>
                                                                    <Field
                                                                        name={`json.${id}.sectionName_en`}
                                                                        component="textarea"
                                                                        placeholder={t('INFO_SECTION_NAME_EN_1')}
                                                                        className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                            values?.json[id]?.sectionName_en === '' ? 'border-red-500' : ''
                                                                        }`}
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {/* text description */}
                                                                {values.json[id]?.sectionDesc && (
                                                                    <div className="flex">
                                                                        <div className="mt-3 w-1/2 pb-6 pl-6 pr-6">
                                                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION')}</p>
                                                                            <Field
                                                                                name={`json.${id}.sectionDesc[0]`}
                                                                                className="border-gray-10 box-border h-24 w-1/2 rounded p-1 pl-2 text-sm"
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                {({ field, imageHandler }) => (
                                                                                    <CustomReactQuill
                                                                                        imageHandler={imageHandler}
                                                                                        value={field.value}
                                                                                        onChange={field.onChange(field.name)}
                                                                                        placeholder={`${t('DESCRIPTION')}...`}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                        <div className="mt-3 w-1/2 pb-6 pl-6 pr-6">
                                                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION_EN')}</p>
                                                                            <Field
                                                                                name={`json.${id}.sectionDesc[1]`}
                                                                                className="border-gray-10 box-border h-24 w-1/2 rounded p-1 pl-2 text-sm"
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                {({ field, imageHandler }) => (
                                                                                    <CustomReactQuill
                                                                                        imageHandler={imageHandler}
                                                                                        value={field.value}
                                                                                        onChange={field.onChange(field.name)}
                                                                                        placeholder={`${t('DESCRIPTION_EN')}...`}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/* table description */}
                                                                {values.json[id].sectionTable && (
                                                                    <>
                                                                        <div className="mb-2 ml-6 mt-6 flex items-center">
                                                                            {/* <p className="mr-3 text-xs">Будет ли производиться оплата через эту секцию</p> */}
                                                                            {/* <Field name={`json.${id}.sectionTable.payment`} type="checkbox" value="true" /> */}
                                                                        </div>
                                                                        {/* table header */}
                                                                        <div className="flex">
                                                                            <div className="w-1/2 pl-6 pr-6">
                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_NAME_FIRST_COLUMN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.first[0]`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_NAME_COLUMN')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.head?.first[0] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_NAME_SECOND_COLUMN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.second[0]`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_NAME_COLUMN')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.head?.second[0] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <div className="w-1/2 pl-6 pr-6">
                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_NAME_FIRST_COLUMN_EN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.first[1]`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_NAME_COLUMN')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.head?.first[1] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_NAME_SECOND_COLUMN_EN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.head.second[1]`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_NAME_COLUMN')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.head?.second[1] === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <FieldArray name={`json[${id}].sectionTable.body`}>
                                                                            {({ remove, unshift }) => (
                                                                                <>
                                                                                    <button
                                                                                        className="w-19 mb-3 ml-6 mt-3 rounded border border-blue-500 bg-white pl-2 pr-2 text-xs text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
                                                                                        type="button"
                                                                                        onClick={() =>
                                                                                            unshift({
                                                                                                cellFirst: '',
                                                                                                cellFirst_en: '',
                                                                                                cellSecond: '',
                                                                                                cellSecond_en: '',
                                                                                                id: Date.now(),
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        {t('INFO_ADD_ROW')}
                                                                                    </button>
                                                                                    {/* table rows */}
                                                                                    {values.json[id].sectionTable.body.length > 0 &&
                                                                                        values.json[id].sectionTable.body.map((cell, idx) => (
                                                                                            <div className="mt-3 flex border-b pb-3" key={cell.id}>
                                                                                                <div className="w-1/2 pl-6 pr-6 ">
                                                                                                    <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                                        {t('INFO_CELL_OF_FIRST_COLUMN')}
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellFirst`}
                                                                                                        component="textarea"
                                                                                                        placeholder={t('INFO_CELL_CONTENT')}
                                                                                                        className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellFirst === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />

                                                                                                    <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                                        {t('INFO_CELL_OF_SECOND_COLUMN')}
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellSecond`}
                                                                                                        component="textarea"
                                                                                                        placeholder={t('INFO_CELL_CONTENT')}
                                                                                                        className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellSecond === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        onClick={() => remove(idx)}
                                                                                                        className="mt-3 w-40 rounded border border-red-500 bg-white pl-1 pr-1 text-xs text-red-500 transition-colors hover:bg-red-500 hover:text-white"
                                                                                                    >
                                                                                                        {t('INFO_REMOVE_ROW')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="w-1/2 pl-6 pr-6">
                                                                                                    <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                                        {t('INFO_CELL_OF_FIRST_COLUMN_EN')}
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellFirst_en]`}
                                                                                                        component="textarea"
                                                                                                        placeholder={t('INFO_CELL_CONTENT')}
                                                                                                        className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellFirst_en === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />
                                                                                                    <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                                        {t('INFO_CELL_OF_SECOND_COLUMN_EN')}
                                                                                                    </p>
                                                                                                    <Field
                                                                                                        name={`json.${id}.sectionTable.body.${idx}.cellSecond_en`}
                                                                                                        component="textarea"
                                                                                                        placeholder={t('INFO_CELL_CONTENT')}
                                                                                                        className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                                            values?.json[id]?.sectionTable?.body[idx]
                                                                                                                .cellSecond_en === ''
                                                                                                                ? 'border-red-500'
                                                                                                                : ''
                                                                                                        }`}
                                                                                                        disabled={isSubmitting}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <button
                                                                type="button"
                                                                onClick={() => remove(id)}
                                                                className="ml-6 mt-3 w-40 rounded border border-red-700 bg-red-700 pl-1 pr-1 text-xs text-white transition-colors hover:bg-red-500 hover:text-white"
                                                            >
                                                                {t('INFO_REMOVE_SECTION')}
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                                        dirty ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                    } `}
                                    disabled={!dirty || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
                <ReactTooltip
                    id="address"
                    aria-haspopup="true"
                    textColor="#FCFCFC"
                    backgroundColor="#8c8d9d"
                    effect="solid"
                    className="!rounded-md !px-2.5 !py-2"
                >
                    {initValues.address ? (
                        <div>
                            <h2>Адреса квартир:</h2>
                            {initValues.address.split(',').map((address, id) => (
                                <div key={id} className="mb-1">
                                    {address}
                                </div>
                            ))}
                        </div>
                    ) : (
                        t('INFORMATION')
                    )}
                </ReactTooltip>
            </ContentWrapper>
        </>
    );
};
