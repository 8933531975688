import * as Yup from 'yup';

export const MeetingRoomScheme = Yup.object().shape({
    name: Yup.string().min(3, 'Поле "Название" слишком короткое').required('Поле "Название" обязательное'),
    name_en: Yup.string().min(3, 'Поле "Название (на англ)" слишком короткое').required('Поле "Название (на англ)" обязательное'),
    seats: Yup.number()
        .integer("Поле 'Количество мест' должно быть целым числом")
        .min(1, 'Поле "Количество мест" слишком маленькое')
        .max(9999999, 'Поле "Количество мест" слишком большое')
        .required('Поле "Количество мест" обязательное'),
    working_hours_from: Yup.string().required('Поле "Время работы открытия" обязательное'),
    working_hours_to: Yup.string().required('Поле "Время работы закрытия" обязательное'),
    price_hour: Yup.number()
        .integer("Поле 'Стоимость' должно быть целым числом")
        .min(1, 'Поле "Стоимость" слишком маленькое')
        .max(99999999, 'Поле "Стоимость" слишком большое')
        .required('Поле "Стоимость" обязательное'),
    color: Yup.string().required('Поле "Выбор цвета" обязательное'),
    files: Yup.array().min(1, 'Добавьте фото переговорной').required('Добавьте фото переговорной'),
    included_options: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().min(3, 'Поле "Название опции" слишком короткое').required('Поле "Название опции" обязательное'),
            name_en: Yup.string().min(3, 'Поле "Название опции (на англ)" слишком короткое').required('Поле "Название опции (на англ)" обязательное'),
        })
    ),
    addition_options: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().min(3, 'Поле "Название опции" слишком короткое').required('Поле "Название опции" обязательное'),
            name_en: Yup.string().min(3, 'Поле "Название опции (на англ)" слишком короткое').required('Поле "Название опции (на англ)" обязательное'),
            price: Yup.string().min(1, 'Поле "Стоимость опции" слишком короткое').required('Поле "Стоимость опции" обязательное'),
        })
    ),
});
