const localUrl = window.location.href;
const controller = localUrl.includes('guestadmin') ? 'api' : 'dc';

let defaultUrl = 'https://dc-app-test.proptech.digital';
// let defaultUrl = 'https://dc-app.proptech.digital';

if (process.env.REACT_APP_MODE === 'prod') {
    defaultUrl = 'https://dc-app.proptech.digital';
}
// test
export const url = defaultUrl;
export const root = `${url}/?r=${controller}`;
export const rootShop = `${url}/?r=shop`;
