import { root } from '../config';

export const blackListApi = Object.freeze({
    // get black list
    getBlackList: {
        fetch: (accessToken: string, hotelId: string) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            formData.append('hotel_id', hotelId);

            return fetch(`${root}/getblacklist`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get black list user info
    getBlackListUserInfo: {
        fetch: (accessToken: string, id: string) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            formData.append('id', id);

            return fetch(`${root}/getblacklistinfo`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // read black list user info
    readBlackListUserInfo: {
        fetch: (accessToken: string, id: string) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            formData.append('id', id);

            return fetch(`${root}/readblacklistinfo`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
