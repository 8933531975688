import ReactQuill from 'react-quill';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const CustomReactQuill = ({ value, onChange, imageHandler, disabledBtn = true, placeholder }) => {
    const [fildSize, setFildSize] = useState(new Blob([value]).size);
    const { t } = useTranslation();

    let newFildSize = new Blob([value]).size;
    useEffect(() => {
        if (newFildSize - fildSize > 10485760) {
            toast.error(t('QUILL_ERROR'), {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            // disabledBtn(true);
        } else {
            setFildSize(newFildSize);
            // disabledBtn(false);
        }
    }, [value]);

    return (
        <ReactQuill
            value={value}
            onChange={onChange}
            modules={{
                toolbar: {
                    container: disabledBtn
                        ? [
                              [{ size: ['small', false, 'large', 'huge'] }],
                              [{ header: 1 }, { header: 2 }],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{ align: [] }],
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              [{ color: ['red', 'green', 'blue', 'black', 'yellow', 'orange', 'purple'] }],
                              [{ script: 'sub' }, { script: 'super' }],
                              ['link', 'image', 'video'],
                              ['clean'],
                          ]
                        : [
                              [{ size: ['small', false, 'large', 'huge'] }],
                              [{ header: 1 }, { header: 2 }],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{ align: [] }],
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              [{ color: ['red', 'green', 'blue', 'black', 'yellow', 'orange', 'purple'] }],
                              [{ script: 'sub' }, { script: 'super' }],
                              ['link'],
                              ['clean'],
                          ],
                    handlers: {
                        image: imageHandler,
                    },
                },
            }}
            placeholder={placeholder}
        />
    );
};
