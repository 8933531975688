import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';
import { getFileFromUrl } from '../../api/functions';

export const merchSlice = createSlice({
    name: 'merch',

    initialState: {
        merch: [],
        merch_fetching: false,
        edit_merch_fetching: false,
    },

    reducers: {
        set_merch_fetching: (state, action) => {
            state.merch_fetching = action.payload;
        },
        set_edit_merch_fetching: (state, action) => {
            state.edit_merch_fetching = action.payload;
        },
        set_merch: (state, action) => {
            state.merch = action.payload;
        },
        update_merch: (state, action) => {
            state.merch = state.merch.map(el => {
                if (el.id === action.payload.id) {
                    return { ...el, ...action.payload };
                }
                return el;
            });
        },
        add_merch: (state, action) => {
            state.merch.unshift(action.payload);
        },
    },
});

export const { set_merch_fetching, set_edit_merch_fetching, set_merch, add_merch, update_merch } = merchSlice.actions;

export const getMerch =
    ({ access_token, hotel_id, tab_id }) =>
    async dispatch => {
        dispatch(set_merch_fetching(true));

        try {
            if (access_token && hotel_id && tab_id) {
                const response = await api.getHotelTab.fetch(access_token, hotel_id, tab_id);

                if (response.status === 200) {
                    const res = await response.json();
                    dispatch(set_merch(res));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('getMerch error: ', e);
        } finally {
            dispatch(set_merch_fetching(false));
        }
    };

export const editMerch =
    ({ access_token, data }) =>
    async dispatch => {
        dispatch(set_edit_merch_fetching(true));
        try {
            if (access_token && data.id) {
                const files = data.hasOwnProperty('files')
                    ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                    : [];

                const response = await api.editMerchItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (!res.error) {
                        if (data.hasOwnProperty('files')) {
                            const files = res.files.files.map((file, id) => ({ url: file, id: id + 1 }));
                            dispatch(
                                update_merch({
                                    ...data,
                                    img_path: res.files.files[0] || '',
                                    files2: files || [],
                                })
                            );
                        } else {
                            dispatch(update_merch(data));
                        }

                        return { status: 1 };
                    } else {
                        return { status: 0, error: res.error };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                    return { status: 0 };
                }
            } else {
                return { status: 0, error: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз' };
            }
        } catch (e) {
            console.log('editMerch error: ', e);
            return { status: 0, error: e };
        } finally {
            dispatch(set_edit_merch_fetching(false));
        }

        return { status: 0 };
    };

export const addMerch =
    ({ access_token, data }) =>
    async dispatch => {
        dispatch(set_edit_merch_fetching(true));
        try {
            if (access_token && data.hotel_id) {
                const files = await Promise.all(data.files.map(async file => await getFileFromUrl(file.url)));
                const response = await api.addMerchItem.fetch(access_token, { ...data, files });
                if (response.status === 200) {
                    const res = await response.json();
                    if (!res.error && res.id) {
                        const files = res.message_file.files.map((file, id) => ({ url: file, id: id + 1 }));
                        dispatch(
                            add_merch({
                                ...data,
                                id: res.id,
                                img_path: res.message_file.files[0] || '',
                                files2: files,
                            })
                        );
                        return { status: 1 };
                    } else {
                        return {
                            status: 0,
                            error: res?.error || 'Что-то пошло не так. Обновите страницу и попробуйте еще раз',
                        };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                    return { status: 0 };
                }
            } else {
                return { status: 0, error: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз' };
            }
        } catch (e) {
            console.log('addMerch error: ', e);
            return { status: 0, error: e };
        } finally {
            dispatch(set_edit_merch_fetching(false));
        }

        return { status: 0 };
    };

export const sortMerch =
    ({ access_token, data }) =>
    async dispatch => {
        dispatch(set_merch(data));

        try {
            if (access_token && data) {
                const sort_arr = data.map(el => el.id);
                const response = await api.sortMerch.fetch(access_token, JSON.stringify(sort_arr));
                if (response.status === 200) {
                    await response.json();
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('sortMerch error: ', e);
        }
    };

export const merch = state => state.merch.merch;
export const merch_fetching = state => state.merch.merch_fetching;

export default merchSlice.reducer;
