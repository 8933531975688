import { useState, useRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { CustomReactQuill } from '../common/CustomReactQuill';
import NoRoomMessage from '../../source/images/no-personal-message.png';

import { hotelRoomNumbersFetchState, hotelRoomNumbersState, updatePersonalRoomMessage } from '../../redux/slices/informationSlice';
import { errorNotification, successNotification } from '../../api/functions';

export const PersonalMessageFormComponent = ({ hotelId }) => {
    const dispatch = useDispatch();
    const hotelRooms = useSelector(hotelRoomNumbersState);
    const hotelRoomNumbersFetch = useSelector(hotelRoomNumbersFetchState);

    const hotelIdRef = useRef(null);
    const [rooms, setRooms] = useState(null);
    const [selectedRoomNumber, setSelectedRoomNumber] = useState(null);
    const [roomFilter, setRoomFilter] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        if (hotelId !== hotelIdRef.current) {
            hotelIdRef.current = hotelId;
            setSelectedRoomNumber(null);
            setRooms(null);
        }
    }, [hotelId]);

    useEffect(() => {
        if (hotelRooms.length) {
            if (roomFilter) {
                setRooms(hotelRooms.filter(room => room.label.toLocaleLowerCase().includes(roomFilter.toLocaleLowerCase())));
            } else {
                setRooms(hotelRooms);
            }
        }
    }, [hotelRooms]);

    const handlerSaveRoomMessageButtonClick = async () => {
        const status = await dispatch(
            updatePersonalRoomMessage({
                data: {
                    ...selectedRoomNumber,
                    hotelId,
                    address: selectedRoomNumber.address.trim().length ? selectedRoomNumber.address : selectedRoomNumber.label,
                },
            })
        );

        if (status) {
            setSelectedRoomNumber({
                ...selectedRoomNumber,
                address: selectedRoomNumber.address.trim().length ? selectedRoomNumber.address : selectedRoomNumber.label,
            });
            successNotification(t('SAVE_INFORMATION'));
        } else {
            errorNotification(t('NOTIFICATION_ERROR_13'));
        }
    };

    const handlerSearch = value => {
        if (rooms) {
            setRooms(hotelRooms.filter(room => room.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
        }
    };
    return (
        <div className="mt-6 pb-10">
            <div className="flex items-center border-b bg-white">
                <p className="pb-6 pl-6 pt-6 font-medium">{t('NOTIFICATION_PERSONAL_MESSAGE')}</p>
                <div className="ml-auto mr-6" data-tip={t('NOTIFICATION_TOOLTIP_3')}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#1890FF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <div className="mt-3 h-[570px] w-full gap-3 md:flex ">
                <div className="bg-white px-3 pb-3 pt-3 md:w-1/2 md:pb-6">
                    <div className="mb-2 text-center text-sm">
                        <span>{t('NOTIFICATION_ROOM_SEARCH')}</span>
                        <input
                            type="text"
                            className="ml-2 h-7 rounded border border-gray-400 text-sm placeholder:text-xs"
                            placeholder={t('START_SEARCH_PLACEHOLDER')}
                            onChange={e => {
                                handlerSearch(e.target.value);
                                setRoomFilter(e.target.value);
                            }}
                        />
                    </div>
                    <div className="h-96 overflow-auto rounded-md border px-3 py-2 md:h-[95%]">
                        {hotelRoomNumbersFetch ? (
                            <div className=" z-60 flex w-full items-center justify-center">
                                <BallTriangle height="50" width="50" color="#3b82f6" ariaLabel="loading" />
                            </div>
                        ) : rooms && rooms.length ? (
                            rooms.map(number => (
                                <div
                                    key={number.id}
                                    className="mb-2 flex cursor-pointer items-center transition-colors hover:text-blue-500"
                                    onClick={() => {
                                        setSelectedRoomNumber(number);
                                    }}
                                >
                                    <div className={`mr-1 h-2 w-2 shrink-0 rounded-full ${number.message?.length ? 'bg-green-500' : 'bg-gray-400'}`}></div>
                                    <span className={`${selectedRoomNumber?.id === number.id ? 'text-blue-500' : ''}`}>{number.label}</span>
                                </div>
                            ))
                        ) : (
                            <div>{t('NOTIFICATION_ERROR_14')}</div>
                        )}
                    </div>
                </div>
                <div className="quill-personal-message h-full bg-white px-3 py-3 md:w-1/2">
                    {selectedRoomNumber ? (
                        <div className="relative h-full">
                            {/* address */}
                            <div className="mb-2 flex items-center justify-center">
                                <p className="mb-0.5 mr-1 line-clamp-1 shrink-0 text-center text-sm">{t('INFO_ADDRESS')}:</p>
                                <input
                                    type="text"
                                    value={selectedRoomNumber.address ? selectedRoomNumber.address : selectedRoomNumber.label}
                                    className="h-6 w-full min-w-[100px] max-w-[350px] rounded border-gray-300 px-1 py-0.5 text-center text-sm"
                                    onChange={e => {
                                        setSelectedRoomNumber(prev => ({ ...prev, address: e.target.value === '' ? ' ' : e.target.value }));
                                    }}
                                />
                            </div>
                            {/* код от keyBox */}
                            <div className="mb-2 flex items-center justify-center">
                                <p className="mr-1 line-clamp-1 text-center text-sm">{t('NOTIFICATION_ROOM_KEYBOXCODE')}</p>
                                <input
                                    type="text"
                                    className="h-6 w-16 rounded border-gray-300 px-1 py-0.5 text-center text-sm"
                                    onChange={e => {
                                        setSelectedRoomNumber(prev => ({ ...prev, keyBoxCode: e.target.value.replace(/[^0-9]/g, '') }));
                                    }}
                                    value={selectedRoomNumber.keyBoxCode ? selectedRoomNumber.keyBoxCode : ''}
                                    inputMode="numeric"
                                />
                            </div>

                            <div className="h-[440px] rounded-md border">
                                <CustomReactQuill
                                    value={selectedRoomNumber.message ? selectedRoomNumber.message : ''}
                                    onChange={e => {
                                        if (e === '<p><br></p>') {
                                            setSelectedRoomNumber(prev => ({ ...prev, message: '' }));
                                        } else {
                                            setSelectedRoomNumber(prev => ({ ...prev, message: e }));
                                        }
                                    }}
                                    placeholder={t('ENTER_MESSAGE')}
                                    disabledBtn={false}
                                />
                            </div>
                            <button
                                onClick={() => handlerSaveRoomMessageButtonClick()}
                                type="button"
                                className="absolute bottom-0 right-0 inline-flex flex-col items-center justify-start rounded-sm bg-lime-500 px-4 py-2 text-sm text-white hover:bg-lime-600"
                            >
                                {t('SAVE')}
                            </button>
                        </div>
                    ) : (
                        <div className="flex h-full flex-col items-center justify-center">
                            <img src={NoRoomMessage} alt="no-message" className="w-[40%]" />
                            <p className="mt-6 text-center">{t('NOTIFICATION_ROOM_NUMBER')}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const PersonalMessageForm = memo(PersonalMessageFormComponent);
