import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { errorNotification, successNotification } from '../../api/functions';
import { RotatingLines } from 'react-loader-spinner';

import {
    updManagementInfo,
    set_response_status,
    updateInfoFetch as updateInfoFetch_state,
    response_status as response_status_state,
    getHotelRoomNumbers,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { PersonalMessageForm } from './PersonalMessageForm';
import { MessageForm } from './MessageForm';

const Schema = Yup.object().shape({
    title_ru: Yup.string().min(2, 'NOTIFICATION_ERROR_1').max(100, 'NOTIFICATION_ERROR_2').required('NOTIFICATION_ERROR_3'),
    title_en: Yup.string().min(2, 'NOTIFICATION_ERROR_4').max(100, 'NOTIFICATION_ERROR_5').required('NOTIFICATION_ERROR_6'),
    info_ru: Yup.string().min(3, 'NOTIFICATION_ERROR_7').max(1000, 'NOTIFICATION_ERROR_8').required('NOTIFICATION_ERROR_9'),
    info_en: Yup.string().min(3, 'NOTIFICATION_ERROR_10').max(1000, 'NOTIFICATION_ERROR_11').required('NOTIFICATION_ERROR_12'),
});

export const ManageForm = ({ tab }) => {
    const [initialValues, setInitialValues] = useState({ title_ru: '', title_en: '', info_ru: '', info_en: '', message: '' });
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [hotelId, setHotelId] = useState(null);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);

    const { t } = useTranslation();

    useEffect(() => {
        if (response_status) {
            successNotification(t('SAVE_INFORMATION'));
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        // работает когда открывают новый отель
        if (tab && tab.hotel_id !== hotelId) {
            // обнуляется initial state
            setInitialValues(initialValues);
            // записываем новый id отеля
            setHotelId(tab.hotel_id);
            // запрашиваем номера нового отеля
            dispatch(getHotelRoomNumbers({ hotelId: tab.hotel_id }));
        }

        if (tab && tab.hotel_id === hotelId) {
            setInitialValues({
                ...initialValues,
                ...tab.managment_info,
                message: tab.managment_info.message ? tab.managment_info.message : '',
            });
        }
    }, [tab, hotelId]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        let status;
        const data = {
            hotel_id: tab.hotel_id,
            manage_additional_html: {
                ...values,
            },
        };

        status = await dispatch(updManagementInfo({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={Schema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                {({ values, errors, isValidating, isSubmitting }) => {
                    if (isSubmitting && !isValidating) {
                        for (const err in errors) {
                            errorNotification(errors[err]);
                        }
                    }
                    return (
                        <Form onKeyDown={handleKeyDown}>
                            <div className=" bg-white sm:w-full ">
                                <div className="flex items-center border-b">
                                    <p className="pb-6 pl-6 pt-6 font-medium">{t('NOTIFICATION_HEADER')}</p>
                                    <div className="ml-auto mr-6" data-tip={t('NOTIFICATION_TOOLTIP_1')}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="#1890FF"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path
                                                d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                stroke="#1890FF"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>

                                <div className=" mt-3 bg-white pb-3 sm:w-full">
                                    {/* title */}
                                    <div className="mt-3 pl-6 pr-6">
                                        <p className="text-gray-20 mb-2 flex w-full text-sm">{t('NOTIFICATION_TITLE')}</p>
                                        <Field
                                            name="title_ru"
                                            component="textarea"
                                            value={values.title_ru}
                                            placeholder={t('NOTIFICATION_TITLE')}
                                            className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm lg:h-8"
                                            disabled={isSubmitting}
                                        />
                                    </div>

                                    {/* title EN */}
                                    <div className="mt-3 pl-6 pr-6">
                                        <p className="text-gray-20 mb-2 flex w-full text-sm">{t('NOTIFICATION_TITLE_EN')}</p>
                                        <Field
                                            name="title_en"
                                            component="textarea"
                                            value={values.title_en}
                                            placeholder={t('NOTIFICATION_TITLE_EN')}
                                            className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm lg:h-8"
                                            disabled={isSubmitting}
                                        />
                                    </div>

                                    {/* info */}
                                    <div className="mt-3 pl-6 pr-6">
                                        <p className="text-gray-20 mb-2 flex w-full text-sm">{t('NOTIFICATION_INFO')}</p>
                                        <Field
                                            name="info_ru"
                                            component="textarea"
                                            value={values.info_ru}
                                            placeholder={t('NOTIFICATION_INFO')}
                                            className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm sm:h-24"
                                            disabled={isSubmitting}
                                        />
                                    </div>

                                    {/* info EN */}
                                    <div className="mb-6 mt-3 pl-6 pr-6">
                                        <p className="text-gray-20 mb-2 flex w-full text-sm">{t('NOTIFICATION_INFO_EN')}</p>

                                        <Field
                                            name="info_en"
                                            component="textarea"
                                            value={values.info_en}
                                            placeholder={t('NOTIFICATION_INFO_EN')}
                                            className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm sm:h-24"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                            </div>
                            <MessageForm initialValues={values} />

                            <button
                                type="submit"
                                className={` mt-6 h-10 rounded-sm px-14 text-sm font-medium  ${
                                    !updateInfoFetch ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                } `}
                                disabled={updateInfoFetch}
                            >
                                {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                            </button>

                            <PersonalMessageForm hotelId={hotelId} />
                        </Form>
                    );
                }}
            </Formik>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
