import * as Yup from 'yup';

export const TemplateListSchema = Yup.object().shape({
    name: Yup.string().min(3, 'INFO_ERROR_1').max(45, 'INFO_ERROR_2').required('INFO_ERROR_3'),
    name_en: Yup.string().min(3, 'INFO_ERROR_4').max(45, 'INFO_ERROR_5').required('INFO_ERROR_6'),
    preview_description: Yup.string().nullable().min(2, 'INFO_ERROR_35').max(35, 'INFO_ERROR_35_1'),
    preview_description_en: Yup.string().nullable().min(2, 'INFO_ERROR_36').max(35, 'INFO_ERROR_36_1'),
    preview_description2: Yup.string().nullable().min(2, 'INFO_ERROR_37').max(35, 'INFO_ERROR_38'),
    preview_description2_en: Yup.string().nullable().min(2, 'INFO_ERROR_39').max(35, 'INFO_ERROR_40'),
    detail_description: Yup.string().nullable().min(12, 'INFO_ERROR_18'),
    detail_description_en: Yup.string().nullable().min(12, 'INFO_ERROR_42'),
    sort: Yup.number().typeError('INFO_ERROR_7').positive('INFO_ERROR_8').max(9999, 'INFO_ERROR_83'),
    files: Yup.array().min(1, 'INFO_ERROR_9').required('INFO_ERROR_9'),
    svg_current: Yup.string().required('INFO_ERROR_10'),
});
