import * as Yup from 'yup';

export const meetingSchema = Yup.object().shape({
    fio: Yup.string().min(4, 'Поле "ФИО" слишком короткое').max(100, 'Поле "ФИО" слишком длинное').required('Поле "ФИО" обязательное'),
    phone: Yup.string().min(18, 'Поле "Номер телефона не полное"').required('Поле "Телефон" обязательное'),
    date: Yup.string().nullable().required('Поле "Дата" обязательное'),
    meeting_id: Yup.object().required('Поле "Переговорная" обязательно'),
    start: Yup.string().required('Поле "Время начала бронирования" обязательное'),
    end: Yup.string().required('Поле "Время завершения бронирования" обязательное'),
});
