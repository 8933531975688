import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import { access_token as access_token_state } from '../../../redux/slices/loginSlice';
import {
    getUsers,
    users as users_state,
    users_fetching as users_fetching_state,
    editUser,
    page as page_state,
    set_page,
    total_count as total_count_state,
    userFilter as userFilter_state,
    set_filter,
} from '../../../redux/slices/loyaltySlice';
import { SubHeader } from '../../common/SubHeader';

const GlobalFilter = () => {
    const { t } = useTranslation();

    const userFilter = useSelector(userFilter_state);

    const [searchLine, setSearchLine] = useState(userFilter ? userFilter : '');
    const dispatch = useDispatch();
    const skipFirstrender = useRef(true);

    useEffect(() => {
        if (skipFirstrender.current) {
            skipFirstrender.current = false;
            return;
        }

        const timeOutId = setTimeout(() => {
            dispatch(set_filter(searchLine));
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchLine, dispatch]);

    return (
        <input
            className="border border-gray-100 px-3 py-2"
            defaultValue={userFilter ? userFilter : ''}
            onChange={e => setSearchLine(e.target.value)}
            placeholder={t('TABLE_SEARCH')}
        />
    );
};

export const Table = ({ setUserId }) => {
    const { t, i18n } = useTranslation();

    const [checked, setChecked] = useState([]);

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const users = useSelector(users_state);
    const users_fetching = useSelector(users_fetching_state);
    const page = useSelector(page_state);
    const total_count = useSelector(total_count_state);
    const userFilter = useSelector(userFilter_state);

    useEffect(() => {
        dispatch(getUsers({ access_token, page, userFilter }));
    }, [page, userFilter]);

    const columns = useMemo(
        () => [
            {
                Header: t('INFORMATION'),
                columns: [
                    {
                        Header: t('TABLE_FIO'),
                        accessor: 'name',
                        width: 200,
                    },
                    {
                        Header: t('TABLE_PHONE'),
                        accessor: 'phone',
                        width: 200,
                    },
                    {
                        Header: t('TABLE_EMAIL'),
                        accessor: 'email',
                        width: 400,
                    },
                ],
            },
            {
                Header: t('LOYALTY'),
                columns: [
                    {
                        Header: t('TABLE_NIGHT'),
                        accessor: 'loyalty_days',
                        width: 75,
                    },
                    {
                        Header: t('TABLE_DISCOUNT'),
                        accessor: 'loyalty_discount',
                        width: 75,
                    },
                    {
                        Header: t('TABLE_STATUS'),
                        accessor: i18n.language === 'ru' ? 'loyalty_status_name' : 'loyalty_status_name_en',
                        width: 150,
                    },
                    {
                        Header: t('TABLE_BLACK_LIST'),
                        accessor: 'black_list',
                        width: 50,
                    },
                    {
                        Header: '',
                        accessor: 'id',
                        width: 50,
                    },
                ],
            },
        ],
        [t, i18n]
    );
    const data_list = useMemo(() => users || [], [users]);

    useEffect(() => {
        const black_list = data_list.reduce((acc, cur) => (cur.black_list === '1' ? [...acc, cur.id] : acc), []);
        setChecked(black_list);
    }, [data_list]);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
        columns,
        data: data_list,
    });

    const handleBlackListClick = user => {
        const data = {
            guest_id: user.row.values.id,
            black_list: user.row.values.black_list === '1' ? null : '1',
        };
        dispatch(editUser({ access_token, data }));
    };

    const handleChecked = () => {};
    const count = total_count - users.length;

    return (
        <>
            <SubHeader>
                <div className="text-gray flex w-full flex-row items-center text-sm text-xl font-semibold uppercase text-gray-500">
                    <span className="text-black">{t('TABLE_TABLE_USERS')}</span>
                </div>
                <GlobalFilter />
            </SubHeader>

            {users_fetching && total_count === null ? (
                <div className="absolute top-2/4 mb-4 mt-4 flex w-full justify-center">
                    <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
                </div>
            ) : (
                <div className=" mx-auto w-full max-w-9xl overflow-x-auto p-4 pb-4 sm:p-6">
                    <InfiniteScroll
                        next={() => dispatch(set_page(page + 1))}
                        hasMore={count > 0 && total_count > 100}
                        loader={
                            <div className="flex h-20 w-full items-center justify-center">
                                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
                            </div>
                        }
                        dataLength={rows.length}
                        scrollableTarget="wrapper"
                    >
                        <table {...getTableProps()} className={`w-full min-w-[800px] bg-white `}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps({
                                                    style: { width: column.width },
                                                })}
                                                className="border border-gray-100 px-4 py-3 "
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell.column.id === 'loyalty_days') {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps({
                                                                style: {
                                                                    width: cell.column.width,
                                                                },
                                                            })}
                                                            className="border border-gray-100 px-4 py-3 text-sm"
                                                        >
                                                            <details className="cursor-pointer">
                                                                <summary>{cell.render('Cell')}</summary>
                                                                {row.original.loyalty_days_detail.map((item, index) => (
                                                                    <p key={`days${index}`} className="whitespace-nowrap text-xs text-gray-600">
                                                                        <b>
                                                                            {item.hotel}, {item.room}
                                                                        </b>
                                                                        : {item.date_from} - {item.date_to}
                                                                    </p>
                                                                ))}
                                                            </details>
                                                        </td>
                                                    );
                                                }
                                                if (cell.column.id === 'name') {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps({
                                                                style: {
                                                                    width: cell.column.width,
                                                                },
                                                            })}
                                                            className={`${
                                                                cell.row.values.black_list === '1' ? 'text-red-500' : ''
                                                            } border border-gray-100 px-4 py-3 text-sm`}
                                                        >
                                                            {cell.value}
                                                        </td>
                                                    );
                                                }
                                                if (cell.column.id === 'loyalty_status_name' || cell.column.id === 'loyalty_status_name_en') {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps({
                                                                style: {
                                                                    width: cell.column.width,
                                                                },
                                                            })}
                                                            className="border border-gray-100 px-4 py-3 text-sm"
                                                        >
                                                            <details className="cursor-pointer">
                                                                <summary>{cell.render('Cell')}</summary>
                                                                <p className="text-xs text-gray-600">
                                                                    <b>{t('TABLE_COMMENT')}</b>: {row.original.comment}
                                                                </p>
                                                            </details>
                                                        </td>
                                                    );
                                                }
                                                if (cell.column.id === 'black_list') {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps({
                                                                style: {
                                                                    width: cell.column.width,
                                                                },
                                                            })}
                                                            className="border border-gray-100 px-4 py-3 text-sm"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="blackList"
                                                                id={cell.row.original.id}
                                                                onClick={() => handleBlackListClick(cell)}
                                                                onChange={handleChecked}
                                                                checked={checked.indexOf(cell.row.original.id) >= 0}
                                                            />
                                                        </td>
                                                    );
                                                }

                                                if (cell.column.id === 'id') {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps({
                                                                style: {
                                                                    width: cell.column.width,
                                                                },
                                                            })}
                                                            className="border border-gray-100 px-4 py-3 text-center"
                                                        >
                                                            <button onClick={() => setUserId(cell.value)}>
                                                                <svg viewBox="0 0 401.52289 401" className="h-4 w-4 fill-current">
                                                                    <path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" />
                                                                    <path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    );
                                                }

                                                return (
                                                    <td
                                                        {...cell.getCellProps({
                                                            style: {
                                                                width: cell.column.width,
                                                            },
                                                        })}
                                                        data-label={cell.column.Header}
                                                        className="break-all border border-gray-100 px-4 py-3 text-sm"
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                    {userFilter !== '' && data_list.length === 0 && <div className="ml-5 mt-5">{t('TABLE_NOT_USER')}</div>}
                </div>
            )}
        </>
    );
};
