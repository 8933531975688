import React, { useState } from 'react';
import { Table } from '../components/loyalty/programTable/Table';
import { Edit } from '../components/loyalty/programTable/Edit';

export const LoyaltyPage = () => {
    const [userId, setUserId] = useState(null);

    return (
        <>
            <Table setUserId={setUserId} />
            <Edit userId={userId} setUserId={setUserId} />
        </>
    );
};
