export const TimeColumn = () => (
    <div className="w-14 border-r">
        <div className="sticky top-0 h-6 border-b bg-white"></div>
        <div className="h-20 bg-[#F8F8F8] "></div>
        {new Array(23).fill(0).map((time, idx) => {
            return (
                <div className="h-20 bg-[#F8F8F8] px-3 text-xs" key={`${idx}_time`}>
                    {idx + 1}:00
                </div>
            );
        })}
    </div>
);
