import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getShop, shopFetchingState, shopState } from '../redux/slices/shopSlice';

import { Loader } from '../components/common/Loader';
import { ShopSection } from '../components/shop/shop/ShopSection';
import { ServicesSection } from '../components/shop/services/ServicesSection';
import { EditCategory } from '../components/shop/EditCategory';

export const ShopPage = () => {
    const dispatch = useDispatch();
    const [category, setCategory] = useState(null);
    const [editCategory, setEditCategory] = useState(null);

    const shop = useSelector(shopState);
    const shopFetching = useSelector(shopFetchingState);

    const { hotel_id, category_id } = useParams();

    useEffect(() => {
        dispatch(getShop({ hotel_id }));
    }, [hotel_id]);

    useEffect(() => {
        if (category_id === 'new') {
            setEditCategory({ id: category_id });
        }
    }, [category_id]);

    useEffect(() => {
        if (shop && category_id && category_id !== 'new') {
            setCategory({
                ...shop.subitems.find(tab => tab.id === category_id),
                hotel_name: shop.hotel_name,
                shop_name: shop.lang_key,
            });
        }
    }, [shop, category_id]);

    if (shopFetching) {
        return <Loader />;
    }

    return (
        <>
            {category && category.type === '1' && <ShopSection category={category} setEditCategory={setEditCategory} />}
            {category && category.type === '2' && <ServicesSection category={category} setEditCategory={setEditCategory} />}
            {editCategory && <EditCategory category={editCategory} setEditCategory={setEditCategory} hotelId={hotel_id} />}
        </>
    );
};
