import { useTranslation } from 'react-i18next';

export const SearchInput = ({ text, setText }) => {
    const { t } = useTranslation();
    return (
        <div className="mt-3">
            <p className="text-sm">{t('INFO_ENTER_ROOM')}</p>
            <input value={text} onChange={e => setText(e.target.value)} type="text" placeholder="..." className="mt-2 w-28 rounded" />
        </div>
    );
};
