import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { unread_messages_counter as unread_messages_counter_state } from '../../../../redux/slices/chatSlice';
import { SidebarMenuLinkGroupButton } from './SidebarMenuLinkGroupButton';
import { SidebarMenuItem } from './SidebarMenuItem';

export const SidebarMenuHotelTab = ({ hotel, tab }) => {
    const { pathname } = useLocation();

    const { t, i18n } = useTranslation();

    const unread_messages_counter = useSelector(unread_messages_counter_state);

    const [isOpen, setIsOpen] = useState(pathname.includes(`object/${hotel.id}/${tab.link}`));

    if (tab.subitems) {
        const active = tab.subitems.find(el => +el.active !== 0);
        if (active) {
            return (
                <li className="mb-2 last:mb-0">
                    <SidebarMenuLinkGroupButton
                        icon={tab.link}
                        title={tab.lang_key}
                        url={`object/${hotel.id}/${tab.link}/`}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />

                    <div className="bg-slate-50">
                        <ul className={`mt-1 ${!isOpen && 'hidden'}`}>
                            {tab.subitems
                                .filter(
                                    tab_lv_2 => tab_lv_2.link !== 'information_guest_book' && tab_lv_2.preview_name !== 'Пиццерия' && +tab_lv_2.active2 !== 0
                                )
                                .map(
                                    tab_lv_2 =>
                                        !Array.isArray(tab_lv_2) && (
                                            <SidebarMenuItem
                                                title={
                                                    Object.keys(i18n).length
                                                        ? i18n.resolvedLanguage === 'ru'
                                                            ? tab_lv_2.name
                                                            : tab_lv_2.name_en
                                                        : tab_lv_2.name
                                                }
                                                link={`/object/${tab_lv_2.hotel_id || tab.hotel_id}/${tab.link}/${
                                                    tab_lv_2.link ? tab_lv_2.link : tab_lv_2.id
                                                }/`}
                                                disabled={tab_lv_2.active === '0'}
                                                key={`tab${tab_lv_2.id}`}
                                                style="ml-8"
                                            />
                                        )
                                )}

                            {tab.link === 'information' && (
                                <SidebarMenuItem title={t('NEW_SECTION')} icon="add" link={`/object/${tab.hotel_id}/${tab.link}/template_info`} style="ml-7" />
                            )}

                            {/* шаблон создания переговорки */}
                            {tab.link === 'meeting' && (
                                <>
                                    <SidebarMenuItem
                                        title={t('ADD_NEW_MEETING_ROOM')}
                                        icon="add"
                                        link={`/object/${tab.hotel_id}/${tab.link}/template_meeting_room`}
                                        style="ml-7"
                                    />

                                    <SidebarMenuItem
                                        title={t('BOOKING_CALENDAR')}
                                        icon="calendar"
                                        link={`/object/${tab.hotel_id}/${tab.link}/booking_calendar`}
                                        style="ml-7"
                                    />

                                    <SidebarMenuItem
                                        title={t('BOOKING_HISTORY')}
                                        icon="history"
                                        link={`/object/${tab.hotel_id}/${tab.link}/booking_history`}
                                        style="ml-7"
                                    />
                                </>
                            )}
                        </ul>
                    </div>
                </li>
            );
        } else {
            return null;
        }
    }

    return (
        <>
            {tab.id_tab === '2' && (
                <SidebarMenuItem
                    icon="chat"
                    title={Object.keys(i18n).length ? (i18n.resolvedLanguage === 'ru' ? tab.name : tab.name_en) : tab.name}
                    link={`/object/${tab.hotel_id}/${tab.link}/`}
                    newMessages={
                        unread_messages_counter.find(el => +el.hotel_id === +tab.hotel_id) &&
                        unread_messages_counter.find(el => +el.hotel_id === +tab.hotel_id).count
                    }
                />
            )}
            {tab.id_tab === '11' && (
                <SidebarMenuItem
                    icon="blackList"
                    title={Object.keys(i18n).length ? (i18n.resolvedLanguage === 'ru' ? tab.name : tab.name_en) : tab.name}
                    link={`/object/${tab.hotel_id}/${tab.link}/`}
                    newMessages={
                        unread_messages_counter.find(el => +el.hotel_id === +tab.hotel_id) &&
                        unread_messages_counter.find(el => +el.hotel_id === +tab.hotel_id).black_list
                    }
                />
            )}
        </>
    );
};
