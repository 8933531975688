import * as Yup from 'yup';

export const TemplateAccordionSchema = Yup.object().shape({
    name: Yup.string().min(3, 'INFO_ERROR_1').max(45, 'INFO_ERROR_2').required('INFO_ERROR_3'),
    name_en: Yup.string().min(3, 'INFO_ERROR_4').max(45, 'INFO_ERROR_5').required('INFO_ERROR_6'),
    sort: Yup.number().typeError('INFO_ERROR_7').positive('INFO_ERROR_8').max(9999, 'INFO_ERROR_83'),
    files: Yup.array().min(1, 'INFO_ERROR_9').required('INFO_ERROR_9'),
    svg_current: Yup.string().required('INFO_ERROR_10'),
    json: Yup.array().of(
        Yup.object().shape({
            sectionName: Yup.string().min(2, 'INFO_ERROR_11').max(90, 'INFO_ERROR_12').required('INFO_ERROR_13'),
            sectionName_en: Yup.string().min(2, 'INFO_ERROR_14').max(90, 'INFO_ERROR_15').required('INFO_ERROR_16'),
            isTable: Yup.boolean(),
            isDesc: Yup.boolean(),
            sectionDesc: Yup.array().when('isDesc', {
                is: true,
                then: () => Yup.array(Yup.string().required('INFO_ERROR_17').min(12, 'INFO_ERROR_18')),
                otherwise: () => Yup.array(),
            }),
            sectionTable: Yup.object().shape({
                isTable: Yup.boolean(),
                head: Yup.object().shape({
                    isTable: Yup.boolean(),
                    first: Yup.array().when('isTable', {
                        is: true,
                        then: () => Yup.array(Yup.string().required('INFO_ERROR_19').min(2, 'INFO_ERROR_20')),
                        otherwise: () => Yup.array(),
                    }),
                    second: Yup.array().when('isTable', {
                        is: true,
                        then: () => Yup.array(Yup.string().required('INFO_ERROR_21').min(2, 'INFO_ERROR_22')),
                        otherwise: () => Yup.array(),
                    }),
                }),
                body: Yup.array().when('isTable', {
                    is: true,
                    then: () =>
                        Yup.array().of(
                            Yup.object().shape({
                                cellFirst: Yup.string().min(2, 'INFO_ERROR_23').required('INFO_ERROR_24').max(100, 'INFO_ERROR_25'),
                                cellFirst_en: Yup.string().min(2, 'INFO_ERROR_26').required('INFO_ERROR_27').max(100, 'INFO_ERROR_28'),
                                cellSecond: Yup.string().min(2, 'INFO_ERROR_29').required('INFO_ERROR_30').max(100, 'INFO_ERROR_31'),
                                cellSecond_en: Yup.string().min(2, 'INFO_ERROR_32').required('INFO_ERROR_33').max(100, 'INFO_ERROR_34'),
                            })
                        ),

                    otherwise: () => Yup.array(),
                }),
            }),
        })
    ),
});
