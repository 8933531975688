import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MeetingRoomTemplate } from '../components/meetingRoom/Templates/MeetingRoomTemplate';
import { MeetingRoom } from '../components/meetingRoom/Templates/MeetingRoom';
import { BookingCalendar } from '../components/meetingRoom/bookingCalendar/BookingCalendar';
import { BookingHistory } from '../components/meetingRoom/bookingHistory/BookingHistory';
import { tabs as tabs_state } from '../redux/slices/hotelSlice';
import { set_hotel_id, getMeetingRoomColors, getAllMeetingRoomForFilter } from '../redux/slices/meetingRoomSlice';

export const MeetingRoomPage = () => {
    const { hotel_id } = useParams();
    const { tab_code } = useParams();

    const dispatch = useDispatch();
    const tabs = useSelector(tabs_state);

    const [tab, setTab] = useState(null);

    useEffect(() => {
        dispatch(set_hotel_id(hotel_id));
        dispatch(getAllMeetingRoomForFilter());
        dispatch(getMeetingRoomColors());
    }, []);

    useEffect(() => {
        if (tabs.length && hotel_id) {
            dispatch(set_hotel_id(hotel_id));
            const hotel = tabs.find(hotel => hotel.id === hotel_id);

            if (hotel) {
                setTab({
                    ...hotel.subitems.find(tab => tab.link === 'meeting'),
                    hotel_name: hotel.Name,
                });
            }
        }
    }, [tabs, hotel_id]);

    if (tab) {
        if (tab_code === 'template_meeting_room') {
            const tab_info = {
                hotel_name: tab.hotel_name,
                hotel_id,
                name: 'Добавить новую переговорную',
                tab_code: 'template_meeting_room',
                section_link: tab.link,
            };
            return <MeetingRoomTemplate tab={tab_info} />;
        }
        if (tab_code === 'booking_calendar') {
            return <BookingCalendar />;
        }
        if (tab_code === 'booking_history') {
            const tab_info = {
                hotel_name: tab.hotel_name,
                hotel_id,
                name: 'История бронирования',
                tab_code: 'booking_history',
                section_link: tab.link,
            };
            return <BookingHistory tab={tab_info} />;
        } else {
            const meetingRoom = tab.subitems.find(el => el.link === tab_code);
            const tab_info = {
                id: meetingRoom.id,
                name: meetingRoom.name,
                hotel_name: tab.hotel_name,
                hotel_id,
                section_link: tab.link,
            };
            return <MeetingRoom tab={tab_info} />;
        }
    }
};
