import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { listenForOutsideClick } from '../../../api/functions';
import { updateTariff } from '../../../redux/slices/coworkingSlice';

export const Item = ({ tab, item, setDetailId }) => {
    const dispatch = useDispatch();
    const editBlockRef = useRef(null);

    const [editBlockIsOpen, setEditBlockIsOpen] = useState(false);
    const [listening, setListening] = useState(false);

    useEffect(listenForOutsideClick(listening, setListening, editBlockRef, setEditBlockIsOpen));

    return (
        <div className="h-56 w-1/3 px-3 pb-3">
            <div className="relative flex h-full w-full flex-col overflow-hidden rounded-2 bg-white drop-shadow">
                {!item.active && (
                    <div className="absolute z-20 flex h-full w-full items-center justify-center bg-white bg-opacity-80">
                        <div className="w-2/3 bg-green-500 py-2 text-center text-sm text-white">Скрыт</div>
                    </div>
                )}

                <div className="absolute right-0 top-0 z-30" ref={editBlockRef}>
                    <button
                        className="flex h-11 w-11 items-center justify-center rounded-bl-2 bg-gray-600 text-white transition-colors hover:bg-gray-800"
                        onClick={() => setEditBlockIsOpen(!editBlockIsOpen)}
                    >
                        <svg className="h-6 w-6 fill-current stroke-current" viewBox="0 0 24 24">
                            <path
                                d="M17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12Z"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12Z"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>

                    <div
                        className={`absolute right-0 overflow-hidden rounded-lg bg-white drop-shadow-2xl transition-opacity  ${
                            editBlockIsOpen ? 'showed opacity-100' : 'hidden opacity-0'
                        }`}
                    >
                        <button
                            className="flex w-full cursor-pointer flex-row items-center whitespace-nowrap border-0 p-3 text-left text-sm font-normal text-black hover:bg-gray-50"
                            type="button"
                            onClick={() => {
                                setEditBlockIsOpen(false);
                                dispatch(updateTariff({ data: { hotel_id: tab.hotel_id, id: item.id, active: item.active ? '0' : '1' } }));
                            }}
                        >
                            {item.active ? 'Скрыть с сайта' : 'Отобразить на сайте'}
                        </button>
                    </div>
                </div>

                <div className="z-10 flex h-full w-full flex-col p-4">
                    <div className="mb-1.5 line-clamp-2 w-3/4 text-xl font-bold text-black">{item.name}</div>
                    <div className="mb-1.5 line-clamp-2 text-base font-normal text-black text-opacity-90">{item.description}</div>
                    <div className="mb-4 w-full font-normal text-gray-500">
                        <span className="text-sm font-medium leading-tight text-black text-opacity-90">Цена: </span>
                        <span className="text-sm font-normal leading-tight text-black text-opacity-60">{item.price} ₽</span>
                    </div>

                    <button
                        className="mt-auto h-8 w-full rounded border border-blue-500 bg-white text-sm text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
                        onClick={() => setDetailId(item.id)}
                    >
                        Редактировать
                    </button>
                </div>
            </div>
        </div>
    );
};
