import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { twMerge } from 'tailwind-merge';

const Option = props => {
    return (
        <components.Option {...props}>
            <div className="flex items-center justify-between">
                <div>{props.label}</div>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />
            </div>
        </components.Option>
    );
};

export const MultiSelect = ({
    options = [],
    placeholder = 'Выберите опцию',
    isClearable = true,
    isSearchable = false,
    isLoading = false,
    isDisabled = false,
    value = [],
    callback,
    className,
    getOptionLabel,
}) => {
    const [values, setValues] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setValues(value);
    }, [value]);

    const classes = twMerge(`select text-sm w-48 ${className}`);

    const changeHandler = (value, meta) => {
        setValues(value);

        if (meta.action !== 'select-option' && meta.action !== 'deselect-option') {
            callback(value);
        }
    };

    const closeMenuHandler = () => {
        if (isOpen) {
            setIsOpen(false);
            callback(values);
        }
    };

    const openMenuHandler = () => {
        setIsOpen(true);
    };

    return (
        <Select
            isMulti
            options={options}
            value={values}
            onChange={changeHandler}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onMenuClose={closeMenuHandler}
            onMenuOpen={openMenuHandler}
            noOptionsMessage={() => 'Нет данных'}
            isClearable={isClearable}
            isSearchable={isSearchable}
            placeholder={placeholder}
            classNamePrefix="filter"
            className={classes}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            getOptionLabel={getOptionLabel}
            // menuIsOpen={true}
        />
    );
};
