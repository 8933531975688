import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';

type Props = {
    dirty: boolean;
    loading: boolean;
    name: string;
};
export const SubmitButton: FC<Props> = ({ dirty, loading, name }) => {
    const { t } = useTranslation();
    return (
        <button
            type="submit"
            className={` mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                dirty ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
            } `}
            disabled={!dirty || loading}
        >
            {loading ? <RotatingLines width="20" strokeColor="white" /> : t(name)}
        </button>
    );
};
