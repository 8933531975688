import { Field } from 'formik';
import { CirclePicker } from 'react-color';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { CustomTimePicker } from '../helpers/CustomTimePicker';

export const MainInfo = ({ values, isSubmitting, setFieldValue, selectColor, colors, setSelectColor }) => {
    return (
        <>
            <div className="flex items-center border-b">
                <p className="pb-6 pl-6 pt-6 font-medium">Укажите основную информацию о переговорной</p>
                <div className="ml-auto mr-6" data-tip="Основная информация">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#1890FF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {/* name */}
            <div className="mt-3 pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">Название</p>
                <Field
                    name="name"
                    component="textarea"
                    value={values.name}
                    placeholder="Введите название переговорной"
                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm lg:h-8"
                    disabled={isSubmitting}
                />
            </div>
            {/* name EN */}
            <div className="mt-3 pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">Name</p>
                <Field
                    name="name_en"
                    component="textarea"
                    value={values.name_en}
                    placeholder="Enter the meeting room name"
                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm lg:h-8"
                    disabled={isSubmitting}
                />
            </div>
            {/* number of seats */}
            <div className="mt-3 pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">Количество мест</p>

                <Field name="seats">
                    {({ field }) => (
                        <div>
                            <input
                                type="number"
                                placeholder="Введите количество сидячих мест"
                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                {...field}
                            />
                        </div>
                    )}
                </Field>
            </div>
            {/* working hours */}
            <div className="mt-3 pl-6 pr-6 ">
                <p className="text-gray-20 mb-2 flex w-full text-sm">Время работы</p>

                <div className="flex gap-6">
                    <div>
                        <p className="text-xs">Открытие</p>
                        <CustomTimePicker
                            name="working_hours_from"
                            selected={values.working_hours_from}
                            timeIntervals={60}
                            timeFormat="HH:mm"
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            className="h-8 w-24 rounded text-sm"
                            minTime={setHours(setMinutes(new Date(), 0), 6)}
                            maxTime={setHours(setMinutes(new Date(), 0), 22)}
                        />
                    </div>
                    <div>
                        <p className="text-xs">Закрытие</p>
                        <CustomTimePicker
                            name="working_hours_to"
                            selected={values.working_hours_to}
                            timeIntervals={60}
                            timeFormat="HH:mm"
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            className="h-8 w-24 rounded text-sm"
                            minTime={
                                values.working_hours_from && setHours(setMinutes(new Date(values.working_hours_from), 30), values.working_hours_from.getHours())
                            }
                            maxTime={setHours(setMinutes(new Date(), 30), 22)}
                            disabled={values.working_hours_from.length === 0}
                        />
                    </div>
                </div>
            </div>
            {/* price */}
            <div className="mt-3 pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">Стоимость за час</p>

                <Field name="price_hour">
                    {({ field }) => (
                        <div>
                            <input
                                type="number"
                                placeholder="Введите стоимость"
                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                {...field}
                            />
                        </div>
                    )}
                </Field>
            </div>
            {/* color */}
            <div className="mt-3 pl-6 pr-6">
                <p className="mb-2 flex w-full text-sm">Выберите цвет переговорной для календаря</p>
                <CirclePicker
                    color={selectColor}
                    colors={colors}
                    circleSize={18}
                    width="100%"
                    onChangeComplete={color => {
                        setSelectColor(color.hex);
                        setFieldValue('color', color.hex);
                    }}
                />
            </div>
            {/* active */}
            <div className="mt-4 flex items-center pl-6 pr-6">
                <p className="text-gray-20 mr-4 text-sm">Укажите активность</p>
                <Field type="checkbox" name="active" value="active" />
            </div>
        </>
    );
};
