import { useFormikContext, useField } from 'formik';
import DatePicker from 'react-datepicker';

export const CustomTimePicker = props => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <DatePicker
            showTimeSelect
            showTimeSelectOnly
            timeFormat="HH:mm"
            timeCaption="Время"
            dateFormat="HH:mm"
            autoComplete="off"
            onKeyDown={e => {
                e.preventDefault();
            }}
            {...field}
            {...props}
            onChange={val => {
                if (field.name === 'start') {
                    setFieldValue(field.name, val);
                    setFieldValue('end', '');
                } else {
                    setFieldValue(field.name, val);
                }
            }}
        />
    );
};
