import { createSlice } from '@reduxjs/toolkit';
import { blackListApi } from '../../api/modules/blackListApi';
import { set_token_error } from './loginSlice';
import { RootState } from '../store';
import { BlackList, BlackListInfo } from '../../components/blackList/types';

type InitialState = {
    blackList: BlackList[];
    blackListFetch: boolean;
    blackListUserInfoFetch: boolean;
    blackListUserInfo: BlackListInfo | null;
};
export const blackListSlice = createSlice({
    name: 'blackList',
    initialState: (): InitialState => ({
        blackList: [],
        blackListFetch: false,
        blackListUserInfoFetch: false,
        blackListUserInfo: null,
    }),

    reducers: {
        setBlackListFetching: (state, { payload }) => {
            state.blackListFetch = payload;
        },
        setBlackList: (state, { payload }) => {
            state.blackList = payload;
        },
        getBlackListUserInfoFetching: (state, { payload }) => {
            state.blackListUserInfoFetch = payload;
        },
        setBlackListUserInfo: (state, { payload }) => {
            state.blackListUserInfo = payload;
        },
        updateBlackListUserInfo: (state, { payload }) => {
            state.blackList = state.blackList.map(el => (el.id === payload ? { ...el, isRead: '1' } : el));
        },
    },
});

export const { setBlackListFetching, setBlackList, getBlackListUserInfoFetching, setBlackListUserInfo, updateBlackListUserInfo } = blackListSlice.actions;

export const getBlackList = (hotelId: string) => async (dispatch, getState) => {
    dispatch(setBlackListFetching(true));
    try {
        const response = await blackListApi.getBlackList.fetch(getState().login.access_token, hotelId);
        if (response.status === 200) {
            const result = await response.json();
            if (result.status) {
                const blackList: BlackList[] = result.result.map(el => ({ id: el.id, title: el.title, isRead: el.is_read, roomNumber: el.room_number }));
                dispatch(setBlackList(blackList));
            }
        } else if (response.status === 401) {
            dispatch(set_token_error(true));
        }
    } catch (e) {
        console.log('getBlackList error: ', e);
    } finally {
        dispatch(setBlackListFetching(false));
    }
};

export const getBlackListUserInfo = (id: string) => async (dispatch, getState) => {
    dispatch(getBlackListUserInfoFetching(true));

    try {
        const response = await blackListApi.getBlackListUserInfo.fetch(getState().login.access_token, id);
        if (response.status === 200) {
            const result = await response.json();
            if (result.status) {
                const userInfo: BlackListInfo = {
                    city: result.result.city,
                    createdAt: result.result.created_at,
                    description: result.result.description,
                    humanKind: result.result.human_kind,
                    id: result.result.id,
                    name: result.result.name,
                    passport: result.result.passport,
                    phone: result.result.phone,
                    photos: result.result.photos.data.map(el => ({ id: el.id, url: el.file.url })) || [],
                };
                dispatch(setBlackListUserInfo(userInfo));
            } else {
                dispatch(setBlackListUserInfo(null));
            }
        } else if (response.status === 401) {
            dispatch(set_token_error(true));
        }
    } catch (e) {
        console.log('getBlackListUserInfo error: ', e);
    } finally {
        dispatch(getBlackListUserInfoFetching(false));
    }
};

export const readBlackListUserInfo = (id: string) => async (dispatch, getState) => {
    try {
        const response = await blackListApi.readBlackListUserInfo.fetch(getState().login.access_token, id);
        if (response.status === 200) {
            const result = await response.json();
            if (result.status) {
                dispatch(updateBlackListUserInfo(id));
            }
        } else if (response.status === 401) {
            dispatch(set_token_error(true));
        }
    } catch (e) {
        console.log('readBlackListUserInfo error: ', e);
    }
};

export const blackListState = (state: RootState) => state.blackList.blackList;
export const blackListFetchState = (state: RootState) => state.blackList.blackListFetch;
export const blackListUserInfoFetchState = (state: RootState) => state.blackList.blackListUserInfoFetch;
export const blackListUserInfoState = (state: RootState) => state.blackList.blackListUserInfo;

export default blackListSlice.reducer;
