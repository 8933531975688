import { InitialValues } from './types';

export const isDisabledSendButton = (data: InitialValues) => {
    if ((data.depositSum.length || data.allDeposit) && data.withholdReason.length <= 5) {
        return true;
    }
    if (!data.depositSum.length && !data.allDeposit && !data.refundDeposit && !data.refundPayment && !data.transferDepositRoom) {
        return true;
    } else {
        return false;
    }
};
