import { useFormikContext } from 'formik';
import { FC } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    className?: string;
    selected?: string | null;
};
export const CustomTimeSelect: FC<Props> = ({ name, className, selected }) => {
    const { t } = useTranslation();

    const { setFieldValue } = useFormikContext();

    let date: Date | null = null;

    if (name && selected) {
        const timeString = selected;
        const [hours, minutes] = timeString.split(':');
        date = new Date();

        if (hours && minutes) {
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
        }
    } else {
        date = new Date();
        date.setMinutes(30);
    }

    return (
        <DatePicker
            selected={date ?? Date.now()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeFormat="HH:mm"
            timeCaption={t('BOOKING_TIME')}
            dateFormat="HH:mm"
            autoComplete="off"
            onChange={val => setFieldValue(name, moment(val).format('HH:mm'))}
            onKeyDown={e => {
                e.preventDefault();
            }}
            className={className}
        />
    );
};
