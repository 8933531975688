import { Form, Formik, FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReviewTitle } from './ReviewTitle';
import { ReviewShorts } from './ReviewShorts';
import { ReviewTime } from './ReviewTime';
import { SubmitButton } from './SubmitButton';
import { ReviewHeader } from './ReviewHeader';

import { errorNotification, successNotification } from '../../api/functions';
import { ReviewSchema } from './utils/ReviewSchema';
import { IData, InitValues } from './types/reviewTypes';

import { reviewsState, updateReviewFetchingState, updateHotelReview } from '../../redux/slices/hotelSlice.js';
import { AppDispatch } from '../../redux/store';

type Props = {
    type: string;
};

const initialValues = {
    type: '',
    time: '',
    mainText: '',
    secondButtons: [],
    mainTextEn: '',
    secondButtonsEn: [],
};

export const Review: FC<Props> = ({ type }) => {
    const { t } = useTranslation();

    const { hotel_id: hotelId } = useParams();
    const formikRef = useRef<FormikProps<any>>(null);

    const dispatch = useDispatch<AppDispatch>();
    const review: IData[] = useSelector(reviewsState);
    const updateReviewFetching: { isSubmitting: boolean; type: string } = useSelector(updateReviewFetchingState);

    const [initValues, setInitValues] = useState<InitValues>(initialValues);

    useEffect(() => {
        let typeData: IData | undefined = undefined;
        if (review) {
            typeData = review.find(el => el.type === type);
        }
        const typeFlatData = typeData
            ? {
                  type: typeData.type,
                  time: typeData.time,
                  mainText: typeData.ru.mainText,
                  secondButtons: typeData.ru.secondButtons,
                  mainTextEn: typeData.en.mainText,
                  secondButtonsEn: typeData.en.secondButtons,
              }
            : { ...initialValues, type };
        setInitValues(typeFlatData);
    }, [review]);

    const formSubmit = async (values: InitValues, { setSubmitting }: { setSubmitting: Function }) => {
        const status = await dispatch(updateHotelReview({ data: { ...values, hotelId } }));
        if (status.isSuccess) {
            successNotification(`${t('REVIEW')} ${type} ${t('REVIEW_SUCCESS')}`);
            setInitValues(values);
            setSubmitting(false);
        }
    };
    return (
        <>
            <Formik initialValues={initValues} validationSchema={ReviewSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                {({ values, errors, isSubmitting, isValidating }) => {
                    if (isSubmitting && !isValidating) {
                        Object.keys(errors).forEach(errKey => {
                            const err = (errors as any)[errKey];
                            if (typeof err === 'string') {
                                errorNotification(t(err));
                            }
                            if (Array.isArray(err)) {
                                err.map(el => errorNotification(t(el.text)));
                            }
                        });
                    }
                    return (
                        <Form>
                            <div className="flex items-center border-b bg-white">
                                <ReviewHeader type={type} />
                            </div>
                            <div className="p-6">
                                <ReviewTitle values={values} isSubmitting={isSubmitting} />
                                <div className="mt-6 border-b pb-3">
                                    <ReviewShorts
                                        values={values}
                                        isSubmitting={isSubmitting}
                                        typeOfButtons="secondButtons"
                                        title={t('REVIEW_TITLE_SHORT')}
                                        placeholder="вариант"
                                    />
                                    <div className="mt-3">
                                        <ReviewShorts
                                            values={values}
                                            isSubmitting={isSubmitting}
                                            typeOfButtons="secondButtonsEn"
                                            title={t('REVIEW_TITLE_SHORT_EN')}
                                            placeholder="option"
                                        />
                                    </div>
                                </div>
                                <ReviewTime values={values} />
                            </div>
                            <SubmitButton isSubmitting={isSubmitting} loading={updateReviewFetching} type={type} />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
