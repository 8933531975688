import { FC } from 'react';
import { SubmitButton } from '../SubmitButton';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import { CustomReactQuill } from '../../../../../common/CustomReactQuill.js';
import { InitValuesList, Room } from '../types/templateTypes';

type Props = {
    dirty: boolean;
    loading: boolean;
    values: InitValuesList;
    isSubmitting: boolean;
    selectedRoom: Room | null;
};
export const AccordionInfo: FC<Props> = ({ dirty, loading, values, isSubmitting, selectedRoom }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="mb-3 mt-6 text-center">
                {t('INFO_APARTMENT')}: {selectedRoom?.label}
            </div>
            <div className=" mt-3 bg-white sm:w-full">
                {values.json && (
                    <FieldArray name="json">
                        {({ remove, unshift }) => (
                            <div>
                                <div className="flex items-center justify-evenly border-b p-6">
                                    <button
                                        type="button"
                                        className="flex w-1/3 flex-row items-center rounded-full bg-blue-500 px-6 py-2 text-white transition-colors hover:bg-blue-800"
                                        onClick={() =>
                                            unshift({
                                                sectionName: '',
                                                sectionName_en: '',
                                                sectionDesc: ['', ''],
                                                id: Date.now(),
                                                isDesc: true,
                                            })
                                        }
                                    >
                                        <svg className="mr-2 h-10 w-10 fill-current stroke-current" viewBox="0 0 20 20">
                                            <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {t('INFO_ADD_SECTION_TEXT')}
                                    </button>
                                    <button
                                        type="button"
                                        className="flex w-1/3 flex-row items-center rounded-full bg-blue-500 px-6 py-2 text-white transition-colors hover:bg-blue-800"
                                        onClick={() =>
                                            unshift({
                                                sectionName: '',
                                                sectionName_en: '',
                                                sectionTable: {
                                                    isTable: true,
                                                    head: { isTable: true, first: ['', ''], second: ['', ''] },
                                                    body: [
                                                        {
                                                            cellFirst: '',
                                                            cellFirst_en: '',
                                                            cellSecond: '',
                                                            cellSecond_en: '',
                                                            id: Date.now(),
                                                        },
                                                    ],
                                                    payment: [],
                                                },
                                                id: Date.now(),
                                                isDesc: false,
                                            })
                                        }
                                    >
                                        <svg className="mr-2 h-10 w-10 fill-current stroke-current" viewBox="0 0 20 20">
                                            <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {t('INFO_ADD_SECTION_TABLE')}
                                    </button>
                                </div>
                                {values.json?.map((section, id) => (
                                    <div className="border-b pb-8 pt-8" key={section.id}>
                                        {/* section naming */}
                                        <p className="text-gray-20 pl-6 pr-6 text-center">
                                            {t('INFO_SECTION_NAME_1')}: {i18n.language === 'ru' ? section?.sectionName : section?.sectionName_en}
                                        </p>
                                        <div className="mt-3 flex">
                                            <div className="w-1/2 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME_1')}</p>
                                                <Field
                                                    name={`json.${id}.sectionName`}
                                                    component="textarea"
                                                    placeholder={t('INFO_SECTION_NAME_1')}
                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                        values?.json[id]?.sectionName === '' ? 'border-red-500' : ''
                                                    }`}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="w-1/2 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME_EN_1')}</p>
                                                <Field
                                                    name={`json.${id}.sectionName_en`}
                                                    component="textarea"
                                                    placeholder={t('INFO_SECTION_NAME_EN_1')}
                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                        values?.json[id]?.sectionName_en === '' ? 'border-red-500' : ''
                                                    }`}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            {/* text description */}
                                            {values.json[id]?.sectionDesc && (
                                                <div className="flex">
                                                    <div className="mt-3 w-1/2 pb-6 pl-6 pr-6">
                                                        <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION')}</p>
                                                        <Field
                                                            name={`json.${id}.sectionDesc[0]`}
                                                            className={`border-gray-10 box-border h-24 w-1/2 rounded p-1 pl-2 text-sm ${
                                                                values?.json[id]?.sectionDesc[0] === '' ? 'border-red-500' : ''
                                                            }`}
                                                            disabled={isSubmitting}
                                                        >
                                                            {({ field, imageHandler }) => (
                                                                <CustomReactQuill
                                                                    imageHandler={imageHandler}
                                                                    value={field.value}
                                                                    onChange={field.onChange(field.name)}
                                                                    placeholder={`${t('DESCRIPTION')}...`}
                                                                    className={`border-gray-10 box-border h-24 w-full rounded p-1 pl-2 text-sm ${
                                                                        values?.json[id]?.sectionDesc[0] === '' ? 'border-red-500' : ''
                                                                    }`}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="mt-3 w-1/2 pb-6 pl-6 pr-6">
                                                        <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION_EN')}</p>
                                                        <Field
                                                            name={`json.${id}.sectionDesc[1]`}
                                                            className="border-gray-10 box-border h-24 w-1/2 rounded p-1 pl-2 text-sm"
                                                            disabled={isSubmitting}
                                                        >
                                                            {({ field, imageHandler }) => (
                                                                <CustomReactQuill
                                                                    imageHandler={imageHandler}
                                                                    value={field.value}
                                                                    onChange={field.onChange(field.name)}
                                                                    placeholder={t('DESCRIPTION_EN')}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            )}
                                            {/* table description */}
                                            {values.json[id].sectionTable && (
                                                <>
                                                    {/* table header */}
                                                    <div className="mt-6 flex">
                                                        <div className="w-1/2 pl-6 pr-6">
                                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_NAME_FIRST_COLUMN')}</p>
                                                            <Field
                                                                name={`json.${id}.sectionTable.head.first[0]`}
                                                                component="textarea"
                                                                placeholder={t('INFO_NAME_COLUMN')}
                                                                className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                    values?.json[id]?.sectionTable?.head?.first[0] === '' ? 'border-red-500' : ''
                                                                }`}
                                                                disabled={isSubmitting}
                                                            />
                                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_NAME_SECOND_COLUMN')}</p>
                                                            <Field
                                                                name={`json.${id}.sectionTable.head.second[0]`}
                                                                component="textarea"
                                                                placeholder={t('INFO_NAME_COLUMN')}
                                                                className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                    values?.json[id]?.sectionTable?.head?.second[0] === '' ? 'border-red-500' : ''
                                                                }`}
                                                                disabled={isSubmitting}
                                                            />
                                                        </div>
                                                        <div className="w-1/2 pl-6 pr-6">
                                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_NAME_FIRST_COLUMN_EN')}</p>
                                                            <Field
                                                                name={`json.${id}.sectionTable.head.first[1]`}
                                                                component="textarea"
                                                                placeholder={t('INFO_NAME_COLUMN')}
                                                                className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                    values?.json[id]?.sectionTable?.head?.first[1] === '' ? 'border-red-500' : ''
                                                                }`}
                                                                disabled={isSubmitting}
                                                            />
                                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_NAME_SECOND_COLUMN_EN')}</p>
                                                            <Field
                                                                name={`json.${id}.sectionTable.head.second[1]`}
                                                                component="textarea"
                                                                placeholder={t('INFO_NAME_COLUMN')}
                                                                className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                    values?.json[id]?.sectionTable?.head?.second[1] === '' ? 'border-red-500' : ''
                                                                }`}
                                                                disabled={isSubmitting}
                                                            />
                                                        </div>
                                                    </div>
                                                    <FieldArray name={`json[${id}].sectionTable.body`}>
                                                        {({ remove, unshift }) => (
                                                            <>
                                                                <button
                                                                    className="w-19 mb-3 ml-6 mt-3 rounded border border-blue-500 bg-white pl-2 pr-2 text-xs text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        unshift({
                                                                            cellFirst: '',
                                                                            cellFirst_en: '',
                                                                            cellSecond: '',
                                                                            cellSecond_en: '',
                                                                            id: Date.now(),
                                                                        })
                                                                    }
                                                                >
                                                                    {t('INFO_ADD_ROW')}
                                                                </button>
                                                                {/* table rows */}
                                                                {values.json[id].sectionTable.body.length > 0 &&
                                                                    values.json[id].sectionTable.body.map((cell, idx) => (
                                                                        <div className="mt-3 flex border-b pb-3" key={cell.id}>
                                                                            <div className="w-1/2 pl-6 pr-6">
                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_CELL_OF_FIRST_COLUMN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.body.${idx}.cellFirst`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_CELL_CONTENT')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.body[idx].cellFirst === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />

                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_CELL_OF_SECOND_COLUMN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.body.${idx}.cellSecond`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_CELL_CONTENT')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.body[idx].cellSecond === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => remove(idx)}
                                                                                    className="mt-3 w-40 rounded border border-red-500 bg-white pl-1 pr-1 text-xs text-red-500 transition-colors hover:bg-red-500 hover:text-white"
                                                                                >
                                                                                    {t('INFO_REMOVE_ROW')}
                                                                                </button>
                                                                            </div>
                                                                            <div className="w-1/2 pl-6 pr-6">
                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_CELL_OF_FIRST_COLUMN_EN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.body.${idx}.cellFirst_en]`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_CELL_CONTENT')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.body[idx].cellFirst_en === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <p className="text-gray-20 mb-2 flex w-full text-sm">
                                                                                    {t('INFO_CELL_OF_SECOND_COLUMN_EN')}
                                                                                </p>
                                                                                <Field
                                                                                    name={`json.${id}.sectionTable.body.${idx}.cellSecond_en`}
                                                                                    component="textarea"
                                                                                    placeholder={t('INFO_CELL_CONTENT')}
                                                                                    className={`border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm ${
                                                                                        values?.json[id]?.sectionTable?.body[idx].cellSecond_en === ''
                                                                                            ? 'border-red-500'
                                                                                            : ''
                                                                                    }`}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </>
                                            )}
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => remove(id)}
                                            className="ml-6 mt-3 w-40 rounded border border-red-700 bg-red-700 pl-1 pr-1 text-xs text-white transition-colors hover:bg-red-500 hover:text-white"
                                        >
                                            {t('INFO_REMOVE_SECTION')}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </FieldArray>
                )}
            </div>
            <SubmitButton dirty={dirty} loading={loading} name="SAVE" />
        </>
    );
};
