export const CreateBookingButton = ({ setPaneIsOpen }) => {
    return (
        <>
            <button
                onClick={() => setPaneIsOpen(true)}
                type="button"
                className="flex items-center rounded-3xl bg-blue-400 px-6 py-2 text-sm text-white transition-colors hover:bg-blue-500"
            >
                <svg viewBox="0 0 14 14" className="mr-2 h-2.5 w-2.5 fill-current">
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" />
                </svg>
                <span>Добавить событие</span>
            </button>
        </>
    );
};
