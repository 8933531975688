import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

export const Calendar = ({ selectedDate, setSelectedDate }) => {
    return (
        <div>
            <DatePicker
                locale={ru}
                onChange={date => setSelectedDate(date)}
                dateFormat="dd MMMM yyyy"
                className="h-8 w-40 cursor-pointer rounded border-blue-500 text-center text-sm text-blue-500"
                selected={selectedDate}
            />
        </div>
    );
};
