import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import moment from 'moment';

import { setChatDateFilter } from '../../../redux/slices/chatSlice';

export const ChatFilterByDate: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    useEffect(() => {
        if (startDate && endDate) {
            dispatch(setChatDateFilter([moment(startDate).format('YYYY-MM-DD '), moment(endDate).format('YYYY-MM-DD ')]));
        }
    }, [dateRange]);
    return (
        <>
            <div>
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={update => {
                        if (!update[0] && !update[1]) {
                            dispatch(setChatDateFilter([null, null]));
                        }
                        setDateRange(update);
                    }}
                    isClearable={true}
                    placeholderText={t('CHAT_SEARCH_DATE')}
                    className="border border-gray-100 text-sm"
                    locale={ru}
                    dateFormat="dd.MM.yy"
                />
            </div>
        </>
    );
};
