import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';

export const coworkingSlice = createSlice({
    name: 'coworking',

    initialState: {
        tariffs: [],
        tariffs_fetching: false,
    },

    reducers: {
        set_tariffs: (state, action) => {
            state.tariffs = action.payload;
        },
        set_tariffs_fetching: (state, action) => {
            state.tariffs_fetching = action.payload;
        },
        add_tariff: (state, action) => {
            state.tariffs.push(action.payload);
        },
        update_tariff: (state, action) => {
            console.log('action', action);

            state.tariffs = state.tariffs.map(el => {
                if (el.id === action.payload.id) {
                    return { ...el, ...action.payload };
                }
                return el;
            });
        },
    },
});

export const { set_tariffs, add_tariff, update_tariff, set_tariffs_fetching } = coworkingSlice.actions;

export const getTariffs =
    ({ hotel_id }) =>
    async dispatch => {
        dispatch(set_tariffs_fetching(true));

        try {
            if (hotel_id) {
                const response = await api.getTariffsList.fetch({ hotel_id });

                if (response.status === 200) {
                    const res = await response.json();

                    if (res) {
                        dispatch(
                            set_tariffs(
                                res.map(item => ({
                                    ...item,
                                    active: item.active !== '0',
                                    additional: JSON.parse(item.additional).map(item => ({ ...item, id: item.id || parseInt(Date.now() * Math.random()) })),
                                }))
                            )
                        );
                    }
                }
            }
        } catch (e) {
            console.log('getTariffs error: ', e);
        } finally {
            dispatch(set_tariffs_fetching(false));
        }
    };

export const updateTariff =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        try {
            if (access_token && data) {
                const response = await api.updateTariff.fetch(access_token, data);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res && res.result && !res.result.error) {
                        const resData = data;
                        if (data.additional) {
                            resData.additional = JSON.parse(data.additional);
                        }
                        if (data.active) {
                            resData.active = data.active !== '0';
                        }

                        dispatch(update_tariff(resData));

                        return { error: 0, message: 'Тариф успешно обновлен' };
                    } else {
                        return { error: 1, message: res.result.error || 'Что-то пошло не так. Пожалуйста обновите страницу и попробуйте еще раз.' };
                    }
                }
            }
        } catch (e) {
            console.log('updateTariff error: ', e);
        }

        return { error: 1, message: 'Что-то пошло не так. Пожалуйста обновите страницу и попробуйте еще раз.' };
    };

export const addTariff =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        try {
            if (access_token && data) {
                const response = await api.addTariff.fetch(access_token, data);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res && res.result && res.result.id) {
                        dispatch(add_tariff({ ...data, additional: JSON.parse(data.additional), id: res.result.id, active: true }));

                        return { error: 0, message: 'Тариф успешно создан' };
                    } else {
                        return { error: 1, message: 'Что-то пошло не так. Пожалуйста обновите страницу и попробуйте еще раз.' };
                    }
                }
            }
        } catch (e) {
            console.log('updateTariff error: ', e);
        }

        return { error: 1, message: 'Что-то пошло не так. Пожалуйста обновите страницу и попробуйте еще раз.' };
    };

export const tariffsState = state => state.coworking.tariffs;
export const tariffsFetchingState = state => state.coworking.tariffs_fetching;

export default coworkingSlice.reducer;
