import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';

import { requestForToken } from '../../../firebase';
import {
    checkAuth,
    refreshToken,
    access_token as access_token_state,
    check_auth_fetching as check_auth_fetching_state,
    login as login_state,
    refresh_token as refresh_token_state,
    token_error as token_error_state,
} from '../../../redux/slices/loginSlice';
import { getUrlInfoPicFetch } from '../../../redux/slices/informationSlice';
import { loadLanguages } from '../../../api/functions';

export const InitialLayout = ({ children }) => {
    const location = useLocation();

    const dispatch = useDispatch();
    const check_auth_fetching = useSelector(check_auth_fetching_state);
    const access_token = useSelector(access_token_state);
    const refresh_token = useSelector(refresh_token_state);
    const login = useSelector(login_state);
    const token_error = useSelector(token_error_state);

    const [cookies, setCookie] = useCookies();

    const [languageLoading, setLanguageLoading] = useState(true);
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    requestForToken();

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = '';
    }, [location.pathname]);

    // Check auth
    useEffect(() => {
        if (cookies.language) {
            (async () => {
                await loadLanguages(cookies.language);
                setLanguageLoading(false);
            })();
        } else {
            const lang = window.navigator.language.split('-')[0];
            setLanguageLoading(true);
            setCookie('language', lang, { path: '/', expires, sameSite: 'none', secure: true });

            (async () => {
                await loadLanguages(lang);
                setLanguageLoading(false);
            })();
        }

        dispatch(
            checkAuth({
                access_token: cookies.access_token,
                refresh_token: cookies.refresh_token,
                login: cookies.login,
            })
        );
        dispatch(getUrlInfoPicFetch());
    }, []);

    // Refresh token
    useEffect(() => {
        if (token_error) {
            dispatch(
                refreshToken({
                    access_token: cookies.access_token,
                    refresh_token: cookies.refresh_token,
                    login: cookies.login,
                })
            );
        }
    }, [token_error]);

    // Set cookie
    useEffect(() => {
        if (access_token) {
            setCookie('access_token', access_token, { path: '/', expires, sameSite: 'none', secure: true });
        }
    }, [access_token]);

    useEffect(() => {
        if (refresh_token) {
            setCookie('refresh_token', refresh_token, { path: '/', expires, sameSite: 'none', secure: true });
        }
    }, [refresh_token]);

    useEffect(() => {
        if (login) {
            setCookie('login', login, { path: '/', expires, sameSite: 'none', secure: true });
        }
    }, [login]);

    if (check_auth_fetching || languageLoading) {
        return (
            <div className="fixed z-60 flex h-full w-full items-center justify-center bg-gray-100">
                <BallTriangle height="100" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    return <>{children}</>;
};
