import { errorNotification } from '../../../../api/functions';

export const getValidateError = errors => {
    for (const err in errors) {
        if (err === 'included_options') {
            errors[err].map(error => {
                error && Object.values(error).map(el => errorNotification(el.toString()));
            });
        } else if (err === 'addition_options') {
            errors[err].map(error => {
                error && Object.values(error).map(el => errorNotification(el.toString()));
            });
        } else {
            errorNotification(errors[err].toString());
        }
    }
};
