import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { errorNotification } from '../../../api/functions';
import { addShopItem, editShopItem } from '../../../redux/slices/shopSlice';
import { SortablePicsLoader } from '../../common/SortablePicsLoader';

const initState = {
    name: '',
    name_en: '',
    price: '',
    size: '',
    size_en: '',
    color: '',
    color_en: '',
    id: '',
    files: [],
};

const SignupSchema = Yup.object().shape({
    name: Yup.string().min(2, 'INFO_ERROR_1').max(100, 'SHOP_ERROR').required('INFO_ERROR_3'),
    name_en: Yup.string().min(2, 'INFO_ERROR_4').max(100, 'SHOP_ERROR_1').required('INFO_ERROR_6'),
    price: Yup.string().min(2, 'INFO_ERROR_47').max(100, 'INFO_ERROR_48').required('INFO_ERROR_49'),
    files: Yup.array().min(1, 'ERROR_MIN_ONE_PHOTO'),
});

export const Detail = ({ item, setItem, hotelId }) => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const [initialState, setInitialState] = useState(initState);

    const { t } = useTranslation();

    useEffect(() => {
        if (item && item.id !== 'new') {
            (async () => {
                setInitialState({
                    name: item.name || '',
                    name_en: item.name_en || '',
                    price: item.price || '',
                    size: item.size || '',
                    size_en: item.size_en || '',
                    color: item.color || '',
                    color_en: item.color_en || '',
                    id: item.id,
                    files: item.files2 ? item.files2 : [],
                });
            })();
        }
    }, [item]);

    const closeHandler = resetForm => {
        setItem(null);
        resetForm();
        setInitialState(initState);
    };

    const onSubmitHandler = async (values, resetForm) => {
        let res;

        if (item.id !== 'new') {
            res = await dispatch(editShopItem({ data: { ...values, hotel_id: hotelId, shop_id: item.shop_id, type: item.type } }));
        } else {
            res = await dispatch(addShopItem({ data: { ...values, hotel_id: hotelId, shop_id: item.shop_id, type: item.type } }));
        }

        if (res.status) {
            closeHandler(resetForm);
        } else {
            if (res.error) {
                errorNotification(res.error.toString());
            }
        }
    };

    const sortDispatch = data => {
        setInitialState({ ...formikRef.current.values, files: data });
    };

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            validationSchema={SignupSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ values, errors, isValidating, isSubmitting, setFieldValue, resetForm }) => {
                if (isSubmitting && !isValidating) {
                    for (const err in errors) {
                        errorNotification(t(errors[err]));
                    }
                }

                return (
                    <Form className="fixed inset-0 z-40 flex items-center justify-center bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200">
                        <div className="flex max-h-[97vh] w-[770px] max-w-full flex-col overflow-auto bg-white p-6">
                            <div className="mb-6 flex w-full flex-row border border-gray-300">
                                <div className="w-1/3 border-r border-r-gray-300 bg-gray-100 p-4">
                                    <SortablePicsLoader setFieldValue={setFieldValue} files={values.files} maxFiles={10} sortDispatch={sortDispatch} />
                                </div>
                                <div className="flex w-2/3 flex-row flex-wrap content-start justify-between p-4">
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="name" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('NAME')}
                                        </label>
                                        <Field id="name" name="name" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="name_en" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('NAME_EN')}
                                        </label>
                                        <Field id="name_en" name="name_en" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="price" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_PRICE')}
                                        </label>
                                        <Field
                                            id="price"
                                            name="price"
                                            type="text"
                                            className="border border-gray-300 p-2"
                                            onChange={e => {
                                                e.preventDefault();
                                                const { value } = e.target;

                                                let formattedValue = value.trim().replace(/[^0-9,]/g, '');

                                                if (formattedValue[0] === ',') {
                                                    formattedValue = `0${formattedValue}`;
                                                }

                                                if (formattedValue[0] === '0' && formattedValue[1] === '0') {
                                                    formattedValue = formattedValue[0] + formattedValue.slice(2, formattedValue.length);
                                                }

                                                if (formattedValue.indexOf(',') >= 0 && formattedValue.indexOf(',', formattedValue.indexOf(',') + 1) >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',', formattedValue.indexOf(',') + 1));
                                                }

                                                if (formattedValue.indexOf(',') >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',') + 3);
                                                }

                                                setFieldValue('price', formattedValue);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-5 flex w-[calc(50%-10px)] flex-col">
                                        <label htmlFor="size" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('INFO_SIZE')}
                                        </label>
                                        <Field id="size" name="size" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-[calc(50%-10px)] flex-col">
                                        <label htmlFor="size_en" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_SIZE_EN')}
                                        </label>
                                        <Field id="size_en" name="size_en" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-[calc(50%-10px)] flex-col">
                                        <label htmlFor="color" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_COLOR')}
                                        </label>
                                        <Field id="color" name="color" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-[calc(50%-10px)] flex-col">
                                        <label htmlFor="color_en" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_COLOR_EN')}
                                        </label>
                                        <Field id="color_en" name="color_en" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" mr-4 h-10 bg-transparent px-6 text-gray-700 transition-colors hover:text-black"
                                    onClick={() => closeHandler(resetForm)}
                                >
                                    {t('CANCEL')}
                                </button>
                                <button
                                    type="submit"
                                    className={`flex h-10 w-[135px] items-center justify-center px-6 text-white ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
