import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';

import { LanguageSwitcher } from '../components/common/LanguageSwitcher';
import AuthImage from '../source/images/auth-image.svg';
import { auth_error as auth_error_state, authFetching, login as login_state, set_auth_error, authFetchingState } from '../redux/slices/loginSlice';

export const AuthPage = () => {
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const dispatch = useDispatch();
    const auth_error = useSelector(auth_error_state);
    const email = useSelector(login_state);
    const checkAuthFetching = useSelector(authFetchingState);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const lang = window.navigator.language;

    const formSubmit = () => {
        dispatch(authFetching({ login, password }));
    };

    useEffect(() => {
        if (auth_error) {
            toast.error(auth_error.toString(), {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            dispatch(set_auth_error(null));
        }
    }, [auth_error]);

    useEffect(() => {
        const handleKeyDown = e => {
            if (e.key === 'Enter') {
                formSubmit();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [login, password]);

    if (email) {
        return <Navigate to={location.state?.from.pathname || '/'} />;
    }

    return (
        <div className="relative flex h-full w-full flex-row items-center md:items-start">
            {/* Image */}
            <div className="hidden h-full w-1/2 md:flex md:items-center" aria-hidden="true">
                <img className="w-full" src={AuthImage} alt="Authentication" />
            </div>

            {/* Content */}
            <div className="w-full md:w-1/2">
                {!lang.includes('en') && !!Object.keys(i18n).length ? (
                    <div className="absolute right-5 top-1 flex justify-end md:right-10">
                        <LanguageSwitcher />
                    </div>
                ) : (
                    ''
                )}
                <div className="mx-auto flex max-w-sm flex-col justify-center px-4 pb-8 md:min-h-screen">
                    <div className="flex w-full flex-col items-center">
                        <svg className="mb-10 h-14 w-14 fill-current" viewBox="0 0 60 61" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_200_905" maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="61">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M60 19.0055C60 18.278 60 17.5504 59.9955 16.8304C59.9918 16.2154 59.985 15.6079 59.9685 14.9929C59.9513 13.6559 59.834 12.3222 59.6175 11.0028C59.3914 9.68271 58.97 8.40366 58.3672 7.20768C57.1413 4.80897 55.1892 2.85868 52.7893 1.63504C51.5954 1.02926 50.317 0.606996 48.9972 0.38251C47.6771 0.167759 46.343 0.049941 45.0056 0.0300007C44.3951 0.0150004 43.7841 0.00750019 43.1726 0.00750019C42.4473 0 41.7213 0 40.9975 0H19.0025C18.2772 0 17.5512 0 16.8274 0.00750019C16.2159 0.00750019 15.6046 0.0150004 14.9936 0.0300007C13.6565 0.0499749 12.3227 0.167793 11.0028 0.38251C9.68275 0.607112 8.40411 1.02936 7.20993 1.63504C4.81049 2.85901 2.8587 4.80923 1.63279 7.20768C1.02968 8.40352 0.608284 9.68262 0.38251 11.0028C0.165614 12.3221 0.0482798 13.6559 0.0315008 14.9929C0.0150004 15.6079 0.00825021 16.2154 0.00450011 16.8304C0 17.5504 0 18.278 0 19.0055V40.996C0 41.7235 0 42.4511 0.00450011 43.1711C0.00450011 43.7861 0.0150004 44.3936 0.0315008 45.0086C0.0482798 46.3456 0.165614 47.6794 0.38251 48.9987C0.608284 50.3189 1.02968 51.598 1.63279 52.7938C2.8587 55.1923 4.81049 57.1425 7.20993 58.3665C8.40411 58.9721 9.68275 59.3944 11.0028 59.619C12.3222 59.8374 13.6563 59.9553 14.9936 59.9715C15.6046 59.9865 16.2159 59.994 16.8274 59.994C17.5527 60.0015 18.2787 60.0015 19.0025 60.0015H40.996C41.7213 60.0015 42.4473 60.0015 43.1711 59.994C43.7826 59.994 44.3936 59.9865 45.0041 59.9715C46.3417 59.9553 47.676 59.8374 48.9957 59.619C50.3155 59.3945 51.5939 58.9722 52.7878 58.3665C55.1877 57.1428 57.1398 55.1925 58.3657 52.7938C58.9685 51.5978 59.3899 50.3188 59.616 48.9987C59.8325 47.6793 59.9498 46.3456 59.967 45.0086C59.9835 44.3936 59.9903 43.7861 59.994 43.1711C59.9985 42.4511 59.9985 41.7235 59.9985 40.996V19.0055H60Z"
                                    fill="white"
                                />
                            </mask>
                            <g mask="url(#mask0_200_905)">
                                <path
                                    d="M14 1H46.0015V-1H14V1ZM59.0015 14V46.0015H61.0015V14H59.0015ZM46.0015 59.0015H14V61.0015H46.0015V59.0015ZM1 46.0015V14H-1V46.0015H1ZM14 59.0015C6.8203 59.0015 1 53.1812 1 46.0015H-1C-1 54.2858 5.71574 61.0015 14 61.0015V59.0015ZM59.0015 46.0015C59.0015 53.1812 53.1812 59.0015 46.0015 59.0015V61.0015C54.2858 61.0015 61.0015 54.2858 61.0015 46.0015H59.0015ZM46.0015 1C53.1812 1 59.0015 6.8203 59.0015 14H61.0015C61.0015 5.71573 54.2858 -1 46.0015 -1V1ZM14 -1C5.71573 -1 -1 5.71574 -1 14H1C1 6.8203 6.8203 1 14 1V-1Z"
                                    fill="#E0E0E0"
                                />
                            </g>
                            <path
                                d="M18.9873 41.5935C19.5513 42.0512 20.229 42.2763 20.9017 42.2763C21.7817 42.2763 22.6567 41.8944 23.2561 41.1585L30.8099 31.8851C31.7203 30.7698 31.7203 29.169 30.8099 28.0513L23.3142 18.8487C22.2546 17.5488 20.3453 17.3541 19.0455 18.4112C17.7456 19.4708 17.5509 21.3826 18.608 22.6825L24.5433 29.9682L18.5498 37.3248C17.4927 38.622 17.6875 40.5339 18.9873 41.5935Z"
                                fill="#1890FF"
                            />
                            <path
                                d="M33.9583 18.8462C32.9012 17.5464 30.9894 17.3517 29.6895 18.4087C28.3897 19.4683 28.1949 21.3777 29.2545 22.6775L35.1873 29.9632L29.1938 37.3198C28.1342 38.6196 28.3315 40.5315 29.6288 41.5885C30.1928 42.0463 30.8705 42.2713 31.5432 42.2713C32.4232 42.2713 33.2982 41.8895 33.8976 41.1536L41.4514 31.8801C42.3618 30.7649 42.3618 29.1641 41.4514 28.0464L33.9583 18.8462Z"
                                fill="#1890FF"
                            />
                        </svg>

                        <h1 className="text-4xl font-semibold">{t('DIGITAL_HOTEL')}</h1>
                        <p className="mb-12 text-lg">{t('ADMIN_PANE')}</p>

                        {/* Form */}
                        <form className="w-full">
                            <div className="space-y-4">
                                <input
                                    className="h-14 w-full rounded-lg bg-blue-100 text-blue-500"
                                    type="text"
                                    value={login}
                                    onChange={e => setLogin(e.target.value)}
                                />
                                <input
                                    className="h-14 w-full rounded-lg bg-blue-100 text-blue-500"
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mt-6 flex items-center justify-between">
                                {/*<div className='mr-1'>*/}
                                {/*	<Link className='text-sm underline hover:no-underline' to='#'>Забыли пароль?</Link>*/}
                                {/*</div>*/}

                                <button
                                    type="button"
                                    name="submit"
                                    className="flex h-14 w-full items-center justify-center rounded-lg bg-blue-500 px-12 text-white transition-colors hover:bg-blue-800"
                                    onClick={formSubmit}
                                >
                                    {checkAuthFetching ? <RotatingLines width="20" strokeColor="white" /> : t('ENTER')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
