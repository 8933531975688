import { useTranslation } from 'react-i18next';
import { SettingsInput } from './SettingsInput';

export const LoyaltySettings = ({ initValues, setInitValues }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="px-6 pt-6">
                <div className="flex w-full items-center border-b bg-white ">
                    <p className="pb-6 pl-6 pt-6 font-medium">{t('LOYALTY_CONDITIONS')}</p>
                    <div className="ml-auto mr-6" data-tip={t('LOYALTY')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                stroke="#1890FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>

                <div className="mt-3 bg-white p-6">
                    <p className="mb-3 text-sm">{t('LOYALTY_CONDITIONS_2')}</p>
                    <div className="md:flex">
                        <div>
                            <SettingsInput title="LOYALTY_USER" initValues={initValues} setInitValues={setInitValues} status="LOYALTY_STATUS_1" />
                            <SettingsInput title="LOYALTY_SILVER" initValues={initValues} setInitValues={setInitValues} status="LOYALTY_STATUS_2" />
                            <SettingsInput title="LOYALTY_GOLD" initValues={initValues} setInitValues={setInitValues} status="LOYALTY_STATUS_3" />
                            <SettingsInput title="LOYALTY_PLATINUM" initValues={initValues} setInitValues={setInitValues} status="LOYALTY_STATUS_4" />
                        </div>
                        <div className="mt-5 md:ml-10 md:mt-0">
                            <SettingsInput title="LOYALTY_PERCENT_DISCOUNT" initValues={initValues} setInitValues={setInitValues} status="percentDiscount1" />
                            <SettingsInput title="LOYALTY_PERCENT_DISCOUNT" initValues={initValues} setInitValues={setInitValues} status="percentDiscount2" />
                            <SettingsInput title="LOYALTY_PERCENT_DISCOUNT" initValues={initValues} setInitValues={setInitValues} status="percentDiscount3" />
                            <SettingsInput title="LOYALTY_PERCENT_DISCOUNT" initValues={initValues} setInitValues={setInitValues} status="percentDiscount4" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
