import { FC } from 'react';

type Props = {
    link?: string;
};
export const SendToWhatsAppButton: FC<Props> = ({ link }) => {
    return (
        <a
            onClick={() => {
                ym(98170648, 'reachGoal', 'yaMetricsSendWATemplate');
            }}
            href={link}
            target="_blanc"
            className="flex w-full items-center justify-center rounded-[100px] bg-blue-500 py-2 text-center font-medium md:w-72 md:px-6"
        >
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                    d="M3 16.6667V3.33337L18.8333 10L3 16.6667ZM4.66667 14.1667L14.5417 10L4.66667 5.83337V8.75004L9.66667 10L4.66667 11.25V14.1667Z"
                    fill="white"
                />
            </svg>

            <span className="ml-2 text-base text-white">Отправить в WhatsApp</span>
        </a>
    );
};
