import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

export const Popup = ({ children, isOpen, setIsOpen, className }) => {
    const classes = twMerge(
        `relative bg-white z-50 w-[500px] max-w-full box-border rounded-lg flex flex-col p-5 shadow-2xl transition-all duration-200 ease-in-out border border-gray-10 ${className}`
    );

    const closeHandler = () => {
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return createPortal(
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200">
            <div className="absolute z-40 h-full w-full" onClick={closeHandler}></div>
            <div className={classes}>
                <button
                    onClick={closeHandler}
                    className="absolute right-1 top-1 flex h-7 w-7 items-center justify-center rounded-full bg-transparent p-0 hover:bg-gray-100"
                >
                    <svg className="h-5 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"></path>
                    </svg>
                </button>
                {children}
            </div>
        </div>,
        document.querySelector('#modal-root')
    );
};
