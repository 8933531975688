import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { BallTriangle } from 'react-loader-spinner';

import { laundryListFetchState } from '../../../../redux/slices/informationSlice';

export const Table = ({ historyList }) => {
    const laundryListFetch = useSelector(laundryListFetchState);
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                Header: t('INFO_MACHINE'),
                accessor: 'name',
            },
            {
                Header: t('INFO_EVENT'),
                accessor: 'action',
            },
            {
                Header: t('INFO_GUEST_ROOM'),
                accessor: 'roomNumber',
            },
            {
                Header: t('INFO_BOOKING_CODE'),
                accessor: 'bookingId',
            },
            {
                Header: t('INFO_DATE'),
                accessor: 'date',
            },
            { Header: t('INFORMATION'), accessor: 'info' },
        ],
        [t]
    );

    const data = useMemo(() => historyList || [], [historyList]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    if (laundryListFetch) {
        return (
            <div className="mt-8 flex w-full justify-center pb-24">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    if (historyList && !historyList.length) {
        return <div className="pb-20">{t('INFO_NO_DATA')}</div>;
    } else {
        return (
            <div className={`mx-auto mt-3 w-full max-w-9xl overflow-x-auto pb-4 ${!historyList ? 'pb-28' : ''}`}>
                <table {...getTableProps()} className="w-full min-w-[800px] bg-white">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="border border-gray-100 px-4 py-3 text-left">
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id === 'info') {
                                            const value = cell.value ? (typeof cell.value === 'string' ? cell.value : cell.value.command) : 'no';
                                            return (
                                                <td {...cell.getCellProps()} className="border border-gray-100 px-4 py-3 text-sm ">
                                                    {value}
                                                </td>
                                            );
                                        }
                                        return (
                                            <td {...cell.getCellProps()} className="border border-gray-100 px-4 py-3 text-sm ">
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
};
