import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';

import { errorNotification, successNotification } from '../../api/functions';
import { RotatingLines } from 'react-loader-spinner';
import { ContentWrapper } from '../common/ContentWrapper';

import { access_token as access_token_state, login as login_state } from '../../redux/slices/loginSlice';
import {
    sendPushNotification,
    push_send_fetching as push_send_fetching_state,
    push_status as push_status_state,
    set_push_status,
} from '../../redux/slices/chatSlice';
import { useTranslation } from 'react-i18next';

const Schema = Yup.object().shape({
    title: Yup.string().min(2, 'PUSH_ERROR_1').max(100, 'PUSH_ERROR_2').required('PUSH_ERROR_3'),
    body: Yup.string().min(2, 'PUSH_ERROR_4').max(300, 'PUSH_ERROR_5').required('PUSH_ERROR_6'),
});

const initialValues = { title: '', body: '' };
export const PushForm = ({ tab }) => {
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const push_send_fetching = useSelector(push_send_fetching_state);
    const push_status = useSelector(push_status_state);
    const login = useSelector(login_state);

    const { t } = useTranslation();

    useEffect(() => {
        if (push_status === 200) {
            successNotification(t('PUSH_SEND'));
            dispatch(set_push_status(false));
        }
        if (push_status === 403) {
            errorNotification(t('PUSH_ERROR_7'));
            dispatch(set_push_status(false));
        }
    }, [push_status]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        let status;
        const data = {
            hotel_id: tab.hotel_id,
            title: values.title,
            body: values.body,
            login,
        };

        status = await dispatch(sendPushNotification({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    return (
        <>
            <ContentWrapper>
                <Formik initialValues={initialValues} validationSchema={Schema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(t(errors[err]));
                            }
                        }
                        return (
                            <Form>
                                <div className=" bg-white sm:w-2/3 ">
                                    <div className="flex items-center border-b">
                                        <p className="pb-6 pl-6 pt-6 font-medium">{t('PUSH_CREATE')}</p>
                                        <div className="ml-auto mr-6" data-tip={t('PUSH_TOOLTIP')}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                    stroke="#1890FF"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path
                                                    d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                    stroke="#1890FF"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className=" mt-3 bg-white pb-3 sm:w-full">
                                        {/* title */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('PUSH_TITLE')}</p>
                                            <Field
                                                name="title"
                                                component="textarea"
                                                value={values.title}
                                                placeholder={t('PUSH_TITLE')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm lg:h-8"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/*  body */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('PUSH_TEXT')}</p>
                                            <Field
                                                name="body"
                                                component="textarea"
                                                value={values.body}
                                                placeholder={t('PUSH_TEXT')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm sm:h-24"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                                        dirty ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                    } `}
                                    disabled={!dirty || push_send_fetching}
                                >
                                    {push_send_fetching ? <RotatingLines width="20" strokeColor="white" /> : t('SEND')}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
