import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Header } from '../Header';
import { ContentWrapper } from '../../common/ContentWrapper';
import { TemplateSelection } from './templatePart/templateInfo/TemplateSelection.tsx';
import { TemplateCombined } from './templatePart/templateInfo/templateCombined/TemplateCombined.tsx';
import { tab_info as tab_info_state } from '../../../redux/slices/informationSlice';

export const TemplateInfo2 = () => {
    const { t } = useTranslation();

    const tab_info = useSelector(tab_info_state);

    const [templateVariant, setTemplateVariant] = useState(null);

    return (
        <>
            {!templateVariant && (
                <>
                    <Header tab={{ ...tab_info, name: t('INFO_TEMPLATE_SECTION'), name_en: t('INFO_TEMPLATE_SECTION') }} />
                    <ContentWrapper>
                        <TemplateSelection setValue={setTemplateVariant} />
                    </ContentWrapper>
                </>
            )}
            {templateVariant && <TemplateCombined templateVariant={templateVariant} />}
        </>
    );
};
