import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SidebarMenuLinkGroupButton } from './SidebarMenuLinkGroupButton';
import { SidebarMenuHotelTab } from './SidebarMenuHotelTab';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuGroup } from './SidebarMenuGroup';
import { SidebarMenuItemLinkBlank } from './SidebarMenuItemLinkBlank';

export const SidebarMenuHotel = ({ hotel }) => {
    const { pathname } = useLocation();

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(pathname.includes(`object/${hotel.id}`));

    return (
        <li className="mb-2 last:mb-0">
            <SidebarMenuLinkGroupButton title={hotel.Name} isOpen={isOpen} setIsOpen={setIsOpen} type="bold" />

            <div className="bg-slate-50">
                <ul className={`mt-1 ${!isOpen && 'hidden'}`}>
                    {/* Уведомления */}
                    {hotel.manage_additional_html && (
                        <SidebarMenuItem title={t('NOTIFICATION')} icon="notification" link={`/object/${hotel.id}/manage_additional/`} />
                    )}

                    {/* Пуш */}
                    <SidebarMenuItem title={t('PUSH')} icon="push" link={`/object/${hotel.id}/push_notification/`} />

                    {/* Отзывы */}
                    {hotel.reviewActive ? <SidebarMenuItem title={t('REVIEW')} icon="review" link={`/object/${hotel.id}/review/`} /> : ''}

                    {/* Табы */}
                    {hotel.subitems &&
                        hotel.subitems
                            .filter(tab => tab.link !== 'room-management')
                            .map(tab => <SidebarMenuHotelTab hotel={hotel} tab={tab} key={`${tab.code}${tab.sort}`} />)}

                    {/* Программа лояльности */}
                    {hotel.loyalty_on === '1' && (
                        <SidebarMenuGroup title={t('LOYALTY')} icon="loyalty" url={`/object/${hotel.id}/loyalty`}>
                            <SidebarMenuItem title={t('ARRIVING_GUESTS')} style="ml-8" link={`/object/${hotel.id}/loyalty/arriving/`} />
                            <SidebarMenuItem title={t('EDITING_FORM')} style="ml-8" link={`/object/${hotel.id}/loyalty/form/`} />
                        </SidebarMenuGroup>
                    )}

                    {/* Услуги Коворкинга*/}
                    {+hotel.id === 702 && (
                        <SidebarMenuGroup title={t('SERVICES')} icon="shop_2" url={`/object/${hotel.id}/services`}>
                            <SidebarMenuItem title={t('TARIFF')} style="ml-8" link={`/object/${hotel.id}/services/tariffs/`} />
                        </SidebarMenuGroup>
                    )}

                    {/* Bi аналитика */}
                    {hotel.power_bi && <SidebarMenuItemLinkBlank title={t('ANALYTICS')} icon="powerBi" link={hotel.power_bi} />}
                </ul>
            </div>
        </li>
    );
};
