import { Field } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InitValuesList } from '../types/templateTypes';

type Props = {
    values: InitValuesList;
    isSubmitting: boolean;
};
export const Location: FC<Props> = ({ values, isSubmitting }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="mt-3 pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_LOCATION')}</p>
                <Field
                    name="preview_description2"
                    component="textarea"
                    value={values.preview_description2}
                    placeholder={t('INFO_LOCATION')}
                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                />
            </div>

            {/* location en */}
            <div className="mt-3 pl-6 pr-6 ">
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_LOCATION_EN')}</p>
                <Field
                    name="preview_description2_en"
                    component="textarea"
                    value={values.preview_description2_en}
                    placeholder={t('INFO_LOCATION_EN')}
                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                />
            </div>
        </>
    );
};
