import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTimeSelect } from './utils/CustomTimeSelect';
import { InitValues } from './types/reviewTypes';

type Props = {
    values: InitValues;
};

export const ReviewTime: FC<Props> = ({ values }) => {
    const { t } = useTranslation();
    return (
        <div className="mt-6">
            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('REVIEW_TIME')}</p>
            <CustomTimeSelect name="time" selected={values.time} className="w-16 text-sm" />
        </div>
    );
};
