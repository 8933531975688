import { FilterByDate } from './filters/FilterByDate';
import { FilterByTypeMethod } from './filters/FilterByTypeMethod';

export const PaymentsFilters = () => {
    return (
        <div className="bg-white py-3 text-center">
            <span>Фильтр</span>
            <div className="mt-2 flex flex-col items-center justify-center gap-2 md:flex-row md:gap-4">
                <FilterByDate />
                <FilterByTypeMethod />
            </div>
        </div>
    );
};
