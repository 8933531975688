import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/ru';

import { api } from '../../api';
import { set_token_error } from './loginSlice';
import { upd_loyalty } from './hotelSlice';
import { add_section, set_sort_info_sections, update_hotel_tab, update_manage_info } from './hotelSlice';
import { getFileFromUrl, errorNotification } from '../../api/functions';
import { root, url } from '../../api/config';

export const informationSlice = createSlice({
    name: 'information',

    initialState: {
        info_fetch: false,
        update_info_fetch: false,
        response_status: false,
        url_info_pic: '',
        tab_info: {},
        information_tabs: [],
        hotel_info_pics: [],
        hotelInfoPicsFetching: false,
        icons: [],
        machines: [],
        machines_fetching: false,
        laundryHistoryList: null,
        laundryListFetch: false,
        hotelRoomNumbers: [],
        hotelRoomNumbersFetch: false,
    },

    reducers: {
        set_info_fetch_start: state => {
            state.info_fetch = true;
        },
        set_info_fetch_end: state => {
            state.info_fetch = false;
        },
        set_update_info_fetch: (state, action) => {
            state.update_info_fetch = action.payload;
        },
        set_response_status: (state, action) => {
            state.response_status = action.payload;
        },
        set_url_info_pic: (state, action) => {
            state.url_info_pic = action.payload;
        },
        set_info_tab: (state, action) => {
            state.information_tabs = action.payload;
        },
        upd_info_tab: (state, action) => {
            state.information_tabs = state.information_tabs.map(el => {
                if (el.id === action.payload.tab_item_id) {
                    return { ...el, ...action.payload };
                }
                return el;
            });
        },
        upd_info_sub_tab: (state, action) => {
            state.information_tabs = state.information_tabs.map(el_tab => {
                if (el_tab.id === action.payload.sub_tab_id) {
                    const tab_subitems = el_tab.subitems.map(el => {
                        if (el.id === action.payload.tab_item_id) {
                            return { ...el, ...action.payload };
                        }
                        return el;
                    });
                    const subitems = { subitems: tab_subitems };
                    return { ...el_tab, ...subitems };
                }
                return el_tab;
            });
        },

        set_sort_photo: (state, action) => {
            state.information_tabs = state.information_tabs.map(el_tab => {
                if (el_tab.id === action.payload.sub_tab_id) {
                    const tab_subitems = el_tab.subitems.map(el => {
                        if (el.id === action.payload.tab_item_id) {
                            return { ...el, files2: action.payload.files2 };
                        }
                        return el;
                    });
                    const subitems = { subitems: tab_subitems };
                    return { ...el_tab, ...subitems };
                }
                return el_tab;
            });
        },
        set_hotel_info_pics: (state, action) => {
            state.hotel_info_pics = action.payload;
        },
        delete_hotel_info_pics: (state, action) => {
            state.hotel_info_pics = state.hotel_info_pics.filter(el => el.url !== action.payload);
        },
        set_tab_info: (state, action) => {
            state.tab_info = action.payload;
        },
        add_new_section: (state, action) => {
            state.information_tabs.push(action.payload);
        },
        set_icons: (state, action) => {
            state.icons = action.payload;
        },
        set_machines: (state, action) => {
            state.machines = action.payload;
        },
        set_machines_fetching: (state, action) => {
            state.machines_fetching = action.payload;
        },
        set_start_machine: (state, action) => {
            state.machines = state.machines.map(el => (el.id === action.payload.id ? { ...el, on_off_status: '1', stage_status: '1' } : el));
        },
        set_end_machine: (state, action) => {
            state.machines = state.machines.map(el => (el.id === action.payload.id ? { ...el, on_off_status: '0', stage_status: '0' } : el));
        },
        change_machine_info: (state, action) => {
            state.machines = state.machines.map(el => (el.id === action.payload.id ? { ...el, ...action.payload } : el));
        },
        create_room: (state, action) => {
            state.information_tabs = state.information_tabs.map(el =>
                el.id === action.payload.parent_id ? { ...el, subitems: el.subitems ? [...el.subitems, action.payload] : [action.payload] } : el
            );
        },
        setLaundryHistory: (state, action) => {
            state.laundryHistoryList = action.payload;
        },
        setLaundryListFetch: (state, action) => {
            state.laundryListFetch = action.payload;
        },
        setHotelRoomNumbers: (state, action) => {
            state.hotelRoomNumbers = action.payload;
        },
        updateRoomMessage: (state, action) => {
            state.hotelRoomNumbers = state.hotelRoomNumbers.map(el => (el.id === action.payload.id ? { ...action.payload } : el));
        },
        setHotelRoomNumbersFetch: (state, action) => {
            state.hotelRoomNumbersFetch = action.payload;
        },
        createCombinedSection: (state, action) => {
            state.information_tabs = state.information_tabs.map(tab =>
                tab.id === action.payload.tab_item_id
                    ? {
                          ...tab,
                          subitems: tab.subitems ? [...tab.subitems, action.payload] : [action.payload],
                      }
                    : tab
            );
        },
        updateCombinedSection: (state, action) => {
            state.information_tabs = state.information_tabs.map(tab =>
                tab.id === action.payload.tab_item_id
                    ? {
                          ...tab,
                          subitems: tab.subitems.map(subitem =>
                              subitem.roomTemplateId === action.payload.roomTemplateId || subitem.id === action.payload.roomTemplateId
                                  ? { ...subitem, ...action.payload }
                                  : subitem
                          ),
                      }
                    : tab
            );
        },
        setHotelInfoPicsFetch: (state, action) => {
            state.hotelInfoPicsFetching = action.payload;
        },
    },
});

export const {
    setHotelInfoPicsFetch,
    updateCombinedSection,
    createCombinedSection,
    setHotelRoomNumbersFetch,
    updateRoomMessage,
    setHotelRoomNumbers,
    setLaundryListFetch,
    setLaundryHistory,
    create_room,
    change_machine_info,
    set_end_machine,
    set_start_machine,
    set_machines_fetching,
    set_machines,
    set_icons,
    add_new_section,
    set_tab_info,
    set_sort_photo,
    set_update_info_fetch,
    upd_info_sub_tab,
    upd_info_tab,
    set_info_tab,
    set_url_info_pic,
    set_response_status,
    set_info_fetch_start,
    set_info_fetch_end,
    set_hotel_info_pics,
    delete_hotel_info_pics,
} = informationSlice.actions;

export const getHotelInformationFetch =
    ({ access_token, hotel_id }) =>
    async dispatch => {
        await dispatch(set_info_fetch_start());
        try {
            if (access_token) {
                const response = await api.getHotelInformation.fetch(access_token, hotel_id);
                if (response.status === 200) {
                    const res = await response.json();
                    const data = res[0].subitems.find(el => el.link === 'information');
                    await dispatch(set_info_tab(data.subitems));
                    await dispatch(
                        set_tab_info({
                            hotel_name: res[0].Name,
                            name: 'информация',
                            hotel_id: res[0].id,
                            link: data.link,
                        })
                    );
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('getHotelInformationFetch error: ', e);
        } finally {
            await dispatch(set_info_fetch_end());
        }
        return 0;
    };

export const editInformation =
    ({ access_token, data }) =>
    async dispatch => {
        await dispatch(set_update_info_fetch(true));
        try {
            if (access_token) {
                const files =
                    data.hasOwnProperty('files') && data.files
                        ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                        : [];
                const allSize = files.reduce((acc, cur) => acc + cur.size, 0);
                if (allSize > 20971520) {
                    return errorNotification('Общий объем всех фото не должен превышать 20МБ');
                }
                const response = await api.editInformationItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (res.message === 'ok') {
                        const files = res.files.map((file, id) => ({ id: id + 1, url: file }));

                        await dispatch(upd_info_tab({ ...data, files2: files }));
                        await dispatch(update_hotel_tab({ ...data, files2: files }));
                        await dispatch(set_sort_info_sections({ hotel_id: data.hotel_id }));
                        await dispatch(set_response_status(true));
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('editInformation error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const editCombinedInformation =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        await dispatch(set_update_info_fetch(true));

        try {
            const files =
                data.hasOwnProperty('files') && data.files
                    ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                    : [];
            const allSize = files.reduce((acc, cur) => acc + cur.size, 0);
            if (allSize > 20971520) {
                return errorNotification('Общий объем всех фото не должен превышать 20МБ');
            }
            const response = await api.editInformationItem.fetch(access_token, { ...data, files });
            if (response.status === 200) {
                const res = await response.json();
                if (res.message === 'ok') {
                    const files = res.files.map((file, id) => ({ id: id + 1, url: file }));
                    if (res.hasOwnProperty('ID')) {
                        dispatch(upd_info_tab({ ...data, files2: files }));
                        dispatch(update_hotel_tab({ ...data, files2: files }));
                        dispatch(set_sort_info_sections({ hotel_id: data.hotel_id }));
                        dispatch(
                            createCombinedSection({
                                roomTemplateId: res.ID,
                                detail_description: data.detail_description,
                                detail_description_en: data.detail_description_en,
                                preview_description: data.preview_description,
                                preview_description_en: data.preview_description_en,
                                preview_description2: data.preview_description2,
                                preview_description2_en: data.preview_description2_en,
                                json: data.json,
                                roomId: data.roomId,
                                tab_item_id: data.tab_item_id,
                            })
                        );
                        return true;
                    } else {
                        dispatch(upd_info_tab({ ...data, files2: files }));
                        dispatch(update_hotel_tab({ ...data, files2: files }));
                        dispatch(set_sort_info_sections({ hotel_id: data.hotel_id }));

                        dispatch(
                            updateCombinedSection({
                                detail_description: data.detail_description,
                                detail_description_en: data.detail_description_en,
                                preview_description: data.preview_description,
                                preview_description_en: data.preview_description_en,
                                preview_description2: data.preview_description2,
                                preview_description2_en: data.preview_description2_en,
                                json: data.json,
                                tab_item_id: data.tab_item_id,
                                roomTemplateId: data.roomTemplateId,
                            })
                        );
                        return true;
                    }
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
            }
        } catch (e) {
            console.log('editCombinedInformation error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
    };

export const editSubInformation =
    ({ access_token, data }) =>
    async dispatch => {
        await dispatch(set_update_info_fetch(true));
        try {
            if (access_token) {
                let files = [];
                if (data.hasOwnProperty('files')) {
                    files = data.files.length ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`))) : [];
                }
                const allSize = files.reduce((acc, cur) => acc + cur.size, 0);
                if (allSize > 20971520) {
                    return errorNotification('Общий объем всех фото не должен превышать 20МБ');
                }
                const response = await api.editInformationItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (res.message === 'ok') {
                        const files = res.files.map((file, id) => ({ id: id + 1, url: file }));
                        await dispatch(set_response_status(true));
                        await dispatch(upd_info_sub_tab({ ...data, files2: files, files: res.files }));
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('editSubInformation error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const getUrlInfoPicFetch = () => async dispatch => {
    try {
        const response = await fetch(`${root}/getserverurl`);
        if (response.status === 200) {
            const res = await response.text();
            await dispatch(set_url_info_pic(res));
        } else if (response.status === 401) {
            await dispatch(set_token_error(true));
        }
    } catch (e) {
        console.log('getUrlInfoPic error:', e);
    }
};

export const updateLoyaltyList =
    ({ access_token, data }) =>
    async dispatch => {
        try {
            if (access_token) {
                const response = await api.updateLoyaltyListFetch.fetch(access_token, data);

                if (response.status === 200) {
                    await dispatch(set_response_status(true));
                    await dispatch(upd_loyalty(data));
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('updateLoyaltyList error: ', e);
        }
        return 0;
    };

export const updManagementInfo =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        await dispatch(set_update_info_fetch(true));
        try {
            if (access_token) {
                const response = await api.updateManageInfoFetch.fetch(access_token, data);

                if (response.status === 200) {
                    await dispatch(set_response_status(true));
                    await dispatch(update_manage_info(data));
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('updateLoyaltyList error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const getHotelInfoPics =
    ({ access_token, hotel_id }) =>
    async dispatch => {
        dispatch(setHotelInfoPicsFetch(true));
        try {
            const response = await api.getHotelInfoPics.fetch(access_token, hotel_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res && res.hasOwnProperty('result')) {
                    dispatch(set_hotel_info_pics(res.files2));
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
            } else {
                dispatch(set_hotel_info_pics([]));
            }
        } catch (e) {
            console.log('getHotelInfoPics error: ', e);
        } finally {
            dispatch(setHotelInfoPicsFetch(false));
        }
    };

export const addHotelInfoPics =
    ({ access_token, hotel_id, files }) =>
    async dispatch => {
        try {
            const filesArr = files.length ? await Promise.all(files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`))) : [];
            const allSize = files.reduce((acc, cur) => acc + cur.size, 0);
            if (allSize > 20971520) {
                return errorNotification('Общий объем всех фото не должен превышать 20МБ');
            }
            const response = await api.addHotelInfoPics.fetch(access_token, hotel_id, filesArr);

            if (response.status === 200) {
                const res = await response.json();

                if (res.result) {
                    const files = res.result.file_path.map((file, id) => ({ id: id + 1, url: file }));

                    await dispatch(set_hotel_info_pics(files));
                    return res.result;
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
                return {
                    error: 401,
                    message: 'error token',
                };
            }
        } catch (e) {
            console.log('addHotelInfoPics error: ', e);
            return {
                error: 1,
                message: e,
            };
        }

        return {
            error: 1,
            message: 'something wrong:(',
        };
    };

export const deleteHotelInfoPics =
    ({ access_token, file }) =>
    async dispatch => {
        try {
            const response = await api.deleteHotelInfoPics.fetch(access_token, file);

            if (response.status === 200) {
                const res = await response.json();

                if (res.result.error === 0) {
                    await dispatch(delete_hotel_info_pics(file));
                    return res.result;
                }
            } else if (response.status === 401) {
                await dispatch(set_token_error(true));
            }
        } catch (e) {
            console.log('deleteHotelInfoPics error: ', e);
        }
    };

export const createNewInfoSection =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        await dispatch(set_update_info_fetch(true));

        try {
            if (access_token) {
                const files =
                    data.hasOwnProperty('files') && data.files
                        ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                        : [];
                const allSize = files.reduce((acc, cur) => acc + cur.size, 0);
                if (allSize > 20971520) {
                    return errorNotification('Общий объем всех фото не должен превышать 20МБ');
                }
                const response = await api.editInformationItem.fetch(access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();
                    if (res.message === 'ok') {
                        const files = res.files.map((file, id) => ({ id: id + 1, url: file }));

                        const newData = {
                            ...res.added_object,
                            files2: files,
                            hotel_id: data.hotel_id,
                            section_link: data.section_link,
                        };
                        await dispatch(add_section(newData));
                        await dispatch(add_new_section(newData));
                        await dispatch(set_sort_info_sections({ hotel_id: data.hotel_id }));
                        await dispatch(set_response_status(true));
                        return newData;
                    }
                    if (res.code === 20) {
                        return res.code;
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('addNewInfoSection error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const createRoom =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        try {
            if (access_token) {
                const response = await api.createRoom.fetch(access_token, { hotel_id: data.hotel_id });

                if (response.status === 200) {
                    const result = await response.json();
                    if (result.message === 'ok') {
                        dispatch(create_room({ ...data, id: result.id }));
                        return result.id;
                    }
                } else if (response.status === 401) {
                    await dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('createRoom error: ', e);
        } finally {
            await dispatch(set_update_info_fetch(false));
        }
        return 0;
    };

export const getInfoIcons = () => async dispatch => {
    try {
        const response = await fetch(`${url}/?r=helper/icons`);
        if (response.status === 200) {
            const res = await response.json();
            dispatch(set_icons(res));
        }
    } catch (e) {
        console.log('getInfoIcons error: ', e);
    }
};

export const getLaundryMachines =
    ({ hotel_id }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        try {
            const response = await api.getLaundryMachines.fetch(access_token, hotel_id);

            if (response.status === 200) {
                const result = await response.json();
                dispatch(set_machines(result));
            }
        } catch (e) {
            console.log('getLaundryMachines error: ', e);
        }
    };

export const updateMachine =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        try {
            const response = await api.updateMachine.fetch(access_token, data.id, data);

            if (response.status === 200) {
                const result = await response.json();

                if (result.status === 'ok') {
                    dispatch(change_machine_info(data));
                    return 'ok';
                } else {
                    return result.error;
                }
            }
        } catch (e) {
            console.log('updateMachine error: ', e);
        }
    };

export const machineControl =
    ({ data }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        try {
            const response = await api.machineControl.fetch(access_token, data);

            if (response.status === 200) {
                const result = await response.json();

                if (result.status === 'ok') {
                    if (data.command === 'on') {
                        dispatch(set_start_machine(data));
                    } else {
                        dispatch(set_end_machine(data));
                    }
                    return 'ok';
                } else {
                    return result.error;
                }
            }
        } catch (e) {
            console.log('updateMachine error: ', e);
        }
    };

export const getLaundryHistory =
    ({ hotelId, roomNumber }) =>
    async (dispatch, getState) => {
        const accessToken = getState().login.access_token;

        try {
            const response = await api.getLaundryHistory.fetch(accessToken, hotelId, roomNumber);

            if (response.status === 200) {
                const result = await response.json();
                if (result && result.length) {
                    const laundryHistoryList = result.map(el => {
                        const isJsonString = str => {
                            try {
                                JSON.parse(str);
                            } catch (e) {
                                return false;
                            }
                            return true;
                        };
                        return {
                            action: el.action,
                            name: el.name,
                            date: el.date ? moment(el.date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM HH:mm') : '',
                            info: isJsonString(el.additional_info) ? JSON.parse(el.additional_info) : el.additional_info,
                            bookingId: el.booking_id,
                            roomNumber: el.room_number,
                        };
                    });
                    dispatch(setLaundryHistory(laundryHistoryList));
                }
            }
        } catch (e) {
            console.log('getLaundryHistory error: ', e);
        } finally {
            dispatch(setLaundryListFetch(false));
        }
    };

export const getHotelRoomNumbers =
    ({ hotelId }) =>
    async (dispatch, getState) => {
        const accessToken = getState().login.access_token;
        dispatch(setHotelRoomNumbersFetch(true));

        try {
            const response = await api.getHotelRoomNumbers.fetch(accessToken, hotelId);
            if (response.status === 200) {
                const result = await response.json();
                if (result.length > 0) {
                    const rooms = result.map(({ label, text, keyBoxCode, id, address }) => ({
                        label,
                        message: text,
                        keyBoxCode,
                        id,
                        hotelId,
                        address: address || '',
                    }));
                    dispatch(setHotelRoomNumbers(rooms));
                } else {
                    dispatch(setHotelRoomNumbers([]));
                }
            } else {
                dispatch(setHotelRoomNumbers([]));
            }
        } catch (e) {
            console.log('getHotelRoomNumbers error: ', e);
        } finally {
            dispatch(setHotelRoomNumbersFetch(false));
        }
    };

export const updatePersonalRoomMessage =
    ({ data }) =>
    async (dispatch, getState) => {
        const accessToken = getState().login.access_token;
        try {
            const response = await api.updatePersonalRoomMessage.fetch(accessToken, data);
            if (response.status === 200) {
                const result = await response.json();
                if (result === 1 || result === 2) {
                    dispatch(updateRoomMessage(data));
                    return true;
                } else {
                    return false;
                }
            }
        } catch (e) {
            console.log('getHotelRoomNumbers error: ', e);
        }
    };

export const response_status = state => state.information.response_status;
export const url_info_pic = state => state.information.url_info_pic;
export const information_tabs = state => state.information.information_tabs;
export const infoFetch = state => state.information.info_fetch;
export const updateInfoFetch = state => state.information.update_info_fetch;
export const hotel_info_pics = state => state.information.hotel_info_pics;
export const tab_info = state => state.information.tab_info;
export const information_icons = state => state.information.icons;
export const machines = state => state.information.machines;
export const machines_fetching = state => state.information.machines_fetching;
export const laundryHistoryListState = state => state.information.laundryHistoryList;
export const laundryListFetchState = state => state.information.laundryListFetch;
export const hotelRoomNumbersState = state => state.information.hotelRoomNumbers;
export const hotelRoomNumbersFetchState = state => state.information.hotelRoomNumbersFetch;
export const hotelInfoPicsFetchingState = state => state.information.hotelInfoPicsFetching;

export default informationSlice.reducer;
