import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { LanguageSwitcher } from './LanguageSwitcher';
import { login as login_state, logout } from '../../redux/slices/loginSlice';

export const Header = ({ setSidebarOpen, sidebarTrigger }) => {
    const { i18n } = useTranslation();

    const [, , removeCookie] = useCookies();
    const dispatch = useDispatch();
    const login = useSelector(login_state);

    const lang = window.navigator.language;

    const logoutHandler = () => {
        removeCookie('access_token', { path: '/' });
        removeCookie('refresh_token', { path: '/' });
        removeCookie('login', { path: '/' });
        dispatch(logout());
    };

    return (
        <header className="sticky top-0 z-30 shrink-0 border-b border-slate-200 bg-white">
            <div className="px-3 sm:px-6 lg:px-8">
                <div className="-mb-px flex h-16 items-center justify-between">
                    {/* Left side */}
                    <div className="flex">
                        {/* Hamburger button */}
                        <button
                            className="flex h-10 w-10 items-center justify-center rounded-full hover:bg-gray-100 lg:hidden"
                            onClick={() => setSidebarOpen(true)}
                            ref={sidebarTrigger}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg className="h-6 w-6 fill-blue-500" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4" y="5" width="16" height="2" />
                                <rect x="4" y="11" width="16" height="2" />
                                <rect x="4" y="17" width="16" height="2" />
                            </svg>
                        </button>
                    </div>

                    {/* Right side */}
                    <div className="flex items-center">
                        {login}

                        <button
                            type="button"
                            className="ml-2 flex h-10 w-10 items-center justify-center rounded-full hover:bg-gray-100"
                            onClick={logoutHandler}
                        >
                            <svg className="h-6 w-6 fill-blue-500" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59ZM19 3H5C3.89 3 3 3.9 3 5V9H5V5H19V19H5V15H3V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" />
                            </svg>
                        </button>
                        {!lang.includes('en') && Object.keys(i18n).length ? <LanguageSwitcher /> : ''}
                    </div>
                </div>
            </div>
        </header>
    );
};
