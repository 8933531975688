import { Field } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InitValues } from './types/reviewTypes';

type Props = {
    values: InitValues;
    isSubmitting: boolean;
};

export const ReviewTitle: FC<Props> = ({ values, isSubmitting }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="border-b pb-3">
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('REVIEW_TITLE')}</p>
                <Field
                    name="mainText"
                    component="textarea"
                    value={values.mainText ?? ''}
                    placeholder={t('REVIEW_PLACEHOLDER_TEXT')}
                    className="border-gray-10 box-border h-14 w-full resize-none rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                />
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('REVIEW_TITLE_EN')}</p>
                <Field
                    name="mainTextEn"
                    component="textarea"
                    value={values.mainTextEn ?? ''}
                    placeholder={t('REVIEW_PLACEHOLDER_TEXT_EN')}
                    className="border-gray-10 box-border h-14 w-full resize-none rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                />
            </div>
        </>
    );
};
