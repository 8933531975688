import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarMenuLinkGroupButton } from './SidebarMenuLinkGroupButton';

export const SidebarMenuGroup = ({ url, title, children, icon }) => {
    const { pathname } = useLocation();

    const [isOpen, setIsOpen] = useState(pathname.includes(url));

    return (
        <li className="mb-2 last:mb-0">
            <SidebarMenuLinkGroupButton title={title} icon={icon} url={url} isOpen={isOpen} setIsOpen={setIsOpen} />

            <div className="bg-slate-50">
                <ul className={`mt-1 ${!isOpen && 'hidden'}`}>{children}</ul>
            </div>
        </li>
    );
};
