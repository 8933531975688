export const TapBar = ({ selectedProgram, setSelectedProgram }) => {
    return (
        <div className="mt-3 flex justify-center gap-16 px-6 py-3 text-center">
            <button
                type="button"
                onClick={() => setSelectedProgram('short')}
                className={`${selectedProgram === 'short' ? 'text-blue-500' : ''} rounded-2xl bg-white px-3 py-1.5 transition-colors hover:text-blue-500`}
            >
                <span className={`selectedProgram === 1 ? 'font-semibold text-blue-500' : ''`}>Краткосрочное проживание</span>
            </button>
            <button
                type="button"
                onClick={() => setSelectedProgram('long')}
                className={`${selectedProgram === 'long' ? 'text-blue-500' : ''} rounded-2xl bg-white px-3 py-1.5 transition-colors hover:text-blue-500`}
            >
                Долгосрочное проживание
            </button>
        </div>
    );
};
