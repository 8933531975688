export const getFilteredTimeStart = (time, values) => {
    const startTime = new Date(new Date().setHours(values.meeting_id.start));
    const endTime = new Date(new Date().setHours(values.meeting_id.end));
    const selectedDate = new Date(time);

    if (values.date > Date.now()) {
        return startTime.getHours() <= selectedDate.getHours() && selectedDate.getHours() < endTime.getHours();
    } else {
        if (startTime.getHours() < new Date().getHours() && endTime.getHours() - 0.5 < new Date().getHours()) {
            return null;
        } else if (startTime.getHours() < new Date().getHours() && endTime.getHours() > new Date().getHours()) {
            return new Date().getTime() < selectedDate.getTime() && selectedDate.getHours() < endTime.getHours();
        } else {
            return startTime.getHours() <= selectedDate.getHours() && selectedDate.getHours() < endTime.getHours();
        }
    }
};
