import React from 'react';
import AuthImage from '../source/images/auth-image.svg';
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
    return (
        <div className="relative h-screen w-full flex-row md:flex">
            {/* Image */}
            <div className="hidden h-full w-1/2 md:flex md:items-center" aria-hidden="true">
                <img className="w-full" src={AuthImage} alt="Authentication" />
            </div>

            {/* Content */}
            <div className="w-full md:w-1/2">
                <div className="mx-auto flex min-h-screen max-w-sm flex-col justify-center px-4 py-8">
                    <div className="flex w-full flex-col items-center">
                        <h1 className="text-4xl font-semibold">Ничего не найдено</h1>
                        <Link to="/" className="mt-4 text-lg text-blue-500 hover:underline">
                            вернуться на главную
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
