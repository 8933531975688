import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';

import { SendToWhatsAppButton } from './SendToWhatsAppButton';
import { whatsAppTemplatesState, setReadTemplate } from '../../../redux/slices/chatSlice.js';
import { CopyTemplateButton } from './CopyTemplateButton';
import { AppDispatch } from '../../../redux/store';

type Props = {
    bookingId: string;
    setBookingId: Dispatch<SetStateAction<string>>;
    roomNumber: string;
};

type WhatsAppTemplate = {
    booking_id: string;
    date_from: string;
    ext_id: string;
    hotel_id: string;
    id: string;
    is_admin_read: string;
    link: string;
    name: string;
    template: string;
};
export const WhatsAppDetail: FC<Props> = ({ bookingId, setBookingId }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch<AppDispatch>();
    const whatsAppTemplates: WhatsAppTemplate[] = useSelector(whatsAppTemplatesState);

    const [template, setTemplate] = useState<WhatsAppTemplate | undefined>(undefined);

    useEffect(() => {
        if (bookingId) {
            const template = whatsAppTemplates.find(el => el.booking_id === bookingId);
            setTemplate(template);
            if (template && template.is_admin_read === '0') {
                dispatch(setReadTemplate(bookingId));
            }
        }
    }, [bookingId]);

    return (
        <>
            <div className="border-b-gray-10 sticky top-0 flex w-full items-center justify-center border-b bg-white p-3 md:hidden">
                <button type="button" onClick={() => setBookingId('')} className="mr-auto flex w-20 items-center gap-2">
                    <svg className="h-5 w-3 fill-blue-500" viewBox="0 0 12 20">
                        <path d="M0 9.93896C0 10.3208 0.145996 10.6465 0.449219 10.9385L9.20898 19.5073C9.44482 19.7544 9.75928 19.8779 10.1187 19.8779C10.8486 19.8779 11.4214 19.3164 11.4214 18.5752C11.4214 18.2158 11.2754 17.8901 11.0283 17.6431L3.1333 9.93896L11.0283 2.23486C11.2754 1.97656 11.4214 1.65088 11.4214 1.2915C11.4214 0.561523 10.8486 0 10.1187 0C9.75928 0 9.44482 0.123535 9.20898 0.370605L0.449219 8.93945C0.145996 9.23145 0.0112305 9.55713 0 9.93896Z" />
                    </svg>
                    <span className="text-blue-500">{t('CHAT_BACK')}</span>
                </button>

                <span className="ml-2 truncate font-bold">{template?.booking_id ? `Бронирование ${template?.booking_id}` : ''}</span>
                <span className="ml-auto w-4"></span>
            </div>
            <div className="relative overflow-auto px-6 pb-1 pt-6">
                <div className="mb-3 text-center text-sm">{moment(template?.date_from, 'YYYY-MM-DD HH:mm:ss').format('DD MMM HH:mm')}</div>
                <div className="flex flex-wrap overflow-auto rounded-[18px] bg-gray-200 p-3 px-3 pb-1.5 pt-3 text-sm md:p-6">{template?.template}</div>
                <div className="flex w-full justify-center bg-white py-3 pb-24 md:p-3">
                    {template?.link ? <SendToWhatsAppButton link={template.link} /> : <CopyTemplateButton text={template?.template} />}
                </div>
            </div>
        </>
    );
};
