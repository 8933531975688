import React, { useEffect, useRef, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { addFood, editFood, food as food_state } from '../../redux/slices/foodSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { PicsLoader } from '../common/PicsLoader';
import { errorNotification } from '../../api/functions';

const initState = {
    name: '',
    name_en: '',
    price: '',
    weight: '',
    id: '',
    category: '',
    files: [],
};

const SignupSchema = Yup.object().shape({
    name: Yup.string().min(2, 'INFO_ERROR_1').max(100, 'SHOP_ERROR').required('INFO_ERROR_3'),
    name_en: Yup.string().min(2, 'INFO_ERROR_4').max(100, 'SHOP_ERROR_1').required('INFO_ERROR_6'),
    price: Yup.string().min(2, 'INFO_ERROR_47').max(100, 'INFO_ERROR_48').required('INFO_ERROR_49'),
    weight: Yup.string().min(2, 'INFO_ERROR_78').max(100, 'INFO_ERROR_79').required('INFO_ERROR_80'),
    category: Yup.string().required('INFO_ERROR_81'),
});

export const Detail = ({ tab, detailId, setDetailId }) => {
    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const food = useSelector(food_state);

    const [initialState, setInitialState] = useState(initState);
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (detailId && detailId !== 'new') {
            let detailItem = null;

            for (const cat of food) {
                if (detailItem) break;
                detailItem = cat.items &&
                    cat.items.find(item => item.id === detailId) && {
                        ...cat.items.find(item => item.id === detailId),
                        mass: cat.mass,
                    };
            }

            (async () => {
                setInitialState({
                    name: detailItem.name || '',
                    name_en: detailItem.name_en || '',
                    price: detailItem.price || '',
                    weight: detailItem.weight || '',
                    category: detailItem.category || '',
                    id: detailItem.id,
                    files: detailItem.img_path ? [detailItem.img_path] : [],
                });
            })();
        }
    }, [detailId]);

    const closeHandler = resetForm => {
        setDetailId('');
        resetForm();
        setInitialState(initState);
    };

    const onSubmitHandler = async (values, resetForm) => {
        setFetchAccessToken(access_token);
        let res;
        if (detailId !== 'new') {
            res = await dispatch(editFood({ access_token, data: values }));
        } else {
            res = await dispatch(addFood({ access_token, data: { ...values, hotel_id: tab.hotel_id } }));
        }

        if (res.status) {
            closeHandler(resetForm);
        } else {
            if (res.error) {
                errorNotification(res.error);
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await onSubmitHandler(formikRef.current.values, formikRef.current.resetForm);
            }
        })();
    }, [access_token]);

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            validationSchema={SignupSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ values, errors, isValidating, isSubmitting, setFieldValue, resetForm }) => {
                if (isSubmitting && !isValidating) {
                    for (const err in errors) {
                        errorNotification(t(errors[err]));
                    }
                }

                return (
                    <Form
                        className={`${
                            detailId ? 'flex' : 'hidden'
                        } fixed inset-0 z-40 items-center justify-center bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200`}
                    >
                        <div className="flex max-h-[97vh] w-[770px] max-w-full flex-col overflow-auto bg-white p-6">
                            <div className="mb-6 flex w-full flex-row border border-gray-300">
                                <div className="w-1/3 border-r border-r-gray-300 bg-gray-100 p-4">
                                    <PicsLoader setFieldValue={setFieldValue} files={values.files} />
                                </div>
                                <div className="flex w-2/3 flex-row flex-wrap content-start justify-between p-4">
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="name" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('NAME')}
                                        </label>
                                        <Field id="name" name="name" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="name_en" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('NAME_EN')}
                                        </label>
                                        <Field id="name_en" name="name_en" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="price" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_PRICE')}
                                        </label>
                                        <Field
                                            id="price"
                                            name="price"
                                            type="text"
                                            className="border border-gray-300 p-2"
                                            onChange={e => {
                                                e.preventDefault();
                                                const { value } = e.target;

                                                let formattedValue = value.trim().replace(/[^0-9,]/g, '');

                                                if (formattedValue[0] === ',') {
                                                    formattedValue = `0${formattedValue}`;
                                                }

                                                if (formattedValue[0] === '0' && formattedValue[1] === '0') {
                                                    formattedValue = formattedValue[0] + formattedValue.slice(2, formattedValue.length);
                                                }

                                                if (formattedValue.indexOf(',') >= 0 && formattedValue.indexOf(',', formattedValue.indexOf(',') + 1) >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',', formattedValue.indexOf(',') + 1));
                                                }

                                                if (formattedValue.indexOf(',') >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',') + 3);
                                                }

                                                setFieldValue('price', formattedValue);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="weight" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_WEIGHT')}
                                        </label>
                                        <Field id="weight" name="weight" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="category" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_CATEGORY')}
                                        </label>
                                        <Field as="select" name="category" className="border-gray-300">
                                            <option value="" disabled>
                                                {t('NO_CHOSEN')}
                                            </option>
                                            {food.map(cat => (
                                                <option value={cat.id} key={cat.name_en}>
                                                    {i18n.language === 'ru' ? cat.name : cat.name_en}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" mr-4 h-10 bg-transparent px-6 text-gray-700 transition-colors hover:text-black"
                                    onClick={() => closeHandler(resetForm)}
                                >
                                    {t('CANCEL')}
                                </button>
                                <button
                                    type="submit"
                                    className={`flex h-10 w-[135px] items-center justify-center px-6 text-white ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
