import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from '../../common/ContentWrapper';
import { TapBar } from './TapBar';

import { updateLoyaltyList, set_response_status, response_status as response_status_state } from '../../../redux/slices/informationSlice';
import { tabs as tabs_state } from '../../../redux/slices/hotelSlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';
import { errorNotification, successNotification } from '../../../api/functions';
import { root } from '../../../api/config';

const LoyaltySchema = Yup.object().shape({
    basic: Yup.array()
        .min(1, 'LOYALTY_ERROR')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
                value_en: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
            })
        ),
    silver: Yup.array()
        .min(1, 'LOYALTY_ERROR')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
                value_en: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
            })
        ),
    gold: Yup.array()
        .min(1, 'LOYALTY_ERROR')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
                value_en: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
            })
        ),
    platinum: Yup.array()
        .min(1, 'LOYALTY_ERROR')
        .of(
            Yup.object().shape({
                value: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
                value_en: Yup.string().min(2, 'LOYALTY_ERROR_1').required('LOYALTY_ERROR_2'),
            })
        ),
});

export const LoyaltyForm = ({ settings, setInitSettingsValues }) => {
    const [tab, setTab] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState('short');
    const [initValues, setInitValues] = useState({
        basic: [{ value: '', value_en: '' }],
        silver: [{ value: '', value_en: '' }],
        gold: [{ value: '', value_en: '' }],
        platinum: [{ value: '', value_en: '' }],
    });

    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const { hotel_id } = useParams();

    const formikRef = useRef();

    const dispatch = useDispatch();
    const tabs = useSelector(tabs_state);
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (response_status) {
            successNotification(t('SAVE_INFORMATION'));
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        if (tabs.length !== 0) {
            const tab = tabs.find(el => el.id === hotel_id);
            setTab({
                short: tab.loyalty_json.short ? tab.loyalty_json.short : tab.loyalty_json,
                long: tab.loyalty_json.long ? tab.loyalty_json.long : tab.loyalty_json,
            });
        }
    }, [tabs, hotel_id]);

    useEffect(() => {
        if (tab) {
            const loyaltySettings = {};
            if (tab.hasOwnProperty('short') || tab.hasOwnProperty('long')) {
                const objValues = tab[selectedProgram]
                    ? tab[selectedProgram].reduce((acc, cur, id) => {
                          acc[cur.name_en.split(' ')[0].toLowerCase()] = cur.items;
                          loyaltySettings[cur.lang_key] = cur.statusCount;
                          loyaltySettings[`percentDiscount${id + 1}`] = cur.percentDiscount;
                          return acc;
                      }, {})
                    : initValues;
                setInitValues(objValues);
            } else {
                const objValues = tab.reduce((acc, cur) => {
                    acc[cur.name_en.split(' ')[0].toLowerCase()] = cur.items;
                    loyaltySettings[cur.lang_key] = cur.statusCount;
                    return acc;
                }, {});
                setInitValues(objValues);
            }
            setInitSettingsValues(loyaltySettings);
        }
    }, [tab, hotel_id, selectedProgram]);

    const formSubmit = async (values, setSubmitting) => {
        setFetchAccessToken(access_token);
        let status;

        // проверка, что все поля объекта settings заполнены
        if (!Object.values(settings).every(value => value !== '')) {
            errorNotification(t('LOYALTY_ERROR_3'));
            return;
        }

        const arr =
            selectedProgram === 'short'
                ? {
                      short: [
                          {
                              items: values.basic,
                              name: 'Участник',
                              name_en: 'Basic Status',
                              lang_key: 'LOYALTY_STATUS_1',
                              statusCount: settings.LOYALTY_STATUS_1,
                              percentDiscount: settings.percentDiscount1,
                          },
                          {
                              items: values.silver,
                              name: 'Серебряный статус',
                              name_en: 'Silver Status',
                              lang_key: 'LOYALTY_STATUS_2',
                              statusCount: settings.LOYALTY_STATUS_2,
                              percentDiscount: settings.percentDiscount2,
                          },
                          {
                              items: values.gold,
                              name: 'Золотой статус',
                              name_en: 'Gold Status',
                              lang_key: 'LOYALTY_STATUS_3',
                              statusCount: settings.LOYALTY_STATUS_3,
                              percentDiscount: settings.percentDiscount3,
                          },
                          {
                              items: values.platinum,
                              name: 'Платиновый статус',
                              name_en: 'Platinum Status',
                              lang_key: 'LOYALTY_STATUS_4',
                              statusCount: settings.LOYALTY_STATUS_4,
                              percentDiscount: settings.percentDiscount4,
                          },
                      ],
                      long: tab.long,
                  }
                : {
                      long: [
                          {
                              items: values.basic,
                              name: 'Участник',
                              name_en: 'Basic Status',
                              lang_key: 'LOYALTY_STATUS_1',
                              statusCount: settings.LOYALTY_STATUS_1,
                          },
                          {
                              items: values.silver,
                              name: 'Серебряный статус',
                              name_en: 'Silver Status',
                              lang_key: 'LOYALTY_STATUS_2',
                              statusCount: settings.LOYALTY_STATUS_2,
                          },
                          {
                              items: values.gold,
                              name: 'Золотой статус',
                              name_en: 'Gold Status',
                              lang_key: 'LOYALTY_STATUS_3',
                              statusCount: settings.LOYALTY_STATUS_3,
                          },
                          {
                              items: values.platinum,
                              name: 'Платиновый статус',
                              name_en: 'Platinum Status',
                              lang_key: 'LOYALTY_STATUS_4',
                              statusCount: settings.LOYALTY_STATUS_4,
                          },
                      ],
                      short: tab.short,
                  };
        const data = {
            loyalty_json: JSON.stringify(arr),
            hotel_id,
            loyalty: arr,
            settings,
        };

        status = await dispatch(updateLoyaltyList({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
        }
    };
    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    return (
        <>
            <ContentWrapper>
                <Formik
                    initialValues={initValues}
                    validationSchema={LoyaltySchema}
                    innerRef={formikRef}
                    onSubmit={async (values, { setSubmitting }) => await formSubmit(values, setSubmitting)}
                    enableReinitialize={true}
                >
                    {({ values, errors, isValidating, isSubmitting }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                Array.isArray(errors[err])
                                    ? errors[err].map(el => {
                                          typeof el === 'object'
                                              ? el.hasOwnProperty('value')
                                                  ? errorNotification(t(el.value))
                                                  : errorNotification(t(el.value_en))
                                              : el;
                                      })
                                    : errorNotification(t(errors[err]));
                            }
                        }
                        return (
                            <Form>
                                <div className="flex">
                                    {/* main info */}
                                    <div className=" w-full ">
                                        <div className="flex items-center border-b bg-white">
                                            <p className="pb-6 pl-6 pt-6 font-medium">{t('LOYALTY_CONDITIONS_3')}</p>
                                            <div className="ml-auto mr-6" data-tip={t('LOYALTY')}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        {root.includes('r=api') && <TapBar selectedProgram={selectedProgram} setSelectedProgram={setSelectedProgram} />}

                                        <div className="w-full flex-wrap justify-between bg-slate-100 sm:flex ">
                                            {tab &&
                                                tab[selectedProgram].map((status, id) => (
                                                    <div key={status.name} className=" mt-6 rounded bg-white p-2 shadow-md sm:w-[49%]">
                                                        <p className="text-center font-bold">{i18n.language === 'ru' ? status.name : status.name_en}</p>

                                                        <FieldArray name={Object.keys(initValues)[id]}>
                                                            {({ remove, push }) => (
                                                                <div>
                                                                    {values[Object.keys(initValues)[id]].map((option, idx) => (
                                                                        <div key={idx}>
                                                                            <div className="flex ">
                                                                                <Field
                                                                                    name={`${Object.keys(initValues)[id]}.${idx}.value`}
                                                                                    component="textarea"
                                                                                    placeholder={t('LOYALTY_PLACEHOLDER')}
                                                                                    className="border-gray-10 mr-2 mt-3 box-border h-24 w-full rounded p-1 pl-2 text-sm"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <Field
                                                                                    name={`${Object.keys(initValues)[id]}.${idx}.value_en`}
                                                                                    component="textarea"
                                                                                    placeholder={t('LOYALTY_PLACEHOLDER')}
                                                                                    className="border-gray-10 mt-3 box-border h-24 w-full rounded p-1 pl-2 text-sm"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => remove(idx)}
                                                                                className="mt-1 w-16 rounded  border border-red-500 bg-white pl-1 pr-1 text-xs text-red-500 transition-colors hover:bg-red-500 hover:text-white"
                                                                            >
                                                                                {t('LOYALTY_REMOVE')}
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => push({ value: '', value_en: '' })}
                                                                        className="w-19 mb-3 mt-6 rounded border border-blue-500 bg-white pl-2 pr-2 text-sm text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
                                                                    >
                                                                        {t('LOYALTY_ADD_FIELD')}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mb-6 ml-4 mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                                        !isSubmitting ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                    } `}
                                    disabled={isSubmitting || Object.keys(errors).length !== 0}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
