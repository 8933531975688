export const ToggleWithoutOnChange = ({ children, checked = false, onClick }) => {
    return (
        <label className="relative flex cursor-pointer select-none items-center">
            <input type="checkbox" className="peer sr-only" checked={checked} onChange={() => {}} onClick={e => onClick(e)} />
            <div className="peer flex h-6 w-11 flex-row items-center justify-around rounded-full bg-gray-400 after:absolute after:left-0.5 after:top-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-500 peer-checked:after:translate-x-full peer-checked:after:border-white  ">
                {children}
            </div>
        </label>
    );
};
