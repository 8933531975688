import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { BallTriangle } from 'react-loader-spinner';

import { getHotelsList } from '../../../redux/slices/hotelSlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';

export const ArrivingGuestTable = ({ tab }) => {
    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);

    const { t,i18n } = useTranslation();

    useEffect(() => {
        if (access_token) {
            dispatch(getHotelsList({ access_token }));
            const interval = setInterval(async () => {
                await dispatch(getHotelsList({ access_token }));
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [access_token]);

    const guests = tab?.arriving_guests ? tab.arriving_guests : [];

    const columns = useMemo(
        () => [
            {
                Header: t('LOYALTY_FIO'),
                accessor: 'Name',
            },
            {
                Header: t('LOYALTY_STATUS'),
                accessor: i18n.language === 'ru' ? 'status_name' : 'status_name_en',
            },
            {
                Header: t('LOYALTY_ROOM'),
                accessor: 'room_number',
            },
            {
                Header: t('LOYALTY_COMMENT'),
                accessor: 'comment',
            },
        ],
        [t, i18n]
    );

    const data = useMemo(() => guests || [], [tab]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    if (!tab) {
        return (
            <div className="mt-8 flex w-full justify-center">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }
    if (tab) {
        return (
            <>
                {guests.length === 0 && <div className="mt-8 flex w-full justify-center">{t('LOYALTY_TEXT_1')}</div>}

                {guests.length !== 0 && (
                    <div className="mx-auto w-full max-w-9xl overflow-x-auto p-4 pb-4 sm:p-6">
                        <table {...getTableProps()} className="w-full min-w-[800px] bg-white">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()} className="border border-gray-100 px-4 py-3 text-left">
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell.column.id === 'Name') {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className={`${
                                                                cell.row.original.black_list === '1' ? 'text-red-500' : ''
                                                            } border border-gray-100 px-4 py-3 text-sm`}
                                                        >
                                                            {cell.value}
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td {...cell.getCellProps()} className="border border-gray-100 px-4 py-3 text-sm ">
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </>
        );
    }
};
