import { useEffect, useState } from 'react';

export const SidebarExpandButton = () => {
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');

    const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
        if (sidebarExpanded) {
            document.querySelector('body').classList.add('sidebar-expanded');
        } else {
            document.querySelector('body').classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    return (
        <div className="mt-auto hidden justify-end pt-3 lg:inline-flex 2xl:hidden">
            <div className="px-3 py-2">
                <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                    <span className="sr-only">Expand / collapse sidebar</span>
                    <svg className="h-6 w-6 fill-blue-500 transition-colors hover:fill-blue-700 sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                        <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                        <path className="text-slate-600" d="M3 23H1V1h2z" />
                    </svg>
                </button>
            </div>
        </div>
    );
};
