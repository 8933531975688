import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useDispatch, useSelector } from 'react-redux';
import { access_token as access_token_state } from './redux/slices/loginSlice';
import { setPushToken } from './redux/slices/chatSlice';
import { useEffect } from 'react';

const firebaseConfig = {
    apiKey: 'AIzaSyDFDZnI9mFPtUq5D-54m-DCKmaK472iv54',
    authDomain: 'vertical-hotel.firebaseapp.com',
    databaseURL: 'https://project-id.firebaseio.com',
    projectId: 'vertical-hotel',
    storageBucket: 'vertical-hotel.appspot.com',
    messagingSenderId: '825961049596',
    appId: '1:825961049596:web:14710ae54c58ef0d0d5679',
    measurementId: 'G-WKCRTTB6MV',
};

export const requestForToken = () => {
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);

    useEffect(() => {
        if (access_token) {
            (async () => {
                try {
                    const permission = await Notification.requestPermission();
                    if (permission === 'granted') {
                        const token = await getToken(messaging, {
                            vapidKey: 'BKZ1VULyHxHeA9Ans9E9sSkoEgoxPnhXWBIApDeQdWflNAJgbGHVljd3Lq66XDwC84h23eImnnZJ1bLKHK-fXTI',
                        });
                        if (token) {
                            // console.log('token true:', token);
                            dispatch(setPushToken({ access_token, push_token: token }));
                        } else {
                            // console.log('current token false: ', token);
                        }
                    } else {
                        await Notification.requestPermission();
                    }
                } catch (error) {
                    // console.error('Error while requesting permission or getting token', error);
                }
            })();

            onMessage(messaging, payload => {
                // console.log('Message received. from onMessage', payload);
                // Handle the notification data within the app
                // For example, update the state or show an in-app message
            });
        }
    }, [access_token]);

    return null;
};
