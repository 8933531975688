import { SubHeader } from '../../common/SubHeader';

export const Header = ({ tab, setDetailId }) => (
    <SubHeader>
        {tab && (
            <div className="text-gray flex w-full flex-row items-center text-xs font-semibold uppercase text-gray-500 sm:text-sm lg:text-xl">
                {tab.hotel_name}
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                    <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L9.70711 4.29289ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L8.29289 18.2929ZM8.29289 5.70711L15.2929 12.7071L16.7071 11.2929L9.70711 4.29289L8.29289 5.70711ZM15.2929 11.2929L8.29289 18.2929L9.70711 19.7071L16.7071 12.7071L15.2929 11.2929Z" />
                </svg>
                Услуги
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                    <path d="M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L9.70711 4.29289ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L8.29289 18.2929ZM8.29289 5.70711L15.2929 12.7071L16.7071 11.2929L9.70711 4.29289L8.29289 5.70711ZM15.2929 11.2929L8.29289 18.2929L9.70711 19.7071L16.7071 12.7071L15.2929 11.2929Z" />
                </svg>
                <span className="text-black">{tab.name}</span>
            </div>
        )}

        <div className="ml-auto shrink-0">
            <button
                className="flex flex-row items-center rounded-full bg-blue-500 px-6 py-2 text-white transition-colors hover:bg-blue-800"
                onClick={() => setDetailId('new')}
            >
                <svg className="mr-2 h-5 w-5 fill-current stroke-current" viewBox="0 0 20 20">
                    <path d="M5 10H15" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 15L10 5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Добавить тариф
            </button>
        </div>
    </SubHeader>
);
