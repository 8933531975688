import { useState, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';

import { Header } from '../components/review/Header';
import { tabs as tabs_state, getHotelReview, reviewFetchingState, tab_fetching } from '../redux/slices/hotelSlice';
import { ReviewLayout } from '../components/review/ReviewLayout';
import { AppDispatch } from '../redux/store';

type Tab = { [key: string]: string }[];
type State = {
    hotel_name: string;
    hotel_id: string;
};

export const ReviewPage: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const reviewFetching = useSelector(reviewFetchingState);
    const tabFetching = useSelector(tab_fetching);

    const [tab, setTab] = useState<State | null>(null);

    const tabs: Tab = useSelector(tabs_state);
    const { hotel_id } = useParams();

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel) {
                setTab({
                    hotel_name: hotel.Name,
                    hotel_id: hotel.id,
                });
                dispatch(getHotelReview({ hotelId: hotel_id }));
            }
        }
    }, [tabs, hotel_id]);

    if (reviewFetching || tabFetching) {
        return (
            <div className="flex h-full w-full items-center justify-center">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }
    return (
        <>
            <Header tab={tab} />

            <ReviewLayout />
        </>
    );
};
