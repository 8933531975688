import React from 'react';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { Fancybox } from './Fancybox';

import { errorNotification } from '../../api/functions';
import { SortableItem } from './sortable/SortableItem';
import { SortableWrapper } from './sortable/SortableWrapper';

export const SortablePicsLoader = ({ setFieldValue, files = [], maxFiles = 1, callback, sortDispatch }) => {
    const { t } = useTranslation();
    const errors = [
        {
            code: 'too-many-files',
            message: t('INFO_PICSLOADER_ERROR_6'),
        },
        {
            code: 'file-too-large',
            message: t('INFO_PICSLOADER_ERROR_7'),
        },
    ];
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            // 'image/*': [],
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
            'image/heic': [],
            'image/jfif': [],
        },
        maxFiles,
        maxSize: 5242880, //5mb
        onDrop: (acceptedFiles, fileRejections) => {
            if (acceptedFiles.length) {
                if (maxFiles > 1) {
                    if (20 - (files.length + acceptedFiles.length) >= 0) {
                        const allSize = acceptedFiles.reduce((acc, cur) => acc + cur.size, 0);
                        if (allSize < 20971520) {
                            setFieldValue('files', [
                                ...files,
                                ...acceptedFiles.map(file => ({
                                    url: URL.createObjectURL(file),
                                    // случайная генерация id от 30 до 1_000_000 чтобы не дублировался id
                                    id: Math.floor(Math.random() * (1_000_000 - 30 + 1)) + 30,
                                })),
                            ]);
                        } else {
                            errorNotification(t('INFO_PICSLOADER_ERROR_1'));
                        }
                    } else {
                        errorNotification(t('INFO_PICSLOADER_ERROR_2'));
                    }
                } else {
                    setFieldValue(
                        'files',
                        acceptedFiles.map((file, id) => ({ url: URL.createObjectURL(file), id: id + 1 }))
                    );
                }
            }

            if (fileRejections.length) {
                fileRejections.forEach(file => {
                    errorNotification(
                        `${t('INFO_PICSLOADER_ERROR_3')} ${file.file.name.substring(0, 10)} ${t('INFO_PICSLOADER_ERROR_4')}. ${
                            errors.find(er => er.code === file.errors[0].code).message
                        }`
                    );
                });
            }
        },
    });

    return (
        <>
            <input {...getInputProps()} />
            <div className="mb-2 flex w-full text-base font-semibold text-black">
                <span>{t('PHOTO')}</span>
                <div className="ml-auto" data-tip data-for="picsLoader">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#1890FF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <aside className="flex flex-row flex-wrap gap-2">
                <SortableWrapper items={files} sortDispatch={sortDispatch}>
                    {files.length !== 0 &&
                        files.map((file, index) => (
                            <SortableItem key={`item${file.id}`} id={file.id}>
                                <div key={file.id} className="relative h-[90px] w-[90px] cursor-pointer" data-src={file.url}>
                                    <img src={file.url} alt="img" data-fancybox="gallery" className="h-full w-full object-cover" />
                                    <button
                                        type="button"
                                        className="absolute right-0 top-0 z-10 flex h-6 w-6 items-center justify-center bg-black"
                                        onClick={() => {
                                            if (callback) {
                                                callback(file);
                                            }
                                            setFieldValue('files', [...files.slice(0, index), ...files.slice(index + 1)]);
                                        }}
                                    >
                                        <svg className="h-4 w-4 fill-white" viewBox="0 0 14 14">
                                            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                                        </svg>
                                    </button>
                                </div>
                            </SortableItem>
                        ))}
                </SortableWrapper>
                <Fancybox />
                {(files.length === 0 || maxFiles > 1) && (
                    <div {...getRootProps({ className: 'dropzone' })} className="flex h-[90px] w-[90px] cursor-pointer items-center justify-center bg-gray-200">
                        <svg viewBox="0 0 14 14" className="h-4 w-4 fill-black">
                            <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fillOpacity="0.45" />
                        </svg>
                    </div>
                )}
            </aside>
            <ReactTooltip
                id="picsLoader"
                aria-haspopup="true"
                textColor="#FCFCFC"
                backgroundColor="#8c8d9d"
                effect="solid"
                className="!rounded-md !px-2.5 !py-2"
            >
                <h2>
                    <strong>{t('INFO_TOOLTIP_3')}</strong>
                </h2>
                <ul>
                    <li>
                        {t(t('TOOLTIP_MAX_PHOTO'))} {maxFiles}
                    </li>
                    <li>{t('TOOLTIP_MAX_PHOTO_MB')}</li>
                    {maxFiles > 1 && <li>{t('TOOLTIP_MAX_PHOTOS_MB')}</li>}
                </ul>
            </ReactTooltip>
        </>
    );
};
