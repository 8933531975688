import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { Activity } from './Activity';
import { Icons } from './Icons';
import { Photo } from './Photo';
import { Sort } from './Sort';

export const MainDetailAccordion = ({ values, isSubmitting, dataSvg, handleSvgClick, idSvg, setFieldValue, initValues, sortDispatch }) => {
    const { t } = useTranslation();
    return (
        <div className=" bg-white sm:w-full">
            <div className="flex items-center border-b">
                <p className="pb-6 pl-6 pt-6 font-medium">{t('MAIN_INFO')}</p>
                <div className="ml-auto mr-6" data-tip data-for="address">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#1890FF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>

            <div className="flex pt-3">
                {/* name */}
                <div className="w-1/2 pl-6 pr-6">
                    <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME')}</p>
                    <Field
                        name="name"
                        component="textarea"
                        value={values.name}
                        placeholder={t('INFO_SECTION_NAME')}
                        className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                        disabled={isSubmitting}
                    />
                </div>

                {/* name EN */}
                <div className=" w-1/2 pl-6 pr-6">
                    <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME_EN')}</p>
                    <Field
                        name="name_en"
                        component="textarea"
                        value={values.name_en}
                        placeholder={t('INFO_SECTION_NAME_EN')}
                        className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                        disabled={isSubmitting}
                    />
                </div>
            </div>

            <div className="mt-3 flex ">
                {/* activity */}
                <div className="w-1/2">
                    <Activity />
                </div>

                {/* sort */}
                <div className=" flex w-1/2 items-center">
                    <Sort values={values} isSubmitting={isSubmitting} />
                </div>
            </div>

            <div className="flex border-b pb-6">
                {/* icon */}
                <div className="sm:w-1/2">
                    <Icons dataSvg={dataSvg} handleSvgClick={handleSvgClick} idSvg={idSvg} setFieldValue={setFieldValue} currentSvg={initValues?.svg_current} />
                </div>

                {/* photo */}
                <div className="sm:w-1/2 ">
                    <Photo setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />
                </div>
            </div>
        </div>
    );
};
