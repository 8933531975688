import { FC } from 'react';

import { ContentWrapper } from '../common/ContentWrapper';
import { Review } from './Review';
import ReactTooltip from 'react-tooltip';

export const ReviewLayout: FC = () => {
    return (
        <>
            <ContentWrapper>
                <div className="flex gap-6">
                    <div className="w-1/2 bg-white">
                        <Review type="checkIn" />
                    </div>
                    <div className="w-1/2 bg-white">
                        <Review type="checkOut" />
                    </div>
                </div>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
