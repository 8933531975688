import { TFunction } from 'i18next';
import { errorNotification } from '../../../../../../api/functions.js';
import { FormikErrors } from 'formik';

export const errorNotificationList = (errors: FormikErrors<any>, t: TFunction<'translation', undefined>) => {
    Object.keys(errors).forEach(errKey => {
        const err = (errors as any)[errKey];
        if (typeof err === 'string') {
            errorNotification(t(err));
        }
    });
};

export const errorNotificationAccordion = (errors: any, t: TFunction<'translation', undefined>) => {
    for (const err in errors) {
        if (err === 'json') {
            errors[err]?.map(el => {
                for (const err in el) {
                    if (Array.isArray(el[err])) {
                        el[err].map(el => errorNotification(t(el)));
                    } else if (typeof el[err] === 'object') {
                        el[err].head?.first?.map(el => errorNotification(t(el)));
                        el[err].head?.second?.map(el => errorNotification(t(el)));
                        el[err].body?.map(el => {
                            for (const err in el) {
                                errorNotification(t(el[err]));
                            }
                        });
                    } else {
                        errorNotification(t(el[err]));
                    }
                }
            });
        } else {
            errorNotification(t(errors[err]));
        }
    }
};
