import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { ListItem } from './chat/ListItem';
import { WhatsAppItem } from './whatsApp/WhatsAppItem';
import { TabBar } from './TabBar';

import {
    chat_list as chat_list_state,
    getChatListFirstRender,
    getChatListInBackground,
    chat_list_fetching as chat_list_fetching_state,
    chat_filter as chat_filter_state,
    getMessagesByBookingId,
    usersMessagesState,
    setUsersMessages,
    getWhatsAppTemplates,
    whatsAppTemplatesState,
    chatDateFilterState,
} from '../../redux/slices/chatSlice';

export const List = ({ setBookingId, bookingId, tab, tabBar, setTabBar }) => {
    const { hotel_id } = useParams();

    const refInterval = useRef(null);
    const refHotelId = useRef(hotel_id);

    const dispatch = useDispatch();
    const chat_list = useSelector(chat_list_state);
    const chat_list_fetching = useSelector(chat_list_fetching_state);
    const chat_filter = useSelector(chat_filter_state);
    const usersMessages = useSelector(usersMessagesState);
    const whatsAppTemplates = useSelector(whatsAppTemplatesState);
    const chatDateFilter = useSelector(chatDateFilterState);

    const { t } = useTranslation();

    useEffect(() => {
        if (tabBar === 'whatsapp') {
            const data = {
                hotel_id,
                search: chat_filter,
            };
            dispatch(getWhatsAppTemplates({ data }));
        }
        setBookingId('');
    }, [hotel_id, tabBar]);

    useEffect(() => {
        if (bookingId === '') {
            clearInterval(refInterval.current);
        }
    }, [chat_list_fetching, bookingId]);

    useEffect(() => {
        if (tab) {
            // если сменился отель или список сообщений пустой
            if (refHotelId.current !== hotel_id || !chat_list.length) {
                refHotelId.current = hotel_id;
                dispatch(setUsersMessages({}));
                dispatch(
                    getChatListFirstRender({
                        hotel_id: tab.hotel_id,
                        search_str: chat_filter,
                        search_date_from: chatDateFilter[0],
                        search_date_to: chatDateFilter[1],
                    })
                );
            } else {
                // если поменялся фильтр
                dispatch(
                    getChatListFirstRender({
                        hotel_id: tab.hotel_id,
                        search_str: chat_filter,
                        search_date_from: chatDateFilter[0],
                        search_date_to: chatDateFilter[1],
                    })
                );
            }
            // интервал обновления списка чатов
            const interval = setInterval(async () => {
                await dispatch(
                    getChatListInBackground({
                        hotel_id: tab.hotel_id,
                        search_str: chat_filter,
                        search_date_from: chatDateFilter[0],
                        search_date_to: chatDateFilter[1],
                    })
                );
            }, 10000);

            return () => {
                clearInterval(interval);
                clearInterval(refInterval.current);
            };
        }
    }, [tab, chat_filter, chatDateFilter]);

    const handlerGetMessages = (e, hotelId, bookingId) => {
        if (e.target.closest('.isOpenChatMenu') || e.target.closest('.contextOverlayClick') || e.target.closest('#modal-root')) {
            return;
        }

        clearInterval(refInterval.current);
        setBookingId(bookingId);

        const unreadCount = chat_list.find(el => el.booking_id === bookingId).unreadCount;
        if (!usersMessages[bookingId] || unreadCount > 0) {
            dispatch(getMessagesByBookingId({ hotelId, bookingId, bgFetch: false }));
        }

        refInterval.current = setInterval(() => {
            dispatch(getMessagesByBookingId({ hotelId, bookingId, bgFetch: true }));
        }, 5000);
    };

    return (
        <div className={`md:border-r-gray-10 h-full w-full shrink-0 overflow-auto md:w-1/3 md:border-r ${bookingId ? 'hidden md:block' : 'block'}`}>
            {tab?.whatsapp && <TabBar hotelId={hotel_id} setTab={setTabBar} tab={tabBar} />}
            {chat_list_fetching ? (
                <div className="flex h-full w-full items-center justify-center">
                    <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
                </div>
            ) : tabBar === 'chat' ? (
                chat_list.length > 0 ? (
                    chat_list.map(chat_item => (
                        <ListItem
                            chatItem={chat_item}
                            handlerGetMessages={handlerGetMessages}
                            bookingId={bookingId}
                            hotelId={hotel_id}
                            key={chat_item.booking_id}
                        />
                    ))
                ) : (
                    <div className="p-3 text-center">{t('CHAT_NO_CHATS')}</div>
                )
            ) : whatsAppTemplates.length > 0 ? (
                whatsAppTemplates.map(whatsAppItem => (
                    <WhatsAppItem chatItem={whatsAppItem} bookingId={bookingId} setBookingId={setBookingId} key={whatsAppItem.id} />
                ))
            ) : (
                <div className="p-3 text-center">{t('CHAT_NO_CHATS')}</div>
            )}
        </div>
    );
};
