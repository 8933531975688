import { getIcon } from '../../../../api/functions';

export const SidebarMenuItemLinkBlank = ({ title, icon, link }) => {
    return (
        <li>
            <a href={link} target="_blank" className="block py-2 pl-6 pr-3 transition-colors hover:text-blue-500">
                <div className="flex truncate text-base">
                    <span className={`shrink-0 ${icon ? 'mr-2' : ''}`}>{getIcon(icon)}</span>
                    <span className={`truncate`}>{title}</span>
                </div>
            </a>
        </li>
    );
};
