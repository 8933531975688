import { useState } from 'react';

import { ContentWrapper } from '../../common/ContentWrapper';
import { Header } from '../Header';
import { List } from './List';
import { Detail } from './Detail';

export const ServicesSection = ({ category, setEditCategory }) => {
    const [item, setItem] = useState(null);

    return (
        <>
            <Header category={category} setItem={setItem} setEditCategory={setEditCategory} />
            <ContentWrapper>
                <List category={category} setItem={setItem} />
                {item && <Detail item={item} setItem={setItem} hotelId={category.hotel_id} />}
            </ContentWrapper>
        </>
    );
};
