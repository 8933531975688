import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from '../common/ContentWrapper';
import { Header } from './Header';
import TemplatePic_1 from '../../source/images/template_info/template_info.jpg';
import TemplatePic_2 from '../../source/images/template_info/template_info_1.jpeg';
import TemplatePic_3 from '../../source/images/no-personal-message.png';

export const TemplatePage = ({ tab_info }) => {
    const { t } = useTranslation();
    return (
        <>
            <Header tab={tab_info} />

            <ContentWrapper>
                <div className="">
                    {/* Title */}
                    <div className="flex w-full items-center border-b bg-white">
                        <p className="pb-6 pl-6 pt-6 font-medium">{t('INFO_CHOOSE_TEMPLATE')}</p>
                        <div className="ml-auto mr-6" data-tip={t('INFO_CHOOSE_TEMPLATE_1')}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#1890FF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>

                    {/* Templates */}
                    <div className="w-full bg-white">
                        <ul className="flex h-full flex-col items-center p-6 md:flex-row md:items-stretch md:justify-around">
                            <li className="mb-6 w-72 pb-2 drop-shadow md:mr-4">
                                <div>
                                    <img src={TemplatePic_1} alt="template" className="h-full w-72" />
                                </div>
                                <NavLink
                                    to={`/object/${tab_info.hotel_id}/information/template`}
                                    className="ml-auto mr-auto mt-3 block h-10 w-44 cursor-pointer rounded-sm bg-blue-500 px-14 pb-2 pt-2 text-center text-sm font-medium text-white"
                                >
                                    {t('INFO_CHOOSE')}
                                </NavLink>
                            </li>

                            <li className="mb-6 w-72 pb-2 drop-shadow md:mr-4 ">
                                <div>
                                    <img src={TemplatePic_2} alt="template" className="h-full w-72" />
                                </div>
                                <NavLink
                                    to={`/object/${tab_info.hotel_id}/information/template_info_3`}
                                    className="ml-auto mr-auto mt-3 block h-10 w-44 cursor-pointer rounded-sm bg-blue-500 px-14 pb-2 pt-2 text-center text-sm font-medium text-white"
                                >
                                    {t('INFO_CHOOSE')}
                                </NavLink>
                            </li>
                            <li className="mb-6 flex h-auto w-72 flex-col justify-between pb-2 drop-shadow">
                                <div className="mb-auto mt-auto flex flex-col items-center">
                                    <img src={TemplatePic_3} alt="template" className="w-[70%]" />

                                    <span className="mt-3 text-center">{t('INFO_APARTMENT_4')}</span>
                                </div>
                                <NavLink
                                    to={`/object/${tab_info.hotel_id}/information/template_info_2`}
                                    className="ml-auto mr-auto mt-3 block h-10 w-44 cursor-pointer rounded-sm bg-blue-500 px-14 pb-2 pt-2 text-sm font-medium text-white"
                                >
                                    {t('INFO_CHOOSE')}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </ContentWrapper>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
