export const MeetingRoomColumn = ({ tableWidth, selectMeetingRooms, selectedDate, meetingRoom, getCurrentWorkTime }) => (
    <div className="min-w-36 shrink-0 bg-[#F8F8F8]" style={{ width: (tableWidth - 56) / selectMeetingRooms.filter(el => el.selected === '1').length }}>
        <div className=" sticky top-0 z-[1] h-6 border-b border-r bg-white text-xs ">
            <p className="h-6 overflow-hidden text-ellipsis pt-1 text-center">{meetingRoom.label}</p>
        </div>
        {new Array(24).fill(0).map((_, idx) => {
            return (
                <div className="h-20 border-b border-r border-b-[#e0e1e0] text-xs" key={`${idx}_meeting`}>
                    <div
                        onClick={e => getCurrentWorkTime(selectedDate, meetingRoom, e, idx)}
                        className={`h-10  transition-colors  ${
                            meetingRoom.start <= idx && meetingRoom.end > idx ? 'cursor-pointer hover:rounded hover:bg-slate-200' : 'bg-gray-200/60'
                        }`}
                        data-time={`${idx}:00`}
                    ></div>
                    <div
                        onClick={e => getCurrentWorkTime(selectedDate, meetingRoom, e, idx)}
                        className={`h-10 border-b transition-colors  ${
                            meetingRoom.start <= idx && meetingRoom.end > idx ? 'cursor-pointer hover:rounded hover:bg-slate-200' : 'bg-gray-200/60'
                        }`}
                        data-time={`${idx}:30`}
                    ></div>
                </div>
            );
        })}
    </div>
);
