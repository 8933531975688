import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { Icons } from './templatesPages/templatePart/Icons';
import { Photo } from './templatesPages/templatePart/Photo';
import { Activity } from './templatesPages/templatePart/Activity';
import { Sort } from './templatesPages/templatePart/Sort';
import { CustomReactQuill } from '../common/CustomReactQuill';
import { getUpdateString, successNotification, errorNotification } from '../../api/functions';
import {
    editInformation,
    set_update_info_fetch,
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
    updateInfoFetch as updateInfoFetch_state,
    information_icons as information_icons_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { HotelServicesAccord } from './HotelServicesAccord';

const ServicesSchema = Yup.object().shape({
    name: Yup.string().min(2, 'INFO_ERROR_11').required('INFO_ERROR_13'),
    name_en: Yup.string().min(2, 'INFO_ERROR_14').required('INFO_ERROR_16'),
    preview_description: Yup.string().nullable().min(2, 'INFO_ERROR_35').max(35, 'INFO_ERROR_35_1'),
    preview_description_en: Yup.string().nullable().min(2, 'INFO_ERROR_36').max(35, 'INFO_ERROR_36_1'),
    preview_description2: Yup.string().nullable().min(2, 'INFO_ERROR_37').max(35, 'INFO_ERROR_38'),
    preview_description2_en: Yup.string().nullable().min(2, 'INFO_ERROR_39').max(35, 'INFO_ERROR_40'),
    detail_description: Yup.string().nullable().min(12, 'INFO_ERROR_18').required('INFO_ERROR_17'),
    detail_description_en: Yup.string().nullable().min(12, 'INFO_ERROR_42').required('INFO_ERROR_17'),
    sort: Yup.number().typeError('INFO_ERROR_7').positive('INFO_ERROR_8').max(9999, 'INFO_ERROR_83'),
    files: Yup.array().min(1, 'INFO_ERROR_9').required('INFO_ERROR_9'),
    svg_current: Yup.string().required('INFO_ERROR_10'),
});

export const HotelServices = ({ tab_info }) => {
    const [initValues, setInitValues] = useState({
        name: '',
        name_en: '',
        detail_description: '',
        detail_description_en: '',
        preview_description: '',
        preview_description_en: '',
        preview_description2: '',
        preview_description2_en: '',
        sort: '',
        files: [],
        active: null,
    });
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [template, setTemplate] = useState(null);
    const [jsonInfo, setJsonInfo] = useState(null);

    const [idSvg, setIdSvg] = useState('');
    const [currentSvg, setCurrentSvg] = useState(null);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const information_icons = useSelector(information_icons_state);

    const { t } = useTranslation();

    useEffect(() => {
        if (response_status) {
            successNotification(t('SAVE_INFORMATION'));
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        const tab = information_tabs?.find(el => el.id === tab_info.id);
        if (tab) {
            const json = tab.json ? JSON.parse(tab.json) : null;
            json ? setTemplate('templ3') : setTemplate('templ1');
            setJsonInfo(json);
            setInitValues({
                name: tab.name,
                name_en: tab.name_en,
                detail_description: tab.detail_description,
                detail_description_en: tab.detail_description_en,
                preview_description: tab.preview_description ? tab.preview_description : '',
                preview_description_en: tab.preview_description_en ? tab.preview_description_en : '',
                preview_description2: tab.preview_description2 ? tab.preview_description2 : '',
                preview_description2_en: tab.preview_description2_en ? tab.preview_description2_en : '',
                files: tab.files2 ? tab.files2 : [],
                active: tab.active === '1' ? ['active'] : [],
                sort: tab.sort,
                svg_current: tab.svg_current,
                address: tab.room_number ?? null,
            });
            setCurrentSvg(tab.svg_current);
            setIdSvg('');
        }
    }, [tab_info]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        dispatch(set_update_info_fetch(true));

        let status;
        const detail_en = await getUpdateString(values.detail_description_en, tab_info, access_token);
        const detail_ru = await getUpdateString(values.detail_description, tab_info, access_token);

        if (detail_ru.length > 10000 || detail_en.length > 10000) {
            dispatch(set_update_info_fetch(false));
            return errorNotification(t('INFO_PICSLOADER_ERROR_5'));
        }

        const data = {
            ...values,
            detail_description: detail_ru,
            detail_description_en: detail_en,
            tab_item_id: tab_info.id,
            hotel_id: tab_info.hotel_id,
            active: values.active[0] === 'active' ? '1' : '0',
            section_link: tab_info.section_link,
        };
        status = await dispatch(editInformation({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm();
            setIdSvg('');
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const handleSvgClick = (e, setFieldValue) => {
        const ev = e.target.closest('div').dataset.svg;
        if (ev) {
            const icon = information_icons.find(el => el.id === Number(ev));
            setIdSvg(Number(ev));
            setFieldValue('svg_current', icon.icon);
        }
    };

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <Header tab={tab_info} />

            {template === 'templ1' ? (
                <ContentWrapper>
                    <Formik initialValues={initValues} validationSchema={ServicesSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                        {({ values, errors, isValidating, isSubmitting, dirty, setFieldValue }) => {
                            if (isSubmitting && !isValidating) {
                                for (const err in errors) {
                                    errorNotification(t(errors[err]));
                                }
                            }
                            return (
                                <Form>
                                    <div className="sm:flex ">
                                        {/* main info */}
                                        <div className=" bg-white sm:w-1/2">
                                            <div className="flex items-center border-b">
                                                <p className="pb-6 pl-6 pt-6 font-medium">{t('MAIN_INFO')}</p>
                                                <div className="ml-auto mr-6" data-tip data-for="address">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path
                                                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                            stroke="#1890FF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path
                                                            d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                            stroke="#1890FF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>

                                            {/* name */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME')}</p>
                                                <Field
                                                    name="name"
                                                    component="textarea"
                                                    value={values.name}
                                                    placeholder={t('INFO_SECTION_NAME')}
                                                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* name EN */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME_EN')}</p>
                                                <Field
                                                    name="name_en"
                                                    component="textarea"
                                                    value={values.name_en}
                                                    placeholder={t('INFO_SECTION_NAME_EN')}
                                                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* working hours */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_WORK_TIME')}</p>
                                                <Field
                                                    name="preview_description"
                                                    component="textarea"
                                                    value={values.preview_description}
                                                    placeholder={t('INFO_WORK_TIME')}
                                                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* working hours EN */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_WORK_TIME_EN')}</p>
                                                <Field
                                                    name="preview_description_en"
                                                    component="textarea"
                                                    value={values.preview_description_en}
                                                    placeholder={t('INFO_WORK_TIME_EN')}
                                                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* location */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_LOCATION')}</p>
                                                <Field
                                                    name="preview_description2"
                                                    component="textarea"
                                                    value={values.preview_description2}
                                                    placeholder="Локация"
                                                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* location en */}
                                            <div className="mt-3 pl-6 pr-6 ">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_LOCATION_EN')}</p>
                                                <Field
                                                    name="preview_description2_en"
                                                    component="textarea"
                                                    value={values.preview_description2_en}
                                                    placeholder={t('INFO_LOCATION_EN')}
                                                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            {/* description */}
                                            <div className="mt-3 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION')}</p>
                                                <Field
                                                    name="detail_description"
                                                    value={values.detail_description}
                                                    className="border-gray-10 box-border h-24 w-full  rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                >
                                                    {({ field, imageHandler }) => (
                                                        <CustomReactQuill
                                                            imageHandler={imageHandler}
                                                            value={field.value}
                                                            onChange={field.onChange(field.name)}
                                                            placeholder={t('DESCRIPTION')}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            {/* description EN */}
                                            <div className="mt-3 pb-6 pl-6 pr-6">
                                                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION_EN')}</p>
                                                <Field
                                                    name="detail_description_en"
                                                    value={values.detail_description_en}
                                                    className="border-gray-10 box-border h-24 w-full rounded p-1 pl-2 text-sm"
                                                    disabled={isSubmitting}
                                                >
                                                    {({ field, imageHandler }) => (
                                                        <CustomReactQuill
                                                            imageHandler={imageHandler}
                                                            value={field.value}
                                                            onChange={field.onChange(field.name)}
                                                            placeholder={t('DESCRIPTION_EN')}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="mt-3 bg-white pb-6 sm:ml-6 sm:mt-0 sm:w-1/2">
                                            {/* photo */}
                                            <Photo setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />

                                            {/* activity */}
                                            <div className=" mt-6 border-b border-t">
                                                <Activity />
                                            </div>

                                            {/* sort */}
                                            <div className="flex items-center border-b ">
                                                <Sort values={values} isSubmitting={isSubmitting} />
                                            </div>

                                            {/* icon */}
                                            <div>
                                                <Icons
                                                    dataSvg={information_icons}
                                                    handleSvgClick={handleSvgClick}
                                                    idSvg={idSvg}
                                                    setFieldValue={setFieldValue}
                                                    currentSvg={currentSvg}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        className={` mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                                            dirty ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                        } `}
                                        disabled={!dirty || updateInfoFetch}
                                    >
                                        {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                    </button>
                                </Form>
                            );
                        }}
                    </Formik>
                    <ReactTooltip
                        id="address"
                        aria-haspopup="true"
                        textColor="#FCFCFC"
                        backgroundColor="#8c8d9d"
                        effect="solid"
                        className="!rounded-md !px-2.5 !py-2"
                    >
                        {initValues.address ? (
                            <div>
                                <h2>Адреса квартир:</h2>
                                {initValues.address.split(',').map((address, id) => (
                                    <div key={id} className="mb-1">
                                        {address}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            t('INFORMATION')
                        )}
                    </ReactTooltip>
                </ContentWrapper>
            ) : (
                <HotelServicesAccord tab_info={tab_info} initValuesProp={initValues} jsonInfo={jsonInfo} />
            )}
        </>
    );
};
