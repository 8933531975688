import ReactTooltip from 'react-tooltip';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { CustomReactQuill } from '../common/CustomReactQuill';

export const MessageForm = ({ initialValues }) => {
    const { t } = useTranslation();
    return (
        <div className="mt-6 bg-white">
            <div className="flex items-center border-b">
                <p className="pb-6 pl-6 pt-6 font-medium">{t('NOTIFICATION_ALL_MESSAGE')}</p>
                <div className="ml-auto mr-6" data-tip={t('NOTIFICATION_TOOLTIP_2')}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#1890FF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <div className="p-6">
                <Field name="message" value={initialValues.message} placeholder="" className="border-gray-10 box-border h-80 w-full rounded p-1 pl-2 text-sm">
                    {({ field }) => (
                        <CustomReactQuill value={field.value} onChange={field.onChange(field.name)} placeholder={t('ENTER_MESSAGE')} disabledBtn={false} />
                    )}
                </Field>
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </div>
    );
};
