export const SubHeader = ({ children, hiddenInMobile = false }) => {
    return (
        <div
            className={`border-gray-10 w-full shrink-0 gap-4 border-b bg-white py-4 md:h-20 md:py-0 ${
                hiddenInMobile ? 'hidden md:flex' : 'flex'
            } flex-col justify-center px-4 sm:px-6 md:flex-row md:items-center md:justify-between lg:px-8`}
        >
            {children}
        </div>
    );
};
