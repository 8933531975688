import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SearchInput } from './SearchInput';
import { Table } from './Table';

import { getLaundryHistory, laundryHistoryListState, setLaundryHistory, setLaundryListFetch } from '../../../../redux/slices/informationSlice';

const LaundryHistoryComponent = ({ hotelId }) => {
    const dispatch = useDispatch();
    const laundryHistoryList = useSelector(laundryHistoryListState);

    const [roomNumber, setRoomNumber] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        let timeOutId;
        if (hotelId) {
            if (roomNumber.trim()) {
                dispatch(setLaundryListFetch(true));
                timeOutId = setTimeout(() => {
                    dispatch(getLaundryHistory({ hotelId, roomNumber }));
                }, 1000);
            } else {
                dispatch(setLaundryHistory(null));
                dispatch(setLaundryListFetch(false));
            }
        }
        return () => {
            clearTimeout(timeOutId);
        };
    }, [roomNumber]);

    return (
        <div className="mt-6">
            <p className="text-center">{t('INFO_LAUNDRY_HISTORY')}</p>
            <SearchInput text={roomNumber} setText={setRoomNumber} />
            <Table historyList={laundryHistoryList} />
        </div>
    );
};
export const LaundryHistory = memo(LaundryHistoryComponent);
