import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';

import { login as login_state, first_render_fetching as first_render_fetching_state } from '../../redux/slices/loginSlice';

export const RequireAuth = () => {
    const first_render_fetching = useSelector(first_render_fetching_state);
    const email = useSelector(login_state);

    if (first_render_fetching) {
        return (
            <div className="mt-8 flex w-full justify-center">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    if (!email) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};
