import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './slices/loginSlice';
import hotelReducer from './slices/hotelSlice';
import chatReducer from './slices/chatSlice';
import loyaltyReducer from './slices/loyaltySlice';
import informationReducer from './slices/informationSlice';
import foodReducer from './slices/foodSlice';
import merchReducer from './slices/merchSlice';
import shopReducer from './slices/shopSlice';
import meetingRoomReducer from './slices/meetingRoomSlice';
import coworkingReducer from './slices/coworkingSlice';
import blackListReducer from './slices/blackListSlice';

export const store = configureStore({
    reducer: {
        login: loginReducer,
        hotel: hotelReducer,
        chat: chatReducer,
        loyalty: loyaltyReducer,
        information: informationReducer,
        food: foodReducer,
        merch: merchReducer,
        shop: shopReducer,
        meetingRoom: meetingRoomReducer,
        coworking: coworkingReducer,
        blackList: blackListReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppGetState = typeof store.getState;
