import { useFormikContext, useField } from 'formik';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

export const CustomDatePicker = props => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <DatePicker
            autoComplete="off"
            disabledKeyboardNavigation
            dateFormat="dd MMMM yyyy"
            fixedHeight
            locale={ru}
            {...field}
            {...props}
            onChange={val => {
                setFieldValue(field.name, val);
                setFieldValue('start', '');
                setFieldValue('end', '');
            }}
        />
    );
};
