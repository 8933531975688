import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { RotatingLines } from 'react-loader-spinner';
import { errorNotification, successNotification } from '../../../api/functions';
import { addTariff, tariffsState, updateTariff } from '../../../redux/slices/coworkingSlice';

const initState = {
    name: '',
    days_count: '',
    description: '',
    price: '',
    description_2: '',
    category: '',
    additional: [],
    id: '',
};

const schema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Поле "Название тарифа" слишком короткое')
        .max(255, 'Поле "Название тарифа" слишком длинное')
        .required('Поле "Название тарифа" обязательное'),
    days_count: Yup.string()
        .min(1, 'Поле "Кол-во дней в тарифе" слишком короткое')
        .max(255, 'Поле "Кол-во дней в тарифе" слишком длинное')
        .required('Поле "Кол-во дней в тарифе" обязательное'),
    description: Yup.string().min(2, 'Поле "Описание" слишком короткое').max(255, 'Поле "Описание" слишком длинное').required('Поле "Описание" обязательное'),
    price: Yup.number().min(1, 'Поле "Цена" слишком короткое').required('Поле "Цена" обязательное'),
    category: Yup.string().required('Поле "Категория" обязательное'),
    additional: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required('Поле "Название условия тарифа" обязательное'),
                show: Yup.number(),
                id: Yup.string(),
            })
        )
        .min(1, 'Добавьте хотя бы одно условие тарифа'),
});

export const Detail = ({ tab, detailId, setDetailId }) => {
    const formikRef = useRef();
    const dispatch = useDispatch();

    const tariffs = useSelector(tariffsState);

    const [initialState, setInitialState] = useState(initState);

    useEffect(() => {
        if (detailId && detailId !== 'new') {
            const tariff = tariffs.find(item => item.id === detailId);

            setInitialState({
                name: tariff.name,
                days_count: tariff.days_count,
                description: tariff.description,
                price: tariff.price,
                description_2: tariff.description_2,
                category: tariff.category,
                additional: tariff.additional,
                id: tariff.id,
            });
        }

        return () => {
            setInitialState(initState);
        };
    }, [detailId]);

    const closeHandler = resetForm => {
        setDetailId(null);
        resetForm();
        setInitialState(initState);
    };

    const onSubmitHandler = async (values, resetForm) => {
        let result;
        if (detailId === 'new') {
            const { id, ...filteredValues } = values;
            result = await dispatch(addTariff({ data: { ...filteredValues, additional: JSON.stringify(values.additional), hotel_id: tab.hotel_id } }));
        } else {
            result = await dispatch(updateTariff({ data: { ...values, additional: JSON.stringify(values.additional), hotel_id: tab.hotel_id } }));
        }

        if (result.error) {
            errorNotification(result.message);
        } else {
            successNotification(result.message);

            closeHandler(resetForm);
        }
    };

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ values, errors, isValidating, isSubmitting, setFieldValue, resetForm }) => {
                if (isSubmitting && !isValidating) {
                    for (const error in errors) {
                        if (Array.isArray(errors[error])) {
                            errors[error].forEach(err => {
                                if (err && err.name) {
                                    errorNotification(err.name.toString());
                                }
                            });
                        } else {
                            errorNotification(errors[error].toString());
                        }
                    }
                }

                return (
                    <Form className="fixed inset-0 z-40 flex items-center justify-center bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200">
                        <div className="flex max-h-[97vh] w-[770px] max-w-full flex-col overflow-auto bg-white p-6">
                            <div className="mb-6 flex w-full flex-row border border-gray-300">
                                <div className="flex w-full flex-row flex-wrap content-start justify-between p-4">
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="name" className="mb-2 truncate text-base font-semibold text-black">
                                            Название тарифа
                                        </label>
                                        <Field id="name" name="name" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="days_count" className="mb-2 truncate text-base font-semibold text-black">
                                            Кол-во дней в тарифе
                                        </label>
                                        <Field id="days_count" name="days_count" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="description" className="mb-2 truncate text-base font-semibold text-black">
                                            Описание
                                        </label>
                                        <Field id="description" name="description" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="price" className="mb-2 truncate text-base font-semibold text-black">
                                            Цена
                                        </label>
                                        <Field
                                            id="price"
                                            name="price"
                                            type="text"
                                            className="border border-gray-300 p-2"
                                            onChange={async e => {
                                                const { value } = e.target;

                                                let formattedValue = value.trim().replace(/[^0-9,]/g, '');

                                                if (formattedValue[0] === ',') {
                                                    formattedValue = `0${formattedValue}`;
                                                }

                                                if (formattedValue[0] === '0' && formattedValue[1] === '0') {
                                                    formattedValue = formattedValue[0] + formattedValue.slice(2, formattedValue.length);
                                                }

                                                if (formattedValue.indexOf(',') >= 0 && formattedValue.indexOf(',', formattedValue.indexOf(',') + 1) >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',', formattedValue.indexOf(',') + 1));
                                                }

                                                if (formattedValue.indexOf(',') >= 0) {
                                                    formattedValue = formattedValue.slice(0, formattedValue.indexOf(',') + 3);
                                                }

                                                await setFieldValue('price', formattedValue);
                                            }}
                                        />
                                    </div>

                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="description_2" className="mb-2 truncate text-base font-semibold text-black">
                                            Пояснение для цены
                                        </label>
                                        <Field id="description_2" name="description_2" type="text" className="border border-gray-300 p-2" />
                                    </div>

                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="category" className="mb-2 truncate text-base font-semibold text-black">
                                            Категория
                                        </label>
                                        <Field as="select" name="category" className="border-gray-300">
                                            <option value="" disabled>
                                                Не выбрано
                                            </option>
                                            <option value="1">Место в open space</option>
                                            <option value="2">Shared офисы</option>
                                        </Field>
                                    </div>

                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="additional" className="mb-2 truncate text-base font-semibold text-black">
                                            Условия тарифа
                                        </label>
                                        <div id="additional" className="flex flex-col gap-4">
                                            {values.additional &&
                                                values.additional.map((condition, index) => (
                                                    <div className="flex flex-row items-center gap-4" key={condition.id}>
                                                        <Field
                                                            name={`condition-${index}`}
                                                            type="text"
                                                            className="w-full border border-gray-300 p-2"
                                                            placeholder="Например: Бесплатный Wi-Fi"
                                                            defaultValue={condition.name}
                                                            onChange={async e => {
                                                                const { value } = e.target;

                                                                await setFieldValue(
                                                                    'additional',
                                                                    values.additional.map(item => {
                                                                        if (item.id === condition.id) {
                                                                            return { ...condition, name: value };
                                                                        }

                                                                        return item;
                                                                    })
                                                                );
                                                            }}
                                                        />

                                                        <Field
                                                            as="select"
                                                            name={`condition-show-${index}`}
                                                            defaultValue={condition.show}
                                                            className="shrink-0 border-gray-300"
                                                            onChange={async e => {
                                                                const { value } = e.target;

                                                                await setFieldValue(
                                                                    'additional',
                                                                    values.additional.map(item => {
                                                                        if (item.id === condition.id) {
                                                                            return { ...condition, show: value };
                                                                        }

                                                                        return item;
                                                                    })
                                                                );
                                                            }}
                                                        >
                                                            <option value="1">Есть в тарифе</option>
                                                            <option value="0">Нет в тарифе</option>
                                                        </Field>

                                                        <button
                                                            className="flex h-full w-8 shrink-0 items-center justify-center"
                                                            onClick={async () => {
                                                                await setFieldValue(
                                                                    'additional',
                                                                    values.additional.filter(item => item.id !== condition.id)
                                                                );
                                                            }}
                                                        >
                                                            <svg viewBox="0 0 512 512" className="h-fit w-5">
                                                                <path
                                                                    fill="#fc0005"
                                                                    fillRule="evenodd"
                                                                    d="M170.8 14.221A14.21 14.21 0 0 1 185 .014L326.991.006a14.233 14.233 0 0 1 14.2 14.223v35.117H170.8zm233.461 477.443a21.75 21.75 0 0 1-21.856 20.33H127.954a21.968 21.968 0 0 1-21.854-20.416L84.326 173.06H427.5l-23.234 318.6zm56.568-347.452H51.171v-33A33.035 33.035 0 0 1 84.176 78.2l343.644-.011a33.051 33.051 0 0 1 33 33.02v33zm-270.79 291.851a14.422 14.422 0 1 0 28.844 0V233.816a14.42 14.42 0 0 0-28.839-.01v202.257zm102.9 0a14.424 14.424 0 1 0 28.848 0V233.816a14.422 14.422 0 0 0-28.843-.01z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ))}

                                            <button
                                                type="button"
                                                className="h-10 w-full rounded-sm border border-sky-500 text-sm font-normal text-sky-500 hover:bg-gray-50"
                                                onClick={async () => {
                                                    await setFieldValue('additional', [
                                                        ...values.additional,
                                                        { name: '', show: 0, id: parseInt(Date.now() * Math.random()) },
                                                    ]);
                                                }}
                                            >
                                                Добавить условие тарифа
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" mr-4 h-10 bg-transparent px-6 text-gray-700 transition-colors hover:text-black"
                                    onClick={() => closeHandler(resetForm)}
                                    disabled={isSubmitting}
                                >
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    className={`flex h-10 w-[135px] items-center justify-center px-6 text-white ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : 'Сохранить'}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
