import { root, rootShop } from './config';

export const api = Object.freeze({
    //auth
    login: {
        fetch: (login, password) => {
            const formData = new FormData();
            formData.append('email', login);
            formData.append('password', password);

            return fetch(`${root}/login`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    checkAuth: {
        fetch: access_token => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            return fetch(`${root}/login`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    refreshToken: {
        fetch: refresh_token => {
            const formData = new FormData();
            formData.append('refresh_token', refresh_token);

            return fetch(`${root}/login`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //hotels
    getHotelList: {
        fetch: access_token => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('tree_view', '1');
            formData.append('show_all', '1');

            return fetch(`${root}/getapptabs`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    getHotelTab: {
        fetch: (access_token, hotel_id, tab_id) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);
            formData.append('tab_id', tab_id);

            return fetch(`${root}/gettabitem`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // merch
    editMerchItem: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                } else {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/setclothesitem`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    addMerchItem: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                } else if (key !== 'id') {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/addclothesitem`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    sortMerch: {
        fetch: (access_token, sort_arr) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            formData.append('ids_arr', sort_arr);

            return fetch(`${root}/setclothessort`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //food
    getFood: {
        fetch: (access_token, hotel_id) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            return fetch(`${root}/getfoodlistbycategory&all=1`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    editFoodItem: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                } else {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/setfooditem`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    addFoodItem: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                } else if (key !== 'id') {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/addfooditem`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    sortFood: {
        fetch: (access_token, sort_arr) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            formData.append('ids_arr', sort_arr);

            return fetch(`${root}/setfoodsort`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    addMinOrderAmount: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('type', 'food_min_price');

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/setmarketmin`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //chat
    getChatList: {
        fetch: (access_token, hotel_id, search_str, search_date_from, search_date_to) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);
            formData.append('search_str', search_str);
            formData.append('search_date_from', search_date_from);
            formData.append('search_date_to', search_date_to);

            return fetch(`${root}/getchatroomlist`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    sendMessage: {
        fetch: (access_token, booking_id, text, file) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('booking_id', booking_id);
            formData.append('text', text);
            formData.append('file', file);
            formData.append('json_result', 1);

            return fetch(`${root}/sendadminmessage`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    setPushToken: {
        fetch: (access_token, push_token) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('push_token', push_token);

            return fetch(`${root}/sendadmintoken`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    setMessagesAsRead: {
        fetch: (access_token, booking_id) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('booking_id', booking_id);

            return fetch(`${root}/markmessarrread`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    getUnreadMessagesCount: {
        fetch: access_token => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            return fetch(`${root}/getunreadmsgcount`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    sendPushNotification: {
        fetch: (access_token, { hotel_id, title, body, login }) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);
            formData.append('title', title);
            formData.append('body', body);
            formData.append('login', login);

            return fetch(`${root}/pushto`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getMessages: {
        fetch: ({ access_token, hotelId, bookingId, oldMessageId, lastReadeId }) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotelId);
            formData.append('booking_id', bookingId);
            !oldMessageId && formData.append('last_id', lastReadeId);
            oldMessageId && formData.append('min_msg_id', oldMessageId);

            return fetch(`${root}/getmsglist`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //loyalty
    getUsersList: {
        fetch: (access_token, page, userFilter) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('page', page);
            formData.append('search_str', userFilter);

            return fetch(`${root}/getnightslist`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    editUser: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/changeguest`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // information
    getHotelInformation: {
        fetch: (access_token, hotel_id) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);
            formData.append('tree_view', '1');
            formData.append('show_all', '1');

            return fetch(`${root}/getapptabs`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    editInformationItem: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                } else {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/settabitem`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    updateLoyaltyListFetch: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', data.hotel_id);
            formData.append('loyalty_json', data.loyalty_json);

            return fetch(`${root}/updatehotelloyaltylist`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    updateManageInfoFetch: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', data.hotel_id);
            formData.append('manage_additional_html', JSON.stringify(data.manage_additional_html));

            return fetch(`${root}/updatehotelloyaltylist`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    uploadPhoto: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('id', data.id);

            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                }
            }

            return fetch(`${root}/addfileleonid`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    sortPhotoFetch: {
        fetch: (access_token, data, sort_arr) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('tab_item_id', data.tab_item_id);
            formData.append('sort_arr', sort_arr);

            return fetch(`${root}/change_tab_item_file_sort`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getHotelInfoPics: {
        fetch: (access_token, hotel_id) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            return fetch(`${root}/gethotelinfo_pic`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    addHotelInfoPics: {
        fetch: (access_token, hotel_id, files) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);
            files.forEach((item, index) => {
                formData.append(`files${index}`, item);
            });

            return fetch(`${root}/addhotelinfo_pic`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    deleteHotelInfoPics: {
        fetch: (access_token, file) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('delete_file_path', file);

            return fetch(`${root}/deletehotelinfo_pic`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // meeting rooms
    addMeetingRoom: {
        fetch: (access_token, hotel_id, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                } else {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/addmeeting`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    editMeetingRoom: {
        fetch: (access_token, hotel_id, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`${key}${index}`, item);
                    });
                } else {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/updatemeeting`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getMeetingRoomColors: {
        fetch: access_token => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            return fetch(`${root}/getcolors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    addMeetingRoomBooking: {
        fetch: (access_token, hotel_id, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/addmeetingbooking `, {
                method: 'POST',
                body: formData,
            });
        },
    },

    updateMeetingRoomBooking: {
        fetch: (access_token, hotel_id, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/updatemeetingbooking  `, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getAllMeetingRoomBooking: {
        fetch: (access_token, hotel_id, date_from) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);
            formData.append('date_from', date_from);

            return fetch(`${root}/getallmeetingbooking   `, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getBookingHistory: {
        fetch: (access_token, hotel_id, page) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);
            formData.append('page', page);

            return fetch(`${root}/getmeetinghistory`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // laundry
    getLaundryMachines: {
        fetch: (access_token, hotel_id) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            return fetch(`${root}/getallwashing`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    updateMachine: {
        fetch: (access_token, id, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('id', id);
            formData.append('params', JSON.stringify(data));

            return fetch(`${root}/updatemachine`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    machineControl: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/washingcontroll`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    createRoom: {
        fetch: (access_token, { hotel_id }) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotel_id);

            return fetch(`${root}/addroomcategory`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getLaundryHistory: {
        fetch: (access_token, hotelId, roomNumber) => {
            const formData = new FormData();
            formData.append('access_token', access_token);
            formData.append('hotel_id', hotelId);
            formData.append('room_number', roomNumber);

            return fetch(`${root}/getwashinglog`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // GrowUp
    // Список тарифов
    getTariffsList: {
        fetch: data => {
            const formData = new FormData();

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/gettariflist`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Обновить тариф
    updateTariff: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/updatetariff`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Добавить тариф
    addTariff: {
        fetch: (access_token, data) => {
            const formData = new FormData();
            formData.append('access_token', access_token);

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/addtariff`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //shop
    addShopItem: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            formData.append('object', 'shop_item');
            formData.append('hotel_id', data.hotel_id);

            const params = {};
            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`file${index}`, item);
                    });
                } else if (key !== 'id' && key !== 'hotel_id' && data[key]) {
                    params[key] = data[key];
                }
            }

            formData.append('params', JSON.stringify(params));

            return fetch(`${rootShop}/createitem`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    editShopItem: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            formData.append('object', 'shop_item');
            formData.append('id', data.id);

            const params = {};
            for (const key in data) {
                if (key === 'files') {
                    data[key].forEach((item, index) => {
                        formData.append(`file${index}`, item);
                    });
                } else if (key !== 'id' && key !== 'hotel_id') {
                    params[key] = data[key];
                }
            }

            formData.append('params', JSON.stringify(params));

            return fetch(`${rootShop}/update`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    sortShopItems: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);

            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${rootShop}/sortitems`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    addShopCategory: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            formData.append('object', 'shop');
            formData.append('hotel_id', data.hotel_id);

            const params = {};
            for (const key in data) {
                if (key !== 'id' && key !== 'hotel_id' && key !== 'shop_link' && data[key]) {
                    params[key] = data[key];
                }
            }

            formData.append('params', JSON.stringify(params));

            return fetch(`${rootShop}/createitem`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    editShopCategory: {
        fetch: (access_token, data) => {
            const formData = new FormData();

            formData.append('access_token', access_token);
            formData.append('object', 'shop');
            formData.append('id', data.id);

            const params = {};
            for (const key in data) {
                if (key !== 'id' && key !== 'hotel_id' && key !== 'shop_link') {
                    params[key] = data[key];
                }
            }

            formData.append('params', JSON.stringify(params));

            return fetch(`${rootShop}/update`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getIcons: {
        fetch: () => {
            return fetch(`${url}/?r=helper/icons`, {
                method: 'POST',
            });
        },
    },

    getHotelRoomNumbers: {
        fetch: (accessToken, hotelId) => {
            const formData = new FormData();

            formData.append('access_token', accessToken);
            formData.append('hotel_id', hotelId);

            return fetch(`${root}/getroomsbyhotelid`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    updatePersonalRoomMessage: {
        fetch: (accessToken, data) => {
            const formData = new FormData();

            formData.append('access_token', accessToken);
            formData.append('label', data.label);
            formData.append('text', data.message);
            formData.append('hotel_id', data.hotelId);
            formData.append('keyBoxCode', data.keyBoxCode);
            formData.append('id', data.id);
            formData.append('address', data.address);

            return fetch(`${root}/setroomtext`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // languages
    getAvailableLanguages: {
        fetch: () => {
            return fetch(`${root}/getlangs`);
        },
    },

    getLanguageJson: {
        fetch: langId => {
            const formData = new FormData();
            formData.append('langId', langId);

            return fetch(`${root}/getlangjson`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // reviews
    getReviews: {
        fetch: (accessToken, hotelId) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            formData.append('hotel_id', hotelId);

            return fetch(`${root}/getfeedbackbyhotelid`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    updateReview: {
        fetch: (accessToken, data) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            for (const key in data) {
                if (key === 'ru' || key === 'en') {
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }

            return fetch(`${root}/savefeedback`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    withholdDeposit: {
        fetch: (accessToken, data) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/bailbond`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    returnDeposit: {
        fetch: (accessToken, bookingId) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            formData.append('booking_id', bookingId);

            return fetch(`${root}/refunddeposit`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    returnPayment: {
        fetch: (accessToken, bookingId) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            formData.append('booking_id', bookingId);

            return fetch(`${root}/paymentrefund`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getBookingsForDepositTransfer: {
        fetch: (hotelId, accessToken, bookingId) => {
            const formData = new FormData();
            formData.append('hotel_id', hotelId);
            formData.append('access_token', accessToken);
            formData.append('booking_id', bookingId);

            return fetch(`${root}/getbookingsfordeposittransfer`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    transferDeposit: {
        fetch: (accessToken, data) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/transferdeposit`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getWhatsAppTemplates: {
        fetch: (accessToken, data) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            for (const key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${root}/getwhatsapptemplates`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    setReadTemplate: {
        fetch: (accessToken, bookingId) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);
            formData.append('booking_id', bookingId);

            return fetch(`${root}/setreadwhattemp`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    // оплаты для таблицы в наквартире
    getPaymentList: {
        fetch: (accessToken, data) => {
            const formData = new FormData();
            formData.append('access_token', accessToken);

            for (const key in data) {
                formData.append(key, data[key]);
            }
            return fetch(`${root}/getpayreport`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
