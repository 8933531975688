import { Field } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InitValuesList } from '../types/templateTypes';
type Props = {
    values: InitValuesList;
    isSubmitting: boolean;
};
export const WorkingHours: FC<Props> = ({ values, isSubmitting }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_WORK_TIME')}</p>
                <Field
                    name="preview_description"
                    component="textarea"
                    value={values.preview_description}
                    placeholder={t('INFO_WORK_TIME')}
                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                />
            </div>

            <div className="mt-3 pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_WORK_TIME_EN')}</p>
                <Field
                    name="preview_description_en"
                    component="textarea"
                    value={values.preview_description_en}
                    placeholder={t('INFO_WORK_TIME_EN')}
                    className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                />
            </div>
        </>
    );
};
