import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { errorNotification } from '../../api/functions';
import { addShopCategory, editShopCategory, shopState } from '../../redux/slices/shopSlice';

const initState = {
    name: '',
    name_en: '',
    type: '',
    id: '',
    active: '',
};

const SignupSchema = Yup.object().shape({
    name: Yup.string().min(2, 'INFO_ERROR_1').max(100, 'SHOP_ERROR').required('INFO_ERROR_3'),
    name_en: Yup.string().min(2, 'INFO_ERROR_4').max(100, 'SHOP_ERROR_1').required('INFO_ERROR_6'),
    type: Yup.string().required('INFO_ERROR_81'),
});

export const EditCategory = ({ category, setEditCategory, hotelId }) => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const navigate = useNavigate();

    const shop = useSelector(shopState);

    const [initialState, setInitialState] = useState(initState);
    const { hotel_id, category_id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        if (category && category.id !== 'new') {
            (async () => {
                setInitialState({
                    name: category.name || '',
                    name_en: category.name_en || '',
                    id: category.id,
                    type: category.type,
                    active: category.active === '1' ? true : false,
                });
            })();
        }
    }, [category]);

    const onSubmitHandler = async (values, resetForm) => {
        let res;

        if (category.id !== 'new') {
            res = await dispatch(
                editShopCategory({ data: { ...values, hotel_id: hotelId, shop_link: shop.link, active: values.active === true ? '1' : '0' } })
            );
        } else {
            res = await dispatch(addShopCategory({ data: { ...values, hotel_id: hotelId, shop_link: shop.link, active: values.active === true ? '1' : '0' } }));
        }

        if (res.status) {
            closeHandler(resetForm);
        } else {
            if (res.error) {
                errorNotification(res.error.toString());
            }
        }

        if (res.id) {
            navigate(`/object/${hotel_id}/shop_2/${res.id}/`);
        }
    };

    const closeHandler = resetForm => {
        setEditCategory(null);
        resetForm();
        setInitialState(initState);

        category_id !== 'new' ? navigate(`/object/${hotel_id}/shop_2/${category_id}/`) : navigate(`/`);
    };

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            validationSchema={SignupSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ errors, isValidating, isSubmitting, resetForm }) => {
                if (isSubmitting && !isValidating) {
                    for (const err in errors) {
                        errorNotification(t(errors[err]));
                    }
                }

                return (
                    <Form className="fixed inset-0 z-40 flex items-center justify-center bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200">
                        <div className="flex max-h-[97vh] w-[770px] max-w-full flex-col overflow-auto bg-white p-6">
                            <div className="mb-6 flex w-full flex-row border border-gray-300">
                                <div className="flex w-full flex-row flex-wrap content-start justify-between p-4">
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="name" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('NAME')}
                                        </label>
                                        <Field id="name" name="name" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="name_en" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('NAME_EN')}
                                        </label>
                                        <Field id="name_en" name="name_en" type="text" className="border border-gray-300 p-2" />
                                    </div>
                                    <div className="mb-5 flex w-full flex-col">
                                        <label htmlFor="type" className="mb-2 truncate text-base font-semibold text-black">
                                            {t('SHOP_CATEGORY')}
                                        </label>
                                        <Field as="select" name="type" className="border-gray-300">
                                            <option value="" disabled>
                                                {t('NO_CHOSEN')}
                                            </option>
                                            <option value="1">{t('SHOP')}</option>
                                            <option value="2">{t('SERVICES')}</option>
                                        </Field>
                                    </div>
                                    <div className="mb-5 flex w-full items-center">
                                        <label htmlFor="name_en" className="mr-2 truncate text-base font-semibold text-black">
                                            {t('INFO_SECTION_ACTIVITY')}
                                        </label>
                                        <Field id="active" name="active" type="checkbox" className="border border-gray-300 p-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" mr-4 h-10 bg-transparent px-6 text-gray-700 transition-colors hover:text-black"
                                    onClick={() => closeHandler(resetForm)}
                                >
                                    {t('CANCEL')}
                                </button>
                                <button
                                    type="submit"
                                    className={`flex h-10 w-[135px] items-center justify-center px-6 text-white ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
