export const SidebarBackdrop = ({ sidebarOpen }) => {
    return (
        <div
            className={`fixed inset-0 z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden ${
                sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
            }`}
            aria-hidden="true"
        ></div>
    );
};
