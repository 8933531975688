import { createSelector, createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';
import moment from 'moment-timezone';
import 'moment/locale/ru';

export const chatSlice = createSlice({
    name: 'chat',

    initialState: {
        chatList: [],
        usersMessages: {},
        userMessagesFetch: false,
        oldestMessagesFetch: false,
        chat_list_fetching: true,
        message_list_fetching: false,
        message_send_fetching: false,
        unread_messages_count: [],
        push_send_fetching: false,
        push_status: false,
        chat_filter: '',
        chatDateFilter: [null, null],
        depositFetching: false,
        whatsAppTemplates: [],
        bookingsForDepositTransfer: null,
    },

    reducers: {
        set_chat_list: (state, action) => {
            state.chatList = action.payload;
        },
        chat_list_fetching_start: state => {
            state.chat_list_fetching = true;
        },
        chat_list_fetching_end: state => {
            state.chat_list_fetching = false;
        },
        message_send_fetching_start: state => {
            state.message_send_fetching = true;
        },
        message_send_fetching_end: state => {
            state.message_send_fetching = false;
        },
        set_unread_messages_counter: (state, action) => {
            state.unread_messages_count = action.payload;
        },
        push_send_fetching_start: state => {
            state.push_send_fetching = true;
        },
        push_send_fetching_end: state => {
            state.push_send_fetching = false;
        },
        set_push_status: (state, action) => {
            state.push_status = action.payload;
        },
        set_chat_filter: (state, action) => {
            state.chat_filter = action.payload;
        },
        setUsersMessages: (state, action) => {
            state.usersMessages = action.payload;
        },
        setUserMessagesFetching: (state, action) => {
            state.userMessagesFetch = action.payload;
        },
        setUnreadCount: (state, action) => {
            state.chatList = state.chatList.map(chat => (chat.booking_id === action.payload ? { ...chat, unreadCount: 0 } : chat));
        },
        getOldestMessagesFetching: (state, action) => {
            state.oldestMessagesFetch = action.payload;
        },
        setUploadedOldestMessages: (state, action) => {
            state.usersMessages = {
                ...state.usersMessages,
                [action.payload.bookingId]: {
                    ...state.usersMessages[action.payload.bookingId],
                    messages: [...state.usersMessages[action.payload.bookingId].messages, ...action.payload.messages.reverse()],
                },
            };
        },
        updateDeposit: (state, action) => {
            state.chatList = state.chatList.map(chat =>
                chat.booking_id === action.payload.bookingId ? { ...chat, deposit: false, depositStatus: action.payload.status } : chat
            );
        },
        updatePayment: (state, action) => {
            state.chatList = state.chatList.map(chat => (chat.booking_id === action.payload ? { ...chat, payment: false, paymentStatus: 3 } : chat));
        },
        setDepositFetching: (state, action) => {
            state.depositFetching = action.payload;
        },
        setWhatsAppTemplates: (state, action) => {
            state.whatsAppTemplates = action.payload;
        },
        updateWhatsAppTemplates: (state, action) => {
            state.whatsAppTemplates = state.whatsAppTemplates.map(template =>
                template.booking_id === action.payload ? { ...template, is_admin_read: '1' } : template
            );
        },
        setChatDateFilter: (state, action) => {
            state.chatDateFilter = action.payload;
        },
        setBookingsForDepositTransfer: (state, action) => {
            state.bookingsForDepositTransfer = action.payload;
        },
    },
});

export const {
    setBookingsForDepositTransfer,
    setChatDateFilter,
    updatePayment,
    updateWhatsAppTemplates,
    setWhatsAppTemplates,
    setDepositFetching,
    updateDeposit,
    setUploadedOldestMessages,
    getOldestMessagesFetching,
    setUnreadCount,
    setUserMessagesFetching,
    set_chat_filter,
    set_push_status,
    push_send_fetching_start,
    push_send_fetching_end,
    set_chat_list,
    chat_list_fetching_start,
    chat_list_fetching_end,
    message_send_fetching_start,
    message_send_fetching_end,
    set_unread_messages_counter,
    setUsersMessages,
} = chatSlice.actions;

export const getChatList =
    ({ access_token, hotel_id, search_str, search_date_from, search_date_to }) =>
    async dispatch => {
        if (access_token && hotel_id) {
            const response = await api.getChatList.fetch(access_token, hotel_id, search_str, search_date_from, search_date_to);
            if (response.status === 200) {
                const res = await response.json();

                if (Array.isArray(res.result) && res.result.length) {
                    const result = res.result.map(el => ({
                        name: el.Name,
                        room_number: el.room_number,
                        booking_id: el.booking_id,
                        unreadCount: el.unread,
                        status_color: el.status_color,
                        date_from: el.date_from,
                        date_to: el.date_to,
                        podpislon: el.podpislon,
                        lockCode: el.lockcode,
                        phone: el.phone,
                        paymentStatus: el.paymentStatus,
                        depositStatus: el.depositStatus,
                        deposit: el.deposit,
                        payment: el.payment,
                        checkLink: el.check_link,
                        price: el.price,
                    }));

                    dispatch(set_chat_list(result));
                } else {
                    dispatch(set_chat_list([]));
                }
            } else if (response.status === 401) {
                dispatch(set_token_error(true));
            } else {
                dispatch(set_chat_list([]));
            }
        }
    };

export const getMessagesByBookingId =
    ({ hotelId, bookingId, bgFetch }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        const usersMessages = getState().chat.usersMessages;
        const oldestMessagesFetch = getState().chat.oldestMessagesFetch;
        let lastReadeId;
        if (!bgFetch) {
            dispatch(setUserMessagesFetching(true));
        }
        try {
            if (usersMessages.hasOwnProperty(bookingId)) {
                const lastReadMessage = usersMessages[bookingId].messages.find(message => message.is_user_read === '1');
                lastReadeId = lastReadMessage ? lastReadMessage.id : '';
            } else {
                lastReadeId = '';
            }
            const response = await api.getMessages.fetch({ access_token, hotelId, bookingId, lastReadeId });

            if (response.status === 200) {
                const result = await response.json();
                if (!oldestMessagesFetch) {
                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    if (usersMessages.hasOwnProperty(bookingId)) {
                        if (result.has_unread === '0') {
                            const allReadMessages = usersMessages[bookingId].messages.map(message =>
                                message.from_admin === '1' && message.is_user_read === '0' ? { ...message, is_user_read: '1' } : message
                            );
                            const newMessages = result.msg_arr.filter(item2 => {
                                return !usersMessages[bookingId].messages.some(item1 => item1.id === item2.id);
                            });
                            dispatch(
                                setUsersMessages({
                                    ...usersMessages,
                                    [bookingId]: {
                                        count: result.count,
                                        messages: [
                                            ...newMessages
                                                .map(message => ({
                                                    ...message,
                                                    date_add: moment.tz(message.date_add, 'Europe/Moscow').tz(timeZone).format('YYYY-MM-DD HH:mm:ss'),
                                                }))
                                                .reverse(),
                                            ...allReadMessages,
                                        ],
                                    },
                                })
                            );
                        } else {
                            const newMessages = result.msg_arr.filter(
                                newMessage => !usersMessages[bookingId].messages.some(message => message.id === newMessage.id)
                            );
                            if (newMessages.length) {
                                dispatch(
                                    setUsersMessages({
                                        ...usersMessages,
                                        [bookingId]: {
                                            count: result.count,
                                            messages: [
                                                ...newMessages
                                                    .map(message => ({
                                                        ...message,
                                                        date_add: moment.tz(message.date_add, 'Europe/Moscow').tz(timeZone).format('YYYY-MM-DD HH:mm:ss'),
                                                    }))
                                                    .reverse(),
                                                ...usersMessages[bookingId].messages,
                                            ],
                                        },
                                    })
                                );
                            }
                        }
                    } else {
                        dispatch(
                            setUsersMessages({
                                ...usersMessages,
                                [bookingId]: {
                                    count: result.count,
                                    messages: result.msg_arr
                                        .map(message => ({
                                            ...message,
                                            date_add: moment.tz(message.date_add, 'Europe/Moscow').tz(timeZone).format('YYYY-MM-DD HH:mm:ss'),
                                        }))
                                        .reverse(),
                                },
                            })
                        );
                    }
                }
            }
        } catch (e) {
            console.log('getMessagesByBookingId error: ', e);
        } finally {
            if (!bgFetch) {
                dispatch(setUserMessagesFetching(false));
            }
        }
    };

export const getOldestMessages =
    ({ hotelId, bookingId, oldMessageId }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;
        dispatch(getOldestMessagesFetching(true));

        try {
            const response = await api.getMessages.fetch({ access_token, hotelId, bookingId, oldMessageId });
            if (response.status === 200) {
                const result = await response.json();
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                dispatch(
                    setUploadedOldestMessages({
                        bookingId,
                        messages: result.msg_arr.map(message => ({
                            ...message,
                            date_add: moment.tz(message.date_add, 'Europe/Moscow').tz(timeZone).format('YYYY-MM-DD HH:mm:ss'),
                        })),
                    })
                );
            }
        } catch (e) {
            console.log('getOldestMessages error: ', e);
        } finally {
            setTimeout(() => {
                dispatch(getOldestMessagesFetching(false));
            }, 500);
        }
    };

export const getChatListFirstRender =
    ({ hotel_id, search_str, search_date_from, search_date_to }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        dispatch(chat_list_fetching_start());
        try {
            await dispatch(getChatList({ access_token, hotel_id, search_str, search_date_from, search_date_to }));
        } catch (e) {
            console.log('getChatList error: ', e);
        } finally {
            dispatch(chat_list_fetching_end());
        }
    };

export const getChatListInBackground =
    ({ hotel_id, search_str, search_date_from, search_date_to }) =>
    async (dispatch, getState) => {
        const access_token = getState().login.access_token;

        try {
            await dispatch(getChatList({ access_token, hotel_id, search_str, search_date_from, search_date_to }));
        } catch (e) {
            console.log('getChatListBackground error: ', e);
        }
    };

export const sendMessage =
    ({ access_token, text, booking_id, file }) =>
    async (dispatch, getState) => {
        dispatch(message_send_fetching_start());
        const usersMessages = getState().chat.usersMessages;

        try {
            if ((text || file) && booking_id) {
                const response = await api.sendMessage.fetch(access_token, booking_id, text, file);
                if (response.status === 200) {
                    const result = await response.json();
                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                    if (Object.keys(usersMessages).includes(booking_id)) {
                        dispatch(
                            setUsersMessages({
                                ...usersMessages,
                                [booking_id]: {
                                    ...usersMessages[booking_id],
                                    messages: [
                                        {
                                            ...result.result,
                                            date_add: moment.tz(result.result.date_add, 'Europe/Moscow').tz(timeZone).format('YYYY-MM-DD HH:mm:ss'),
                                        },
                                        ...usersMessages[booking_id].messages,
                                    ],
                                },
                            })
                        );
                    } else {
                        dispatch(
                            setUsersMessages({
                                ...usersMessages,
                                [booking_id]: {
                                    count: 1,
                                    messages: [
                                        {
                                            ...result.result,
                                            date_add: moment.tz(result.result.date_add, 'Europe/Moscow').tz(timeZone).format('YYYY-MM-DD HH:mm:ss'),
                                        },
                                    ],
                                },
                            })
                        );
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('sendMessage error: ', e);
        } finally {
            dispatch(message_send_fetching_end());
        }
    };

export const setPushToken =
    ({ access_token, push_token }) =>
    async dispatch => {
        try {
            if (access_token && push_token) {
                const response = await api.setPushToken.fetch(access_token, push_token);
                if (response.status === 200) {
                    // const res = await response.json();
                    // console.log('res', res);
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('setPushToken error: ', e);
        } finally {
        }
    };

export const setMessagesAsRead =
    ({ access_token, booking_id }) =>
    async dispatch => {
        try {
            if (access_token && booking_id) {
                const response = await api.setMessagesAsRead.fetch(access_token, booking_id);
                if (response.status === 200) {
                    dispatch(setUnreadCount(booking_id));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('setMessagesAsRead error: ', e);
        } finally {
        }
    };

export const getUnreadMessagesCount = () => async (dispatch, getState) => {
    const access_token = getState().login.access_token;
    try {
        if (access_token) {
            const response = await api.getUnreadMessagesCount.fetch(access_token);
            if (response.status === 200) {
                const res = await response.json();
                if (res.hasOwnProperty('result')) {
                    dispatch(set_unread_messages_counter(res.result));
                }
            } else if (response.status === 401) {
                dispatch(set_token_error(true));
            }
        }
    } catch (e) {
        console.log('getUnreadMessagesCount error: ', e);
    }
};

export const sendPushNotification =
    ({ access_token, data }) =>
    async dispatch => {
        dispatch(push_send_fetching_start());
        try {
            if (access_token) {
                const response = await api.sendPushNotification.fetch(access_token, data);
                if (response.status === 200) {
                    const res = await response.json();
                    dispatch(set_push_status(res.code));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
                return response.status;
            }
        } catch (e) {
            console.log('sendPushNotification error: ', e);
        } finally {
            dispatch(push_send_fetching_end());
        }
    };

export const withholdDeposit =
    ({ data }) =>
    async (dispatch, getState) => {
        dispatch(setDepositFetching(true));
        const access_token = getState().login.access_token;
        try {
            if (access_token) {
                const response = await api.withholdDeposit.fetch(access_token, data);
                if (response.status === 200) {
                    const result = await response.json();
                    if (result.status) {
                        dispatch(updateDeposit({ bookingId: data.booking_id, status: data.status }));
                        return true;
                    } else {
                        return `${result.error} ${result.sum ? `- ${result.sum}` : ''}`;
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                } else {
                    return 'Что-то пошло не так, обратитесь в поддержку';
                }
            }
        } catch (e) {
            console.log('withholdDeposit error: ', e);
        } finally {
            dispatch(setDepositFetching(false));
        }
    };

export const returnDeposit = bookingId => async (dispatch, getState) => {
    const access_token = getState().login.access_token;
    try {
        if (access_token) {
            const response = await api.returnDeposit.fetch(access_token, bookingId);
            if (response.status === 200) {
                const result = await response.json();
                if (result.status) {
                    dispatch(updateDeposit({ bookingId, status: 2 }));
                    return true;
                } else {
                    return result.error;
                }
            } else if (response.status === 401) {
                dispatch(set_token_error(true));
            } else {
                return 'Что-то пошло не так, обратитесь в поддержку';
            }
        }
    } catch (e) {
        console.log('returnDeposit error: ', e);
    }
};
export const returnPayment = bookingId => async (dispatch, getState) => {
    const access_token = getState().login.access_token;
    try {
        if (access_token) {
            const response = await api.returnPayment.fetch(access_token, bookingId);
            if (response.status === 200) {
                const result = await response.json();
                if (result.status) {
                    dispatch(updatePayment(bookingId));
                    return true;
                } else {
                    return result.error;
                }
            } else if (response.status === 401) {
                dispatch(set_token_error(true));
            } else {
                return 'Что-то пошло не так, обратитесь в поддержку';
            }
        }
    } catch (e) {
        console.log('returnPayment error: ', e);
    }
};

export const getWhatsAppTemplates =
    ({ data }) =>
    async (dispatch, getState) => {
        dispatch(chat_list_fetching_start());
        const access_token = getState().login.access_token;
        try {
            if (access_token) {
                const response = await api.getWhatsAppTemplates.fetch(access_token, data);
                if (response.status === 200) {
                    const result = await response.json();
                    dispatch(setWhatsAppTemplates(result));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                } else {
                    dispatch(setWhatsAppTemplates([]));
                }
            }
        } catch (e) {
            console.log('getWhatsAppTemplates error: ', e);
        } finally {
            dispatch(chat_list_fetching_end());
        }
    };

export const setReadTemplate = bookingId => async (dispatch, getState) => {
    const access_token = getState().login.access_token;
    try {
        if (access_token) {
            const response = await api.setReadTemplate.fetch(access_token, bookingId);
            if (response.status === 200) {
                const result = await response.json();
                if (result === 1) {
                    dispatch(updateWhatsAppTemplates(bookingId));
                }
            } else if (response.status === 401) {
                dispatch(set_token_error(true));
            }
        }
    } catch (e) {
        console.log('setReadTemplate error: ', e);
    }
};

export const getBookingForDepositTransfer = (hotelId, bookingId) => async (dispatch, getState) => {
    const accessToken = getState().login.access_token;
    try {
        if (accessToken) {
            const response = await api.getBookingsForDepositTransfer.fetch(hotelId, accessToken, bookingId);
            if (response.status === 200) {
                const result = await response.json();
                if (result.result.length) {
                    const bookings = result.result.map(el => ({
                        label: `${el.room_number} (${el.date_from} - ${el.date_to})`,
                        value: el.ext_id,
                    }));
                    dispatch(setBookingsForDepositTransfer(bookings));
                }
            } else if (response.status === 401) {
                dispatch(set_token_error(true));
            }
        }
    } catch (e) {
        console.log('setReadTemplate error: ', e);
    }
};

export const transferDeposit =
    ({ data }) =>
    async (dispatch, getState) => {
        const accessToken = getState().login.access_token;
        try {
            if (accessToken) {
                const response = await api.transferDeposit.fetch(accessToken, data);
                if (response.status === 200) {
                    const result = await response.json();
                    if (result.result.status) {
                        return true;
                    } else {
                        return result.result.message;
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                } else {
                    return 'Что-то пошло не так, обратитесь в поддержку';
                }
            }
        } catch (e) {
            console.log('transferDeposit error: ', e);
        }
    };

const selectChat = state => state.chat;
export const chat_list = state => state.chat.chatList;
export const message_send_fetch = state => state.chat.message_send_fetching;
// export const unread_messages_counter = state => state.chat.unread_messages_count;
export const unread_messages_counter = createSelector([selectChat], chat => chat.unread_messages_count);
export const selectUnreadMessagesByHotelId = createSelector([unread_messages_counter, (_, hotelId) => hotelId], (unreadMessages, hotelId) =>
    unreadMessages.find(msg => msg.hotel_id === hotelId)
);
export const chat_list_fetching = state => state.chat.chat_list_fetching;
export const push_send_fetching = state => state.chat.push_send_fetching;
export const push_status = state => state.chat.push_status;
export const chat_filter = state => state.chat.chat_filter;
export const userMessagesFetchState = state => state.chat.userMessagesFetch;
export const usersMessagesState = state => state.chat.usersMessages;
export const oldestMessagesFetchState = state => state.chat.oldestMessagesFetch;
export const depositFetchingState = state => state.chat.depositFetching;
export const whatsAppTemplatesState = state => state.chat.whatsAppTemplates;
export const chatDateFilterState = state => state.chat.chatDateFilter;
export const bookingsForDepositTransferState = state => state.chat.bookingsForDepositTransfer;

export default chatSlice.reducer;
