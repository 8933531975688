import { Dispatch, FC, SetStateAction, memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { hotelRoomNumbersState, hotelRoomNumbersFetchState, information_tabs, getHotelRoomNumbers } from '../../../../../redux/slices/informationSlice.js';
import { Room } from './types/templateTypes.js';
import { AppDispatch } from '../../../../../redux/store.js';

type Props = {
    selectedRoom: Room | null;
    setSelectedRoom: Dispatch<SetStateAction<Room | null>>;
    scrollRef: React.RefObject<HTMLDivElement>;
};

export const RoomListComponent: FC<Props> = ({ selectedRoom, setSelectedRoom, scrollRef }) => {
    const { t } = useTranslation();
    const params = useParams();

    const dispatch = useDispatch<AppDispatch>();
    const hotelRooms: Room[] = useSelector(hotelRoomNumbersState);
    const hotelRoomsFetching = useSelector(hotelRoomNumbersFetchState);
    const information: any = useSelector(information_tabs);

    const [hasInfo, setHasInfo] = useState<string[]>([]);
    const [renderRooms, setRenderRooms] = useState<Room[] | null>(null);
    const [roomFilter, setRoomFilter] = useState('');

    useEffect(() => {
        if (params.tab_code) {
            const info = information.find(el => el.link === params.tab_code);
            if (info.subitems) {
                const roomsHasInfo = info.subitems.map(el => el.roomId);
                setHasInfo(roomsHasInfo);
            } else {
                setHasInfo([]);
            }
        }
    }, [information, params.tab_code]);

    useEffect(() => {
        setRenderRooms(hotelRooms);
    }, [hotelRooms]);

    useEffect(() => {
        if (params.hotel_id && !hotelRooms.length) {
            dispatch(getHotelRoomNumbers({ hotelId: params.hotel_id }));
            return;
        }
        if (params.hotel_id && params.hotel_id !== hotelRooms[0].hotelId) {
            dispatch(getHotelRoomNumbers({ hotelId: params.hotel_id }));
            return;
        }
        setSelectedRoom(null);
        setRoomFilter('');
        setRenderRooms(hotelRooms);
    }, [params]);

    const handlerSearch = (value: string) => {
        if (renderRooms) {
            setRenderRooms(hotelRooms.filter(room => room.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
        }
    };
    return (
        <div className="h-64 bg-white p-6" ref={scrollRef}>
            <span className="mb-2 block ">{t('INFO_APARTMENT_2')}</span>
            <input
                value={roomFilter}
                type="text"
                className="mb-3 w-64 rounded border border-gray-300 p-1 text-sm"
                placeholder={t('NOTIFICATION_ROOM_SEARCH')}
                onChange={e => {
                    handlerSearch(e.target.value);
                    setRoomFilter(e.target.value);
                }}
            />
            <div className="h-32 overflow-y-auto rounded border border-gray-300 px-3 py-1.5">
                {hotelRoomsFetching ? (
                    <div className="flex h-full items-center justify-center">
                        <RotatingLines width="20" strokeColor="blue" />
                    </div>
                ) : renderRooms ? (
                    renderRooms.map(room => (
                        <button type="button" key={room.id} className="flex cursor-pointer items-center" onClick={() => setSelectedRoom(room)}>
                            <div className={`mr-1 h-2 w-2 shrink-0 rounded-full ${hasInfo.includes(room.id) ? 'bg-green-500' : 'bg-gray-400'}`}></div>
                            <span className={`${selectedRoom?.id === room.id ? 'text-blue-500' : ''} text-sm hover:text-blue-500`}> {room.label}</span>
                        </button>
                    ))
                ) : (
                    <div>{t('INFO_APARTMENT_3')}</div>
                )}
            </div>
        </div>
    );
};

export const RoomList = memo(RoomListComponent);
