import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';

import { editUser, users as users_state } from '../../../redux/slices/loyaltySlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';
import { root } from '../../../api/config';

const initState = {
    loyalty_status_id: '',
    comment: '',
};

const statusArr = [
    {
        id: 1,
        name: 'Участник',
        name_en: 'Basic Status',
    },
    {
        id: 2,
        name: 'Серебряный статус',
        name_en: 'Silver Status',
    },
    {
        id: 3,
        name: 'Золотой статус',
        name_en: 'Gold Status',
    },
    {
        id: 4,
        name: 'Платиновый статус',
        name_en: 'Platinum status',
    },
];

export const Edit = ({ userId, setUserId }) => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const users = useSelector(users_state);
    const access_token = useSelector(access_token_state);

    const [initialState, setInitialState] = useState(initState);
    const [user, setUser] = useState(null);
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const { t, i18n } = useTranslation();

    const onSubmitHandler = async (values, resetForm) => {
        setFetchAccessToken(access_token);
        let status;
        status = await dispatch(
            editUser({
                access_token,
                data: {
                    ...values,
                    guest_id: userId,
                    loyalty_status_name: statusArr.find(el => el.id === +values.loyalty_status_id).name,
                },
            })
        );

        if (status === 200) {
            closeHandler(resetForm);
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await onSubmitHandler(formikRef.current.values, formikRef.current.resetForm);
            }
        })();
    }, [access_token]);

    const closeHandler = resetForm => {
        setUserId(null);
        setUser(null);
        resetForm();
        setInitialState(initState);
    };

    useEffect(() => {
        if (userId) {
            const user = users.find(el => el.id === userId);
            if (user) {
                setUser(user);
            }
        }
    }, [userId]);

    useEffect(() => {
        if (user && user.hasOwnProperty('loyalty_status_id')) {
            setInitialState({
                loyalty_status_id: user.loyalty_status_id,
                comment: user.comment,
            });
        }
    }, [user]);

    return (
        <Formik
            initialValues={initialState}
            innerRef={formikRef}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => await onSubmitHandler(values, resetForm)}
        >
            {({ isSubmitting, resetForm }) => {
                return (
                    <Form
                        className={`${
                            userId ? 'flex' : 'hidden'
                        } fixed inset-0 z-40 items-center justify-center bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200`}
                    >
                        <div className="flex max-h-[97vh] w-[400px] max-w-full flex-col overflow-auto bg-white p-6">
                            <div>
                                {t('TABLE_USER')}: {user && user.hasOwnProperty('name') && user.name}
                            </div>
                            <div className="flex w-full flex-row flex-wrap content-start justify-between p-4">
                                <div className="mb-5 flex w-full flex-col">
                                    <label htmlFor="loyalty_status_id" className="mb-2 truncate text-base font-semibold text-black">
                                        {t('TABLE_STATUS')}
                                    </label>
                                    {/* для отелей вертикаль показываем селект для ДС показываем текст */}
                                    {root.includes('r=api') ? (
                                        <Field as="select" name="loyalty_status_id" className="border-gray-300">
                                            {statusArr.map(status => (
                                                <option key={`status${status.id}`} value={status.id}>
                                                    {i18n.language === 'ru' ? status.name : status.name_en}
                                                </option>
                                            ))}
                                        </Field>
                                    ) : statusArr.find(status => status.id === initialState.loyalty_status_id) ? (
                                        i18n.language === 'ru' ? (
                                            statusArr.find(status => status.id === initialState.loyalty_status_id).name
                                        ) : (
                                            statusArr.find(status => status.id === initialState.loyalty_status_id).name_en
                                        )
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div className="mb-5 flex w-full flex-col">
                                    <label htmlFor="comment" className="mb-2 truncate text-base font-semibold text-black">
                                        {t('TABLE_COMMENT')}
                                    </label>
                                    <Field id="comment" name="comment" component="textarea" className="border border-gray-300 p-2" />
                                </div>
                            </div>

                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    type="button"
                                    className=" mr-4 h-10 bg-transparent px-6 text-gray-700 transition-colors hover:text-black"
                                    onClick={() => closeHandler(resetForm)}
                                >
                                    {t('CANCEL')}
                                </button>
                                <button
                                    type="submit"
                                    className={`flex h-10 w-[135px] items-center justify-center px-6 text-white ${
                                        isSubmitting ? 'bg-gray-500' : 'bg-green-500 transition-colors hover:bg-green-600'
                                    }`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
