import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { tabs as tabs_state } from '../redux/slices/hotelSlice';
import { LoyaltyForm } from '../components/loyalty/loyaltyForm/LoyaltyForm';
import { Header } from '../components/loyalty/Header';
import { LoyaltySettings } from '../components/loyalty/loyaltySettings/LoyaltySettings';

export const LoyaltyFormPage = () => {
    const [tab, setTab] = useState(null);
    const [initSettingsValues, setInitSettingsValues] = useState({
        LOYALTY_STATUS_1: '',
        LOYALTY_STATUS_2: '',
        LOYALTY_STATUS_3: '',
        LOYALTY_STATUS_4: '',
        percentDiscount1: '',
        percentDiscount2: '',
        percentDiscount3: '',
        percentDiscount4: '',
    });

    const tabs = useSelector(tabs_state);

    const { hotel_id } = useParams();

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel) {
                setTab({
                    hotel_name: hotel.Name,
                });
            }
        }
    }, [tabs, hotel_id]);

    return (
        <>
            <Header tab={tab} />
            <LoyaltySettings initValues={initSettingsValues} setInitValues={setInitSettingsValues} />
            <LoyaltyForm settings={initSettingsValues} setInitSettingsValues={setInitSettingsValues} />
        </>
    );
};
