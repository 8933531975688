import { useEffect, useRef, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { SortablePicsLoader } from '../common/SortablePicsLoader';

import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import {
    addHotelInfoPics,
    deleteHotelInfoPics,
    getHotelInfoPics,
    hotel_info_pics as hotel_info_pics_state,
    hotelInfoPicsFetchingState,
} from '../../redux/slices/informationSlice';
import { errorNotification, successNotification } from '../../api/functions';

type Props = {
    tab_info: {
        FILES: string[];
        hotel_id: string;
        hotel_name: string;
        link: string;
        name: string;
        name_en: string;
        section_link: string;
        sort: number;
    };
};

type InfoPictures = {
    id: number;
    url: string;
};
export const HotelMain: FC<Props> = ({ tab_info }) => {
    const dispatch = useDispatch();
    const formikRef = useRef<FormikProps<any>>(null);

    const [initValues, setInitValues] = useState<{ files: InfoPictures[] }>({
        files: [],
    });
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const access_token: string = useSelector(access_token_state);
    const hotel_info_pics: InfoPictures[] = useSelector(hotel_info_pics_state);
    const photoFetching: boolean = useSelector(hotelInfoPicsFetchingState);

    const { t } = useTranslation();

    useEffect(() => {
        if (tab_info && tab_info.hasOwnProperty('hotel_id')) {
            dispatch(getHotelInfoPics({ access_token, hotel_id: tab_info.hotel_id }));
        }
    }, []);

    useEffect(() => {
        setInitValues({ files: hotel_info_pics });
    }, [hotel_info_pics]);

    const formSubmit = async (values: { files: InfoPictures[] }, { setSubmitting, resetForm }: { setSubmitting: Function; resetForm: Function }) => {
        setFetchAccessToken(access_token);

        const result = await dispatch(addHotelInfoPics({ access_token, hotel_id: tab_info.hotel_id, files: values.files }));

        if (result.error) {
            if (result.error === 1) {
                errorNotification(result.message);
            }
        } else {
            setSubmitting(false);
            resetForm({ values });
            successNotification(t('SAVE_INFORMATION'));
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token && formikRef.current) {
                await formSubmit(formikRef.current.values, formikRef.current);
            }
        })();
    }, [access_token]);

    const deleteFile = async (file: InfoPictures) => {
        const isPicAdd = hotel_info_pics.find(pic => pic.url === file.url);
        if (isPicAdd) {
            const result = await dispatch(deleteHotelInfoPics({ access_token, file: file.url }));
            if (result.error) {
                if (result.error === 1) {
                    errorNotification(result.message);
                }
            } else {
                successNotification(t('SAVE_INFORMATION'));
            }
        }
    };

    const sortDispatch = (data: InfoPictures[]) => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <Header tab={tab_info} />
            <ContentWrapper>
                {photoFetching ? (
                    ''
                ) : (
                    <Formik initialValues={initValues} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                        {({ values, errors, isSubmitting, isValidating, setFieldValue }) => {
                            if (isSubmitting && !isValidating) {
                                Object.keys(errors).forEach(errKey => {
                                    const err = (errors as any)[errKey];
                                    if (typeof err === 'string') {
                                        errorNotification(t(err));
                                    }
                                });
                            }
                            return (
                                <Form>
                                    <div className="bg-white pb-6">
                                        <div className="border-b p-6 font-medium">{t('UPLOAD_PHOTO')}</div>
                                        <div className="m-6 mb-0">
                                            <SortablePicsLoader
                                                setFieldValue={setFieldValue}
                                                files={values.files}
                                                maxFiles={20}
                                                callback={deleteFile}
                                                sortDispatch={sortDispatch}
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        className={`mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                                            !isSubmitting ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                        }`}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                    </button>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </ContentWrapper>
        </>
    );
};
