import { Dispatch, FC, SetStateAction } from 'react';

type Props = {
    chatItem: any;
    bookingId: string;
    setBookingId: Dispatch<SetStateAction<boolean>>;
};
export const WhatsAppItem: FC<Props> = ({ chatItem, bookingId, setBookingId }) => {
    return (
        <div
            className={`border-b-gray-10 relative flex w-full cursor-pointer flex-col border-b py-4 transition-colors ${
                bookingId === chatItem.booking_id ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-50'
            }`}
            onClick={() => setBookingId(chatItem.booking_id)}
        >
            {chatItem.is_admin_read === '0' && <div className={`absolute left-1 top-1 h-2 w-2 rounded rounded-br bg-blue-500`}></div>}
            <div className="flex items-center px-6 text-sm">
                <span className="break-words">{chatItem.name}</span>
            </div>
        </div>
    );
};
