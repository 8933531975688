import { t } from 'i18next';
import { FC } from 'react';
import { RotatingLines } from 'react-loader-spinner';

type Props = {
    isSubmitting: boolean;
    loading: { isSubmitting: boolean; type: string };
    type: string;
};
export const SubmitButton: FC<Props> = ({ loading, type }) => {
    return (
        <div className="flex w-full justify-center pb-3">
            <button
                type="submit"
                className={`mt-3 flex h-10 w-44 items-center justify-center rounded-sm px-14 text-sm font-medium ${
                    !(loading.isSubmitting && type === loading.type) ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                }`}
                disabled={loading.isSubmitting && type === loading.type}
            >
                {loading.isSubmitting && type === loading.type ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
            </button>
        </div>
    );
};
