import { SidebarHeaderLogo } from './SidebarHeaderLogo';
import { SidebarHeaderCloseButton } from './SidebarHeaderCloseButton';

export const SidebarHeader = ({ setSidebarOpen }) => (
    <div className="border-gray-10 sticky top-0 z-30 flex h-16 shrink-0 items-center justify-between border-b bg-white pl-3 pr-3">
        {/* Logo */}
        <SidebarHeaderLogo />

        {/* Close button */}
        <SidebarHeaderCloseButton setSidebarOpen={setSidebarOpen} />
    </div>
);
