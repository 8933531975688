import { useState, useEffect, useRef } from 'react';

import { SidebarHeader } from './header/SidebarHeader';
import { SidebarMenu } from './menu/SidebarMenu';
import { SidebarExpandButton } from './SidebarExpandButton';
import { SidebarBackdrop } from './SidebarBackdrop';

const listenForOutsideClick = (listening, setListening, menuRef, triggerRef, setIsOpen) => () => {
    if (listening) return;
    setListening(true);

    document.addEventListener('click', e => {
        const cur = menuRef.current;
        const trigger = triggerRef.current;
        if (!menuRef.current) return;

        const node = e.target;

        if (cur.contains(node) || trigger.contains(node)) return;
        setIsOpen(null);
    });
};
export const Sidebar = ({ sidebarOpen, setSidebarOpen, sidebarTrigger }) => {
    const sidebarRef = useRef(null);
    const [listening, setListening] = useState(false);

    useEffect(listenForOutsideClick(listening, setListening, sidebarRef, sidebarTrigger, setSidebarOpen));

    return (
        <div>
            <SidebarBackdrop sidebarOpen={sidebarOpen} />
            <div
                id="sidebar"
                ref={sidebarRef}
                className={`border-r-gray-10 no-scrollbar absolute left-0 top-0 z-40 flex h-screen w-64 shrink-0 transform flex-col overflow-y-scroll border-r bg-white shadow-2xl transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-20 lg:translate-x-0 lg:overflow-y-auto lg:sidebar-expanded:!w-64 2xl:!w-64 ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-64'
                }`}
            >
                <SidebarHeader setSidebarOpen={setSidebarOpen} />
                <SidebarMenu />
                <SidebarExpandButton />
            </div>
        </div>
    );
};
