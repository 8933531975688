import { useFormikContext, useField } from 'formik';
import Select from 'react-select';

export const CustomSelectField = props => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <Select
            isClearable={true}
            isSearchable={false}
            onChange={val => {
                if (val === null) {
                    setFieldValue(field.name, '');
                } else {
                    setFieldValue(field.name, val);
                }

                setFieldValue('start', '');
                setFieldValue('end', '');
            }}
            {...props}
        />
    );
};
