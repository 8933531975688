import { InitialValues } from './types';

export const initialState: InitialValues = {
    allDeposit: false,
    depositSum: '',
    refundDeposit: false,
    refundPayment: false,
    withholdReason: '',
    photo: null,
    transferDepositRoom: null,
};
