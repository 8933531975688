import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { setPaymentsFilter } from '../../../../redux/slices/loyaltySlice';

export const FilterByTypeMethod = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const options = [
        { label: 'Realty', value: 'cm' },
        { label: 'Realty DC', value: 'cmdc' },
        { label: 'Bank', value: 'bank' },
    ];
    const handlerChange = val => {
        setValue(val);
        dispatch(
            setPaymentsFilter({
                pay_type: val.value ?? null,
                page: 1,
            })
        );
    };

    return (
        <Select
            name="type"
            value={value}
            options={options}
            onChange={val => handlerChange(val ? val : '')}
            placeholder="Выберите тип оплаты"
            className="w-48 rounded text-xs"
            isSearchable={false}
            isClearable={true}
        />
    );
};
