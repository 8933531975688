import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import { set_token_error } from './loginSlice';

export const hotelSlice = createSlice({
    name: 'hotel',

    initialState: {
        tabs: [],
        tab_fetching: false,
        reviews: [],
        reviewFetching: false,
        updateReviewFetching: { isSubmitting: false, type: null },
    },

    reducers: {
        set_tabs: (state, action) => {
            state.tabs = action.payload;
        },
        set_tab_fetching: (state, action) => {
            state.tab_fetching = action.payload;
        },
        upd_loyalty: (state, action) => {
            state.tabs = state.tabs.map(el => (el.id === action.payload.hotel_id ? { ...el, loyalty_json: action.payload.loyalty } : el));
        },
        add_section: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    const hotel_subitems = hotel.subitems.map(subitem => {
                        if (subitem.link === action.payload.section_link) {
                            return { ...subitem, subitems: [...subitem.subitems, action.payload] };
                        }
                        return subitem;
                    });
                    return { ...hotel, subitems: hotel_subitems };
                }
                return hotel;
            });
        },
        set_sort_info_sections: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    const hotel_subitems = hotel.subitems.map(subitem => {
                        if (subitem.link === 'information') {
                            const sort_info = subitem.subitems.sort((x, y) => x.sort - y.sort);
                            return { ...subitem, subitems: sort_info };
                        }
                        return subitem;
                    });
                    return { ...hotel, subitems: hotel_subitems };
                }
                return hotel;
            });
        },
        update_hotel_tab: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    const hotel_subitems = hotel.subitems.map(subitem => {
                        if (subitem.link === action.payload.section_link) {
                            const sections = subitem.subitems.map(section => {
                                if (section.id === action.payload.tab_item_id) {
                                    return { ...section, ...action.payload };
                                }
                                return section;
                            });
                            return { ...subitem, subitems: sections };
                        }
                        return subitem;
                    });
                    return { ...hotel, subitems: hotel_subitems };
                }
                return hotel;
            });
        },
        update_manage_info: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    return { ...hotel, manage_additional_html: action.payload.manage_additional_html };
                }
                return hotel;
            });
        },
        update_tab: (state, action) => {
            state.tabs = state.tabs.map(hotel => {
                if (hotel.id === action.payload.hotel_id) {
                    return {
                        ...hotel,
                        subitems: hotel.subitems.map(subitem => {
                            if (subitem.link === action.payload.shop_link) {
                                return {
                                    ...subitem,
                                    subitems: subitem.subitems.map(subSubitem => {
                                        if (subSubitem.id === action.payload.id) {
                                            return { ...subSubitem, ...action.payload };
                                        }
                                        return subSubitem;
                                    }),
                                };
                            }
                            return subitem;
                        }),
                    };
                }
                return hotel;
            });
        },
        add_tab: (state, action) => {
            state.tabs
                .find(hotel => hotel.id === action.payload.hotel_id)
                .subitems.find(subitem => subitem.link === action.payload.shop_link)
                .subitems.push(action.payload);
        },

        setReviewFetching: (state, action) => {
            state.reviewFetching = action.payload;
        },
        setReviews: (state, action) => {
            state.reviews = action.payload;
        },
        updateReviewFetching: (state, action) => {
            state.updateReviewFetching = action.payload;
        },
        updateReview: (state, action) => {
            state.reviews = state.reviews.map(el => (el.type === action.payload.type ? action.payload : el));
        },
    },
});

export const {
    updateReviewFetching,
    updateReview,
    setReviews,
    setReviewFetching,
    update_tab,
    add_tab,
    update_manage_info,
    upd_loyalty,
    set_tabs,
    set_tab_fetching,
    add_section,
    set_sort_info_sections,
    update_hotel_tab,
} = hotelSlice.actions;

export const getHotelsList =
    ({ access_token }) =>
    async dispatch => {
        dispatch(set_tab_fetching(true));
        try {
            if (access_token) {
                const response = await api.getHotelList.fetch(access_token);

                if (response.status === 200) {
                    const res = await response.json();
                    dispatch(set_tabs(res));
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('getHotelsList error: ', e);
        }
        dispatch(set_tab_fetching(false));
    };

// получение отзывов отеля
export const getHotelReview =
    ({ hotelId }) =>
    async (dispatch, getState) => {
        const accessToken = getState().login.access_token;
        dispatch(setReviewFetching(true));
        try {
            if (accessToken && hotelId) {
                const response = await api.getReviews.fetch(accessToken, hotelId);
                if (response.status === 200) {
                    const result = await response.json();
                    if (result.length) {
                        const reviewsData = result.map(review => ({
                            ...review,
                            ru: { mainText: review.ru.main_text, secondButtons: review.ru.second_buttons },
                            en: { mainText: review.en.main_text, secondButtons: review.en.second_buttons },
                        }));
                        dispatch(setReviews(reviewsData));
                    } else {
                        dispatch(setReviews([]));
                    }
                }
            }
        } catch (e) {
            console.log('getHotelReview error: ', e);
        } finally {
            dispatch(setReviewFetching(false));
        }
    };

// обновление отзывов отеля
export const updateHotelReview =
    ({ data }) =>
    async (dispatch, getState) => {
        dispatch(updateReviewFetching({ isSubmitting: true, type: data.type }));
        const accessToken = getState().login.access_token;
        try {
            if (accessToken && data) {
                const reviewData = {
                    hotel_id: data.hotelId,
                    type: data.type,
                    time: data.time,
                    ru: { main_text: data.mainText, second_buttons: data.secondButtons },
                    en: { main_text: data.mainTextEn, second_buttons: data.secondButtonsEn },
                };
                const response = await api.updateReview.fetch(accessToken, reviewData);
                if (response.status === 200) {
                    const result = await response.json();
                    if (result.success) {
                        const reviewData = {
                            type: data.type,
                            time: data.time,
                            ru: { mainText: data.mainText, secondButtons: data.secondButtons },
                            en: { mainText: data.mainTextEn, secondButtons: data.secondButtonsEn },
                        };
                        dispatch(updateReview(reviewData));
                        return { isSuccess: true, type: data.type };
                    } else {
                        return { isSuccess: false, type: data.type };
                    }
                }
            }
        } catch (e) {
            console.log('updateHotelReview error: ', e);
        } finally {
            dispatch(updateReviewFetching({ isSubmitting: false, type: null }));
        }
    };

export const tabs = state => state.hotel.tabs;
export const tab_fetching = state => state.hotel.tab_fetching;
export const reviewsState = state => state.hotel.reviews;
export const reviewFetchingState = state => state.hotel.reviewFetching;
export const updateReviewFetchingState = state => state.hotel.updateReviewFetching;

export default hotelSlice.reducer;
