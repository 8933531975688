import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    chat_list as chat_list_state,
    setMessagesAsRead,
    chat_list_fetching as chat_list_fetching_state,
    userMessagesFetchState,
    usersMessagesState,
    getOldestMessages,
    oldestMessagesFetchState,
} from '../../redux/slices/chatSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { ChatDetail } from '../chat/chat/ChatDetail';
import { WhatsAppDetail } from './whatsApp/WhatsAppDetail';

export const Detail = ({ setBookingId, bookingId, tab, tabBar }) => {
    const { t } = useTranslation();
    const { hotel_id } = useParams();

    const [roomNumber, setRoomNumber] = useState('');
    const [messageList, setMessageList] = useState(null);

    const dispatch = useDispatch();
    const chat_list = useSelector(chat_list_state);
    const access_token = useSelector(access_token_state);
    const chat_list_fetching = useSelector(chat_list_fetching_state);
    const userMessagesFetch = useSelector(userMessagesFetchState);
    const usersMessages = useSelector(usersMessagesState);
    const oldestMessagesFetch = useSelector(oldestMessagesFetchState);

    useEffect(() => {
        if (bookingId) {
            setRoomNumber(chat_list.find(el => el.booking_id === bookingId)?.room_number);
            if (usersMessages[bookingId]) {
                setMessageList(usersMessages[bookingId]);
            }

            const unreadCount = chat_list.find(el => el.booking_id === bookingId)?.unreadCount;
            if (unreadCount) {
                dispatch(setMessagesAsRead({ access_token, booking_id: bookingId }));
            }
        }
    }, [bookingId, chat_list, usersMessages]);

    useEffect(() => {
        setRoomNumber('');
        setBookingId('');
    }, [chat_list_fetching]);

    useEffect(() => {
        if (userMessagesFetch) {
            setMessageList(null);
        }
    }, [userMessagesFetch]);

    const handlerLoadMoreMessages = () => {
        const oldMessageId = messageList.messages[messageList.messages.length - 1].id;
        dispatch(getOldestMessages({ hotelId: hotel_id, bookingId, oldMessageId }));
    };

    if (!bookingId) {
        return (
            <div className="hidden h-full w-full items-center justify-center md:flex">
                <div className="rounded-[100px] bg-gray-200 px-6 py-3 font-semibold">{t('CHAT_CHOOSE_PEOPLE')}</div>
            </div>
        );
    }

    return (
        <div className="flex h-full w-full flex-col md:h-auto">
            {tabBar === 'chat' ? (
                <ChatDetail
                    setBookingId={setBookingId}
                    roomNumber={roomNumber}
                    messageList={messageList}
                    userMessagesFetch={userMessagesFetch}
                    oldestMessagesFetch={oldestMessagesFetch}
                    handlerLoadMoreMessages={handlerLoadMoreMessages}
                    tab={tab}
                    bookingId={bookingId}
                />
            ) : (
                <WhatsAppDetail bookingId={bookingId} setBookingId={setBookingId} roomNumber={roomNumber} />
            )}
        </div>
    );
};
