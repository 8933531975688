import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

export const SortableWrapper = ({ children, items, sortDispatch }) => {
    const isMobile = () => window.innerWidth <= 768;
    const sensors = isMobile()
        ? []
        : useSensors(
              useSensor(PointerSensor, {
                  activationConstraint: {
                      distance: 1,
                  },
              })
          );

    const handleDragEnd = event => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            const oldIndex = items.indexOf(items.find(item => item.id === active.id));
            const newIndex = items.indexOf(items.find(item => item.id === over.id));

            sortDispatch(arrayMove(items, oldIndex, newIndex));
        }
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={items}>{children}</SortableContext>
        </DndContext>
    );
};
