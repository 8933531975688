import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getIcon } from '../../../../api/functions';

export const SidebarMenuItem = ({ children, title, style, icon, link, type = 'normal', newMessages, disabled = false }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <li className="mb-2 last:mb-0">
            <Link
                to={link}
                className={`block py-2 pl-6 pr-3 ${
                    pathname !== '/' && link.includes(pathname)
                        ? 'border-r-3 border-r-blue-500 bg-blue-100 text-blue-500'
                        : disabled
                        ? 'text-gray-400'
                        : 'text-current'
                } transition-colors hover:text-blue-500`}
            >
                <div className={`flex items-center ${style}`}>
                    {children}
                    {type === 'normal' ? (
                        <div className="flex truncate text-base">
                            <span className={`shrink-0 ${icon ? 'mr-2' : ''}`}>{getIcon(icon)}</span>

                            <span className={`truncate`}>{t(title)}</span>
                        </div>
                    ) : (
                        <span className="truncate text-base font-semibold">{t(title)}</span>
                    )}
                    {newMessages ? (
                        <span className=" ml-auto flex h-6 min-w-[1.5rem] items-center justify-center rounded-full bg-red-600 p-0.5 text-xs text-white">
                            {newMessages}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            </Link>
        </li>
    );
};
