import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { Activity } from './templatesPages/templatePart/Activity';
import { SortablePicsLoader } from '../common/SortablePicsLoader';
import { CustomReactQuill } from '../common/CustomReactQuill';

import { getUpdateString, successNotification, errorNotification } from '../../api/functions';
import {
    editSubInformation,
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
    updateInfoFetch as updateInfoFetch_state,
} from '../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

const RoomSchema = Yup.object().shape({
    name: Yup.string().min(2, 'INFO_ERROR_1').max(50, 'INFO_ERROR_2').required('INFO_ERROR_3'),
    name_en: Yup.string().min(2, 'INFO_ERROR_4').max(50, 'INFO_ERROR_5').required('INFO_ERROR_6'),
    preview_description: Yup.string().min(2, 'INFO_ERROR_50').max(50, 'INFO_ERROR_51').required('INFO_ERROR_52'),
    detail_description: Yup.string().min(12, 'INFO_ERROR_18').required('INFO_ERROR_17'),
    detail_description_en: Yup.string().min(12, 'INFO_ERROR_42').required('INFO_ERROR_17'),
    files: Yup.array().min(1, 'ERROR_MIN_ONE_PHOTO'),
});

const initialState = {
    name: '',
    name_en: '',
    preview_description: '',
    detail_description: '',
    detail_description_en: '',
    files: [],
    active: null,
};

export const HotelRoom = ({ tab_info }) => {
    const [initValues, setInitValues] = useState(initialState);
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);

    const { t } = useTranslation();

    useEffect(() => {
        if (response_status) {
            successNotification(t('SAVE_INFORMATION'));
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    const roomsTab = information_tabs.find(el => el.id === tab_info.parent_id);
    const roomTab = roomsTab.subitems.find(el => el.id === tab_info.id);

    useEffect(() => {
        if (information_tabs) {
            setInitValues({
                name: roomTab.name ? roomTab.name : '',
                name_en: roomTab.name_en ? roomTab.name_en : '',
                preview_description: roomTab.preview_description ? roomTab.preview_description : '',
                detail_description: roomTab.detail_description ? roomTab.detail_description : '',
                detail_description_en: roomTab.detail_description_en ? roomTab.detail_description_en : '',
                active: roomTab.active === '1' ? ['active'] : [],
                files: roomTab.files2,
            });
        }
    }, [information_tabs]);

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);
        let status;

        const detail_en = await getUpdateString(values.detail_description_en, tab_info, access_token);
        const detail_ru = await getUpdateString(values.detail_description, tab_info, access_token);

        if (detail_ru.length > 10000 || detail_en.length > 10000) {
            return errorNotification(t('INFO_PICSLOADER_ERROR_5'));
        }

        const data = {
            ...values,
            detail_description: detail_ru,
            detail_description_en: detail_en,
            tab_item_id: tab_info.id,
            hotel_id: tab_info.hotel_id,
            sub_tab_id: tab_info.sub_tab_id,
            section_link: tab_info.section_link,
            active: values.active[0] === 'active' ? '1' : '0',
        };

        status = await dispatch(editSubInformation({ access_token, data }));

        if (status === 200) {
            setSubmitting(false);
            resetForm({ values });
        }
    };

    const disabledBtn = isDisabled => {
        setDisableBtn(isDisabled);
    };

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };
    return (
        <>
            <Header tab={tab_info} />

            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={RoomSchema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(t(errors[err]));
                            }
                        }
                        return (
                            <Form>
                                <div className="sm:flex ">
                                    {/* main info */}
                                    <div className="w-full bg-white sm:w-1/2">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pl-6 pt-6 font-medium">{t('MAIN_INFO')}</p>
                                            <div className="ml-auto mr-6" data-tip={t('INFORMATION')}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* activity */}
                                        <Activity />

                                        {/* room name */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('NAME')}</p>
                                            <Field
                                                name="name"
                                                component="textarea"
                                                value={values.name}
                                                placeholder={t('NAME')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* room name en */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('NAME_EN')}</p>
                                            <Field
                                                name="name_en"
                                                component="textarea"
                                                value={values.name_en}
                                                placeholder={t('NAME_EN')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* size */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SIZE')}</p>
                                            <Field
                                                name="preview_description"
                                                component="textarea"
                                                value={values.preview_description}
                                                placeholder={t('INFO_SIZE')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* description */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION')}</p>
                                            <Field
                                                name="detail_description"
                                                value={values.detail_description}
                                                className="border-gray-10 box-border h-40 w-full  rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        disabledBtn={disabledBtn}
                                                        placeholder={t('DESCRIPTION')}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        {/* description EN */}
                                        <div className="mt-3 pb-6 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION_EN')}</p>
                                            <Field
                                                name="detail_description_en"
                                                value={values.detail_description_en}
                                                className="border-gray-10 box-border h-40 w-full rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        disabledBtn={disabledBtn}
                                                        placeholder={t('DESCRIPTION')}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    {/* photo */}
                                    <div className="ml-0 mt-3 w-full bg-white pb-3 sm:ml-6 sm:mt-0 sm:w-1/2  ">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pl-6 pt-6 font-medium">{t('UPLOAD_PHOTO')}</p>
                                            <div className="ml-auto mr-6" data-tip data-for="global">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="ml-6 mr-6 mt-6 flex flex-wrap">
                                            <SortablePicsLoader setFieldValue={setFieldValue} files={values.files} maxFiles={20} sortDispatch={sortDispatch} />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                                        !disableBtn ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                    } `}
                                    disabled={(!isSubmitting && disableBtn) || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>

            <ReactTooltip textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2" />
            <ReactTooltip id="global" aria-haspopup="true" textColor="#FCFCFC" backgroundColor="#8c8d9d" effect="solid" className="!rounded-md !px-2.5 !py-2">
                <h2>{t('INFO_TOOLTIP_3')}</h2>
                <ul>
                    <li>{t('INFO_TOOLTIP_4')}</li>
                    <li>{t('INFO_TOOLTIP_5')}</li>
                </ul>
            </ReactTooltip>
        </>
    );
};
