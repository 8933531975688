import { Route, Routes } from 'react-router-dom';

import { RequireAuth } from '../common/RequireAuth';
import { MainLayout } from '../common/MainLayout';
import { HomePage } from '../../pages/HomePage';
import { RoomManagementPage } from '../../pages/RoomManagementPage';
import { RoomManagementInfo } from '../../pages/RoomManagementInfo';
import { PushNotificationPage } from '../../pages/PushNotificationPage';
import { ChatPage } from '../../pages/ChatPage';
import { FoodPage } from '../../pages/FoodPage';
import { MerchPage } from '../../pages/MerchPage';
import { InformationPage } from '../../pages/InformationPage';
import { TemplateInfo2 } from '../informations/templatesPages/TemplateInfo2';
import { TemplateInfo3 } from '../informations/templatesPages/TemplateInfo3';
import { ShopPage } from '../../pages/ShopPage';
import { LoyaltyPage } from '../../pages/LoyaltyPage';
import { LoyaltyArrivingGuestsPage } from '../../pages/LoyaltyArrivingGuestsPage';
import { LoyaltyFormPage } from '../../pages/LoyaltyFormPage';
import { DesignPage } from '../../pages/DesignPage';
import { MeetingRoomPage } from '../../pages/MeetingRoomPage';
import { TariffsPage } from '../../pages/services/TariffsPage';
import { AuthPage } from '../../pages/AuthPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { ReviewPage } from '../../pages/ReviewPage';
import { BlackListPage } from '../../pages/BlackListPage';

export const Navigation = () => {
    return (
        <Routes>
            <Route element={<RequireAuth />}>
                <Route element={<MainLayout />}>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="/object/:hotel_id/room-management/" element={<RoomManagementPage />} />
                    <Route path="/object/:hotel_id/manage_additional" element={<RoomManagementInfo />} />
                    <Route path="/object/:hotel_id/push_notification" element={<PushNotificationPage />} />
                    <Route path="/object/:hotel_id/chat/" element={<ChatPage />} />
                    <Route path="/object/:hotel_id/shop/food/" element={<FoodPage />} />
                    <Route path="/object/:hotel_id/shop/shop/" element={<MerchPage />} />
                    <Route path="/object/:hotel_id/information/:tab_code/" element={<InformationPage />} />
                    <Route path="/object/:hotel_id/information/template_info_2/" element={<TemplateInfo2 />} />
                    <Route path="/object/:hotel_id/information/template_info_3/" element={<TemplateInfo3 />} />
                    <Route path="/object/:hotel_id/shop_2/:category_id/" element={<ShopPage />} />
                    <Route path="/object/:hotel_id/loyalty/arriving" element={<LoyaltyArrivingGuestsPage />} />
                    <Route path="/object/:hotel_id/loyalty/form" element={<LoyaltyFormPage />} />
                    <Route path="/loyalty-table/" element={<LoyaltyPage />} />
                    <Route path="/design/" element={<DesignPage />} />
                    <Route path="/object/:hotel_id/meeting/:tab_code/" element={<MeetingRoomPage />} />
                    <Route path="/object/:hotel_id/services/tariffs/" element={<TariffsPage />} />
                    <Route path="/object/:hotel_id/review/" element={<ReviewPage />} />
                    <Route path="/object/:hotel_id/black_list/" element={<BlackListPage />} />
                </Route>
            </Route>
            <Route path="/login" element={<AuthPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};
