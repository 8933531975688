import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tabs as tabs_state } from '../../redux/slices/hotelSlice';
import { Header } from '../../components/services/tariffs/Header';
import { List } from '../../components/services/tariffs/List';
import { Detail } from '../../components/services/tariffs/Detail';

export const TariffsPage = () => {
    const { hotel_id } = useParams();
    const tabs = useSelector(tabs_state);

    const [tab, setTab] = useState(null);
    const [detailId, setDetailId] = useState(null);

    useEffect(() => {
        if (tabs.length && hotel_id) {
            const hotel = tabs.find(hotel => hotel.id === hotel_id);
            if (hotel) {
                setTab({
                    hotel_name: hotel.Name || 'Не задано',
                    name: 'Тарифы',
                    hotel_id: +hotel.id,
                });
            }
        }

        return () => setTab(null);
    }, [tabs, hotel_id]);

    return (
        <>
            <Header tab={tab} setDetailId={setDetailId} />
            <List tab={tab} setDetailId={setDetailId} />
            {!!detailId && <Detail tab={tab} detailId={detailId} setDetailId={setDetailId} />}
        </>
    );
};
