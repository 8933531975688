import { InitValuesList } from '../types/templateTypes';

export const initialValues: InitValuesList = {
    name: '',
    name_en: '',
    detail_description: '',
    detail_description_en: '',
    preview_description: '',
    preview_description_en: '',
    preview_description2: '',
    preview_description2_en: '',
    sort: '',
    files: [],
    active: null,
    svg_current: null,
    json: [],
    subitems: '',
};
