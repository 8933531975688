import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getHotelsList } from '../../redux/slices/hotelSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';

import { Sidebar } from './sidebar/Sidebar';
import { Header } from './Header';

export const MainLayout = () => {
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarTrigger = useRef(null);
    const access_token = useSelector(access_token_state);

    //get tabs
    useEffect(() => {
        if (access_token) {
            dispatch(getHotelsList({ access_token }));
        }
    }, []);

    return (
        <div className="flex h-full overflow-hidden">
            {/*Sidebar*/}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} />

            {/*Content*/}
            <div className="relative flex h-full flex-1 flex-col overflow-x-hidden overflow-y-scroll md:min-h-screen" id="wrapper">
                {/*Header*/}
                <Header setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} />

                {/*Content wrapper*/}
                <main className="flex flex-grow flex-col">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};
