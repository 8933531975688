import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        login: '',
        access_token: '',
        refresh_token: '',
        auth_fetching: false,
        auth_error: null,
        check_auth_fetching: true,
        token_error: false,
        first_render_fetching: true,
    },
    reducers: {
        auth: (state, action) => {
            state.login = action.payload.login;
            state.access_token = action.payload.access_token;
            state.refresh_token = action.payload.refresh_token;
            state.auth_error = null;
        },

        logout: state => {
            state.login = '';
            state.access_token = '';
            state.refresh_token = '';
            state.auth_error = null;
            state.first_render_fetching = false;
        },

        set_auth_error: (state, action) => {
            state.auth_error = action.payload;
        },

        set_auth_fetching: (state, action) => {
            state.auth_fetching = action.payload;
        },

        set_check_auth_fetching: (state, action) => {
            state.check_auth_fetching = action.payload;
        },

        set_token_error: (state, action) => {
            state.token_error = action.payload;
        },

        set_first_render_fetching: (state, action) => {
            state.first_render_fetching = action.payload;
        },
    },
});

export const { auth, logout, set_auth_error, set_auth_fetching, set_check_auth_fetching, set_token_error, set_first_render_fetching } = loginSlice.actions;

export const checkAuth =
    ({ access_token, refresh_token, login }) =>
    async dispatch => {
        dispatch(set_check_auth_fetching(true));

        try {
            if (access_token && refresh_token && login) {
                const response = await api.checkAuth.fetch(access_token);
                if (response.status === 200) {
                    const res = await response.json();

                    if (!res.error) {
                        dispatch(auth({ login, access_token, refresh_token }));
                        dispatch(set_token_error(false));
                        dispatch(set_first_render_fetching(false));
                    } else {
                        dispatch(set_token_error(true));
                    }
                }
            } else {
                dispatch(logout());
            }
        } catch (e) {
            console.log('checkAuth error: ', e);
        } finally {
            dispatch(set_check_auth_fetching(false));
        }
    };

export const refreshToken =
    ({ access_token, refresh_token, login }) =>
    async dispatch => {
        try {
            if (access_token && refresh_token && login) {
                const response = await api.refreshToken.fetch(refresh_token);
                if (response.status === 200) {
                    const res = await response.json();
                    if (!res.error) {
                        dispatch(
                            auth({
                                login,
                                access_token: res.token.access_token,
                                refresh_token: res.token.refresh_token,
                            })
                        );
                        dispatch(set_token_error(false));
                    } else {
                        dispatch(logout());
                    }
                }
            } else {
                dispatch(logout());
            }
        } catch (e) {
            console.log('refreshToken error: ', e);
        } finally {
            dispatch(set_first_render_fetching(false));
        }
    };

export const authFetching =
    ({ login, password }) =>
    async dispatch => {
        dispatch(set_auth_fetching(true));
        try {
            if (login && password) {
                const response = await api.login.fetch(login, password);

                if (response.status === 200) {
                    const res = await response.json();

                    if (!res.error) {
                        dispatch(
                            auth({
                                login,
                                access_token: res.token.access_token,
                                refresh_token: res.token.refresh_token,
                            })
                        );
                        dispatch(set_token_error(false));
                    } else {
                        dispatch(set_auth_error(res.message));
                    }
                }
            } else {
                dispatch(set_auth_error('Введите логин и пароль'));
            }
        } catch (e) {
            console.log('authFetching error: ', e);
        } finally {
            await dispatch(set_auth_fetching(false));
            dispatch(set_first_render_fetching(false));
        }
    };

export const check_auth_fetching = state => state.login.check_auth_fetching;
export const auth_error = state => state.login.auth_error;
export const access_token = state => state.login.access_token;
export const refresh_token = state => state.login.refresh_token;
export const login = state => state.login.login;
export const token_error = state => state.login.token_error;
export const first_render_fetching = state => state.login.first_render_fetching;
export const authFetchingState = state => state.login.auth_fetching;

export default loginSlice.reducer;
