import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import { setPaymentsFilter } from '../../../../redux/slices/loyaltySlice';

export const FilterByDate = () => {
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                setPaymentsFilter({
                    date_from: moment(startDate).format('YYYY-MM-DD '),
                    date_to: moment(endDate).format('YYYY-MM-DD '),
                    page: 1,
                })
            );
        }
    }, [dateRange]);

    return (
        <div className="w-40">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                    if (!update[0] && !update[1]) {
                        dispatch(setPaymentsFilter({ date_from: null, date_to: null, page: 1 }));
                    }
                    setDateRange(update);
                }}
                isClearable={true}
                placeholderText="Выберите дату"
                className="h-[38px] rounded border border-gray-300 text-xs"
                locale={ru}
                dateFormat="dd.MM.yy"
            />
        </div>
    );
};
