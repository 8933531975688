import { useState, useEffect } from 'react';
import { PowerButton } from './PowerButton';
import { ChangeButton } from './ChangeButton';
import { MachineInfo } from './MachineInfo';
import { ConfirmationPopup } from '../ConfirmationPopup';

export const Machine = ({ machine, setChangePopupIsOpen, setMachineId }) => {
    const [powerStatus, setPowerStatus] = useState(Number(machine.on_off_status));
    const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false);

    useEffect(() => {
        setPowerStatus(Number(machine.on_off_status));
    }, [machine.on_off_status]);

    const powerButtonClick = () => {
        if (machine.working_status === '0' || machine.rele_status === '0') {
            return;
        }
        setConfirmationPopupIsOpen(true);
    };
    return (
        <div
            className={`h-52 w-44 rounded-2xl p-4 ${
                machine.working_status === '0' || machine.rele_status === '0' ? 'bg-red-200' : machine.stage_status === '0' ? 'bg-green-200' : 'bg-gray-200'
            }`}
        >
            <div className="flex items-center justify-between">
                <PowerButton powerStatus={powerStatus} powerButtonClick={powerButtonClick} />
                <ChangeButton setChangePopupIsOpen={setChangePopupIsOpen} machine={machine} setMachineId={setMachineId} />
            </div>
            <MachineInfo machine={machine} />
            {confirmationPopupIsOpen && (
                <ConfirmationPopup id={machine.id} powerStatus={powerStatus} isOpen={confirmationPopupIsOpen} setIsOpen={setConfirmationPopupIsOpen} />
            )}
        </div>
    );
};
