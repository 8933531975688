import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import TemplatePic1 from '../../../../../source/images/template_info/template_info.jpg';
import TemplatePic2 from '../../../../../source/images/template_info/template_info_1.jpeg';

type Props = {
    setValue: Dispatch<SetStateAction<string>>;
};
export const TemplateSelection: FC<Props> = ({ setValue }) => {
    const { t } = useTranslation();
    return (
        <div className="bg-white p-6">
            <div className="text-center">
                <span>{t('INFO_APARTMENT_1')}</span>
            </div>
            <div className="mt-6 flex flex-col items-center justify-around md:flex-row">
                <div className="mb-6 drop-shadow md:mb-0">
                    <img src={TemplatePic1} alt="template" className="h-auto w-72" />
                    <button
                        type="button"
                        onClick={() => setValue('list')}
                        className="ml-auto mr-auto mt-3 block h-10 w-44 cursor-pointer rounded-sm bg-blue-500 px-14 pb-2 pt-2 text-center text-sm font-medium text-white"
                    >
                        {t('INFO_CHOOSE')}
                    </button>
                </div>
                <div className="drop-shadow">
                    <img src={TemplatePic2} alt="template" className="h-auto w-72" />
                    <button
                        type="button"
                        onClick={() => setValue('accordion')}
                        className="ml-auto mr-auto mt-3 block h-10 w-44 cursor-pointer rounded-sm bg-blue-500 px-14 pb-2 pt-2 text-center text-sm font-medium text-white"
                    >
                        {t('INFO_CHOOSE')}
                    </button>
                </div>
            </div>
        </div>
    );
};
