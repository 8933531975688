import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getIcon } from '../../../../api/functions';

export const SidebarMenuLinkGroupButton = ({ title, isOpen, icon, url, setIsOpen, type = 'normal' }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const [active, setActive] = useState(pathname.includes(url));

    useEffect(() => {
        if (pathname.includes(url)) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [pathname]);

    return (
        <button
            className={`block w-full py-2 pl-6 pr-3 transition-colors hover:text-blue-500 ${active ? 'text-blue-500 ' : 'text-current'}`}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center truncate">
                    {type === 'normal' ? (
                        <div className="flex truncate text-base">
                            <span className={`shrink-0 ${icon ? 'mr-2' : ''}`}>{getIcon(icon)}</span>
                            <span className="truncate">{t(title)}</span>
                        </div>
                    ) : (
                        <span className="truncate text-base font-semibold">{t(title)}</span>
                    )}
                </div>

                <div className="ml-2 flex shrink-0">
                    <svg className={`ml-1 h-3 w-3 shrink-0 fill-current text-slate-400 ${isOpen && 'rotate-180 transform'}`} viewBox="0 0 12 12">
                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                </div>
            </div>
        </button>
    );
};
