import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { FormikErrors } from 'formik';

import { SectionName } from './SectionName';
import { Photo } from '../../Photo.js';
import { Activity } from '../../Activity';
import { Sort } from '../../Sort';
import { Icons } from '../../Icons';
import { InitValuesList } from '../types/templateTypes.js';

import { information_icons } from '../../../../../../redux/slices/informationSlice.js';

type Props = {
    values: InitValuesList;
    isSubmitting: boolean;
    idSvg: number | null;
    currentSvg: string | null;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>;
    setInitValues: Dispatch<SetStateAction<InitValuesList>>;
    setIdSvg: Dispatch<SetStateAction<number | null>>;
};

export const MainInfo: FC<Props> = ({ values, isSubmitting, setFieldValue, setInitValues, idSvg, setIdSvg, currentSvg }) => {
    const informationIcons: { id: number; icon: string }[] = useSelector(information_icons);

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    const handleSvgClick = (e, setFieldValue) => {
        const ev = e.target.closest('div').dataset.svg;
        if (ev) {
            const icon = informationIcons.find(el => el.id === Number(ev));
            setIdSvg(Number(ev));
            setFieldValue('svg_current', icon?.icon);
        }
    };
    return (
        <>
            <SectionName values={values} isSubmitting={isSubmitting} />
            <div className="flex border-b bg-white pb-3">
                <div className="w-1/2">
                    <Photo setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />
                    <div className="mt-6 border-b border-t">
                        <Activity />
                    </div>
                    <div className="flex items-center">
                        <Sort values={values} isSubmitting={isSubmitting} />
                    </div>
                </div>
                <div className="w-1/2">
                    <Icons dataSvg={informationIcons} handleSvgClick={handleSvgClick} idSvg={idSvg} setFieldValue={setFieldValue} currentSvg={currentSvg} />
                </div>
            </div>
        </>
    );
};
