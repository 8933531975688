import { TFunction } from 'i18next';

export const getButtonName = (
    depositSum: string,
    allDeposit: boolean,
    transferDepositRoom: { value: string; label: string } | null,
    t: TFunction<'translation', undefined>
) => {
    if (depositSum.length || allDeposit) {
        return t('CHAT_DEPOSIT_6');
    }
    if (transferDepositRoom) {
        return t('CHAT_DEPOSIT_36');
    } else {
        return t('CHAT_DEPOSIT_9');
    }
};
