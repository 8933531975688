import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getPayments, paymentsDataFetchingState, paymentsDataState, paymentsFilterState, setPaymentsFilter } from '../../../redux/slices/loyaltySlice';
import { Loader } from '../../common/Loader';
import { PaymentsFilters } from './PaymentsFilters';

export const PaymentTable = ({ tab }) => {
    const dispatch = useDispatch();
    const paymentData = useSelector(paymentsDataState);
    const paymentsDataFetching = useSelector(paymentsDataFetchingState);
    const paymentsSettings = useSelector(paymentsFilterState);

    useEffect(() => {
        dispatch(getPayments({ data: { hotel_id: tab.hotel_id, ...paymentsSettings } }));
    }, [paymentsSettings]);

    const columns = useMemo(
        () => [
            {
                Header: 'Адрес',
                accessor: 'roomNumber',
            },
            {
                Header: 'Даты проживания',
                accessor: 'date',
            },
            {
                Header: 'Способ оплаты',
                accessor: 'paymentMethod',
            },
            {
                Header: 'Сумма оплаты',
                accessor: 'balance',
            },
        ],
        []
    );

    const dataList = useMemo(() => paymentData.data || [], [paymentData]);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
        columns,
        data: dataList,
    });
    const count = paymentData.totalCount - paymentData.data.length;

    return (
        <div>
            <PaymentsFilters />
            <div className=" mx-auto w-full max-w-9xl overflow-x-auto">
                {paymentsDataFetching ? (
                    <Loader />
                ) : paymentData.data.length ? (
                    <InfiniteScroll
                        next={() => dispatch(setPaymentsFilter({ page: paymentsSettings.page + 1 }))}
                        hasMore={count > 0 && paymentData.totalCount > 100}
                        loader={<Loader />}
                        dataLength={rows.length}
                        scrollableTarget="wrapper"
                    >
                        <table {...getTableProps()} className="w-full bg-white text-sm">
                            <thead className="absolute w-0 overflow-hidden text-left text-black md:sticky md:w-full md:overflow-visible">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps({
                                                    style: { width: column.width },
                                                })}
                                                className="border-gray-10 border-y bg-white px-2 py-5 font-medium first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:border-r last:pr-4"
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className="border-gray-10 mx-2 mb-4 block overflow-hidden rounded-lg border md:mb-0 md:table-row md:overflow-visible md:border-0"
                                        >
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps({
                                                            style: {
                                                                width: cell.column.width,
                                                            },
                                                        })}
                                                        data-label={cell.column.Header}
                                                        className={`border-gray-10 flex !w-full justify-between border-b bg-white px-2 py-5 text-right font-normal before:font-medium  before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:!w-auto md:text-left md:before:hidden md:first:border-l md:first:pl-4 md:last:border-b md:last:border-r md:last:pr-4 ${
                                                            rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                        }`}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                ) : (
                    <div className="mt-6 flex justify-center">Данных нет</div>
                )}
            </div>
        </div>
    );
};
