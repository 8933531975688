import { useEffect, useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { Header } from '../Header';
import { Icons } from './templatePart/Icons';
import { ContentWrapper } from '../../common/ContentWrapper';
import { Photo } from './templatePart/Photo';
import { Activity } from './templatePart/Activity';
import { CustomReactQuill } from '../../common/CustomReactQuill';
import { getUpdateString, errorNotification, successNotification } from '../../../api/functions';
import {
    set_response_status,
    response_status as response_status_state,
    information_tabs as information_tabs_state,
    updateInfoFetch as updateInfoFetch_state,
    tab_info as tab_info_state,
    getHotelInformationFetch,
    createNewInfoSection,
    information_icons as information_icons_state,
} from '../../../redux/slices/informationSlice';
import { access_token as access_token_state } from '../../../redux/slices/loginSlice';

const Template1Schema = Yup.object().shape({
    name: Yup.string().min(2, 'INFO_ERROR_11').required('INFO_ERROR_13'),
    name_en: Yup.string().min(2, 'INFO_ERROR_14').required('INFO_ERROR_16'),
    preview_description: Yup.string().nullable().min(2, 'INFO_ERROR_35'),
    preview_description_en: Yup.string().nullable().min(2, 'INFO_ERROR_36'),
    preview_description2: Yup.string().nullable().min(2, 'INFO_ERROR_37').max(20, 'INFO_ERROR_38'),
    preview_description2_en: Yup.string().nullable().min(2, 'INFO_ERROR_39').max(20, 'INFO_ERROR_40'),
    detail_description: Yup.string().nullable().min(12, 'INFO_ERROR_18').required('INFO_ERROR_17'),
    detail_description_en: Yup.string().nullable().min(12, 'INFO_ERROR_42').required('INFO_ERROR_17'),
    sort: Yup.number().typeError('INFO_ERROR_7').positive('INFO_ERROR_8').max(9999, 'INFO_ERROR_83'),
    files: Yup.array().min(1, 'INFO_ERROR_9').required('INFO_ERROR_9'),
    svg_current: Yup.string().required('INFO_ERROR_10'),
});

const initialValues = {
    name: '',
    name_en: '',
    detail_description: '',
    detail_description_en: '',
    preview_description: '',
    preview_description_en: '',
    preview_description2: '',
    preview_description2_en: '',
    sort: '',
    files: [],
    active: null,
    svg_current: null,
};

export const TemplateInfo1 = () => {
    const [fetchAccessToken, setFetchAccessToken] = useState('');
    const [idSvg, setIdSvg] = useState('');
    const [initValues, setInitValues] = useState(initialValues);

    const formikRef = useRef();

    const dispatch = useDispatch();
    const access_token = useSelector(access_token_state);
    const response_status = useSelector(response_status_state);
    const information_tabs = useSelector(information_tabs_state);
    const updateInfoFetch = useSelector(updateInfoFetch_state);
    const tab_info = useSelector(tab_info_state);
    const information_icons = useSelector(information_icons_state);

    const params = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (response_status) {
            successNotification(t('SAVE_INFORMATION'));
            dispatch(set_response_status(false));
        }
    }, [response_status]);

    useEffect(() => {
        if (!information_tabs.length) {
            dispatch(getHotelInformationFetch({ access_token, hotel_id: params.hotel_id }));
        }
    }, []);

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setFetchAccessToken(access_token);

        let status;
        const detail_en = await getUpdateString(values.detail_description_en, tab_info, access_token);
        const detail_ru = await getUpdateString(values.detail_description, tab_info, access_token);
        const active = values.active ? (values.active[0] === 'active' ? '1' : '0') : '0';

        if (detail_ru.length > 10000 || detail_en.length > 10000) {
            return errorNotification(t('INFO_PICSLOADER_ERROR_5'));
        }

        const data = {
            ...values,
            detail_description: detail_ru,
            detail_description_en: detail_en,
            hotel_id: tab_info.hotel_id,
            sort: values.sort ? values.sort : '100',
            active,
            section_link: tab_info.link,
        };
        status = await dispatch(createNewInfoSection({ data }));

        if (status === 20) {
            errorNotification(t('INFO_ERROR_82'));
        }

        if (status.id) {
            setSubmitting(false);
            resetForm(initialValues);
            setIdSvg('');
        } else {
            errorNotification(t('NOTIFICATION_ERROR_13'));
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                await formSubmit(formikRef.current.values, formikRef.current.setSubmitting);
            }
        })();
    }, [access_token]);

    const handleSvgClick = (e, setFieldValue) => {
        const ev = e.target.closest('div').dataset.svg;
        if (ev) {
            const icon = information_icons.find(el => el.id === Number(ev));
            setIdSvg(Number(ev));
            setFieldValue('svg_current', icon.icon);
        }
    };

    const sortDispatch = data => {
        setInitValues(prevState => ({ ...prevState, files: data }));
    };

    return (
        <>
            <Header tab={{ ...tab_info, name: t('INFO_TEMPLATE_SECTION'), name_en: t('INFO_TEMPLATE_SECTION') }} />
            <ContentWrapper>
                <Formik initialValues={initValues} validationSchema={Template1Schema} innerRef={formikRef} onSubmit={formSubmit} enableReinitialize={true}>
                    {({ values, errors, isValidating, isSubmitting, dirty, setFieldValue }) => {
                        if (isSubmitting && !isValidating) {
                            for (const err in errors) {
                                errorNotification(t(errors[err]));
                            }
                        }
                        return (
                            <Form>
                                <div className="sm:flex">
                                    {/* main info */}
                                    <div className=" bg-white sm:w-1/2">
                                        <div className="flex items-center border-b">
                                            <p className="pb-6 pl-6 pt-6 font-medium">{t('MAIN_INFO')}</p>
                                            <div className="ml-auto mr-6" data-tip={t('INFORMATION')}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* name */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME')}</p>
                                            <Field
                                                name="name"
                                                component="textarea"
                                                value={values.name}
                                                placeholder={t('INFO_SECTION_NAME')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* name EN */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_SECTION_NAME_EN')}</p>
                                            <Field
                                                name="name_en"
                                                component="textarea"
                                                value={values.name_en}
                                                placeholder={t('INFO_SECTION_NAME_EN')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* working hours */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_WORK_TIME')}</p>
                                            <Field
                                                name="preview_description"
                                                component="textarea"
                                                value={values.preview_description}
                                                placeholder={t('INFO_WORK_TIME')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* working hours EN */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_WORK_TIME_EN')}</p>
                                            <Field
                                                name="preview_description_en"
                                                component="textarea"
                                                value={values.preview_description_en}
                                                placeholder={t('INFO_WORK_TIME_EN')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* location */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_LOCATION')}</p>
                                            <Field
                                                name="preview_description2"
                                                component="textarea"
                                                value={values.preview_description2}
                                                placeholder={t('INFO_LOCATION')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* location en */}
                                        <div className="mt-3 pl-6 pr-6 ">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('INFO_LOCATION_EN')}</p>
                                            <Field
                                                name="preview_description2_en"
                                                component="textarea"
                                                value={values.preview_description2_en}
                                                placeholder={t('INFO_LOCATION_EN')}
                                                className="border-gray-10 box-border h-8 w-full resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        {/* description */}
                                        <div className="mt-3 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION')}</p>
                                            <Field
                                                name="detail_description"
                                                value={values.detail_description}
                                                placeholder="Описание"
                                                className="border-gray-10 box-border h-24 w-full  rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        // disabledBtn={disabledBtn}
                                                        placeholder={t('DESCRIPTION')}
                                                    />
                                                )}
                                            </Field>
                                        </div>

                                        {/* description EN */}
                                        <div className="mt-3 pb-6 pl-6 pr-6">
                                            <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION_EN')}</p>
                                            <Field
                                                name="detail_description_en"
                                                value={values.detail_description_en}
                                                placeholder="description"
                                                className="border-gray-10 box-border h-24 w-full rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            >
                                                {({ field, imageHandler }) => (
                                                    <CustomReactQuill
                                                        imageHandler={imageHandler}
                                                        value={field.value}
                                                        onChange={field.onChange(field.name)}
                                                        // disabledBtn={disabledBtn}
                                                        placeholder={t('DESCRIPTION_EN')}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="mt-3 bg-white pb-6 sm:ml-6 sm:mt-0 sm:w-1/2">
                                        {/* photo */}
                                        <Photo setFieldValue={setFieldValue} files={values.files} sortDispatch={sortDispatch} />

                                        {/* activity */}
                                        <div className="mt-6 border-b border-t">
                                            <Activity />
                                        </div>
                                        {/* sort */}
                                        <div className="flex items-center border-b">
                                            <p className="mr-4 pb-6 pl-6 pt-6 font-medium">{t('INFO_SORT')}</p>

                                            <Field
                                                name="sort"
                                                component="textarea"
                                                value={values.sort}
                                                placeholder="№"
                                                className="border-gray-10 mr-5 box-border h-8 w-12 resize-none rounded p-1 pl-2 text-sm"
                                                disabled={isSubmitting}
                                            />

                                            <div className="ml-auto mr-6" data-tip={t('INFO_TOOLTIP_1')}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M11.9502 8H12.0502V8.1H11.9502V8Z"
                                                        stroke="#1890FF"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* icon */}
                                        <Icons dataSvg={information_icons} handleSvgClick={handleSvgClick} idSvg={idSvg} setFieldValue={setFieldValue} />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={` mt-3 h-10 rounded-sm px-14 text-sm font-medium  ${
                                        dirty ? 'cursor-pointer bg-blue-500 text-white' : 'cursor-default bg-slate-300 text-gray-400'
                                    } `}
                                    disabled={!dirty || updateInfoFetch}
                                >
                                    {updateInfoFetch ? <RotatingLines width="20" strokeColor="white" /> : t('SAVE')}
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentWrapper>

            <ReactTooltip effect="solid" textColor="#FCFCFC" backgroundColor="#8c8d9d" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
