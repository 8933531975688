import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Popup } from '../../common/popup/Popup';
import { errorNotification } from '../../../api/functions';
import { machineControl } from '../../../redux/slices/informationSlice';

export const ConfirmationPopup = ({ isOpen, setIsOpen, powerStatus, id }) => {
    const dispatch = useDispatch();

    const [bookingId, setBookingId] = useState('');
    const [bookingInputIsOpen, setBookingInputIsOpen] = useState(false);

    const { t } = useTranslation();

    const handlerStartClick = async () => {
        const data = {
            id,
            command: 'on',
            booking_id: bookingId,
        };
        const status = await dispatch(machineControl({ data }));
        if (status === 'ok') {
            setIsOpen(false);
        } else {
            errorNotification(status);
        }
    };
    const handlerAccept = async () => {
        if (powerStatus === 1) {
            const data = {
                id,
                command: 'off',
                booking_id: bookingId,
            };
            const status = await dispatch(machineControl({ data }));
            if (status === 'ok') {
                setIsOpen(false);
            } else {
                errorNotification(status);
                setIsOpen(false);
            }
        }
        setBookingInputIsOpen(true);
    };
    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen} className="w-96">
            <div className="flex">
                <svg width="14" height="22" viewBox="0 0 14 22" fill="none" className="mr-2">
                    <g clipPath="url(#clip0_1087_219)">
                        <path
                            d="M7 4.875C3.61758 4.875 0.875 7.61758 0.875 11C0.875 14.3824 3.61758 17.125 7 17.125C10.3824 17.125 13.125 14.3824 13.125 11C13.125 7.61758 10.3824 4.875 7 4.875ZM6.5625 8.04688C6.5625 7.98672 6.61172 7.9375 6.67188 7.9375H7.32812C7.38828 7.9375 7.4375 7.98672 7.4375 8.04688V11.7656C7.4375 11.8258 7.38828 11.875 7.32812 11.875H6.67188C6.61172 11.875 6.5625 11.8258 6.5625 11.7656V8.04688ZM7 14.0625C6.82827 14.059 6.66476 13.9883 6.54455 13.8656C6.42434 13.7429 6.35701 13.578 6.35701 13.4062C6.35701 13.2345 6.42434 13.0696 6.54455 12.9469C6.66476 12.8242 6.82827 12.7535 7 12.75C7.17173 12.7535 7.33524 12.8242 7.45545 12.9469C7.57566 13.0696 7.64299 13.2345 7.64299 13.4062C7.64299 13.578 7.57566 13.7429 7.45545 13.8656C7.33524 13.9883 7.17173 14.059 7 14.0625Z"
                            fill="#FAAD14"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1087_219">
                            <rect width="14" height="14" fill="white" transform="translate(0 4)" />
                        </clipPath>
                    </defs>
                </svg>
                {powerStatus === 0 ? t('INFO_LAUNDRY_NOTIFICATION') : t('INFO_LAUNDRY_NOTIFICATION_1')}
            </div>
            <div className="ml-auto mt-2">
                <button type="button" className="mr-2 rounded border px-2 py-1 text-sm transition-colors hover:bg-gray-100" onClick={() => setIsOpen(false)}>
                    {t('CANCEL')}
                </button>
                <button
                    type="button"
                    className="rounded border bg-blue-500 px-8 py-1 text-sm text-white transition-colors hover:bg-blue-700"
                    onClick={() => handlerAccept()}
                >
                    {t('YES')}
                </button>
            </div>

            {bookingInputIsOpen && powerStatus === 0 && (
                <div className="mt-2 flex flex-col items-center">
                    <span className="text-sm">{t('INFO_BOOKING_CODE_1')}</span>
                    <input type="text" className="mt-1 h-7 w-24 rounded text-xs" onChange={e => setBookingId(e.target.value)} />
                    <button
                        onClick={() => handlerStartClick()}
                        disabled={bookingId.length === 0}
                        type="button"
                        className="mt-2 rounded border bg-blue-500 px-4 py-1 text-sm text-white transition-colors hover:bg-blue-700"
                    >
                        {t('INFO_START_MACHINE')}
                    </button>
                </div>
            )}
        </Popup>
    );
};
