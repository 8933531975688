import { Field } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomReactQuill } from '../../../../../common/CustomReactQuill.js';
import { InitValuesList } from '../types/templateTypes.js';
type Props = {
    values: InitValuesList;
    isSubmitting: boolean;
};
export const Description: FC<Props> = ({ values, isSubmitting }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION')}</p>
                <Field
                    name="detail_description"
                    value={values.detail_description}
                    placeholder="Описание"
                    className="border-gray-10 box-border h-24 w-full  rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                >
                    {({ field, imageHandler }) => (
                        <CustomReactQuill
                            imageHandler={imageHandler}
                            value={field.value}
                            onChange={field.onChange(field.name)}
                            // disabledBtn={disabledBtn}
                            placeholder={t('DESCRIPTION')}
                        />
                    )}
                </Field>
            </div>

            {/* description EN */}
            <div className="mt-3 pb-6 pl-6 pr-6">
                <p className="text-gray-20 mb-2 flex w-full text-sm">{t('DESCRIPTION_EN')}</p>
                <Field
                    name="detail_description_en"
                    value={values.detail_description_en}
                    placeholder="description"
                    className="border-gray-10 box-border h-24 w-full rounded p-1 pl-2 text-sm"
                    disabled={isSubmitting}
                >
                    {({ field, imageHandler }) => (
                        <CustomReactQuill
                            imageHandler={imageHandler}
                            value={field.value}
                            onChange={field.onChange(field.name)}
                            // disabledBtn={disabledBtn}
                            placeholder={t('DESCRIPTION_EN')}
                        />
                    )}
                </Field>
            </div>
        </>
    );
};
