import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header } from './Header';
import { ContentWrapper } from '../common/ContentWrapper';
import { information_tabs as information_tabs_state } from '../../redux/slices/informationSlice';

export const HotelHearUs = ({ tab }) => {
    const information_tabs = useSelector(information_tabs_state);
    const { t, i18n } = useTranslation();

    const params = useParams();
    const locations = information_tabs?.find(el => el.code === params.tab_code);

    return (
        <>
            <Header tab={tab} />
            <ContentWrapper>
                <div className=" gap-4 md:flex">
                    {locations?.subitems?.map(location => {
                        return (
                            <div className="mb-3 bg-white drop-shadow md:mb-0 md:w-1/4 " key={location.id}>
                                <img src={location.files[0]} alt={location.name} className="mb-4 h-40 w-full object-cover md:w-96" />
                                <p className="mb-3 ml-4 font-bold">{i18n.language === 'ru' ? location.name : location.name_en}</p>
                                <Link to={`?location=${location.name_en.toLowerCase()}`}>
                                    <div className="flex justify-center	">
                                        <button
                                            type="button"
                                            className="mb-4 w-4/5 rounded border border-blue-500 py-1 text-center text-sm text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
                                        >
                                            {t('EDIT')}
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </ContentWrapper>
        </>
    );
};
